import {
  postBodyRequest
} from '@/utils/axios'

// 国外GPS绑定
export function gpsShipBind (params) {
  return postBodyRequest(`/trans/zillion/gps/ship/bind`, params)
}
// 查询客户所有活动运单
export function shipmentsActive (params) {
  return postBodyRequest(`/trans/zillion/gps/shipments/active`, params)
}
