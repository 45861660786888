import { ref } from 'vue'

// 订单： 1订单待确认 2订单待推送 车辆： 1验车委托 2干线车辆在途 21发车计划 3待送车 5即将到达
export const orderiptData1 = ref([
  {
    type: "selectTime",
    placeholder: "推送时间起",
    value: null,
    prop: "pushStartTime",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "推送时间止",
    value: null,
    prop: "pushEndTime",
    width: 200,
  },
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddressString",
    fieldNamesValue: 'label',
    changeOn: true,
    width: 200,
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddressString",
    fieldNamesValue: 'label',
    changeOn: true,
    width: 200,
  },
  {
    type: "select",
    placeholder: "结算方式",
    value: null,
    prop: "orderSettlement",
    width: 200,
    key: 'value',
    keyVal: 'label',
    opt: []
  },
  {
    type: "select",
    placeholder: "结算状态",
    value: null,
    prop: "settlementStatus",
    opt: [
      {
        name: "全部",
        id: ""
      },
      {
        name: "未结算",
        id: 0
      },
      {
        name: "部分结算",
        id: 1
      },
      {
        name: "已结算",
        id: 2
      },
    ],
    width: 200,
  },
  {
    type: "select",
    placeholder: "返款状态",
    value: null,
    prop: "isRefund",
    width: 200,
    opt: [
      { name: '是', id: 1 }
      , { name: '否', id: 0 }
    ],
  },
  {
    type: "select",
    placeholder: "返款结算",
    value: null,
    prop: "refundSettlementStatus",
    width: 200,
    opt: [
      { name: '已结算', id: 2 }
      , { name: '未结算', id: 0 }
    ],
  },
  {
    type: "select",
    placeholder: "部门",
    value: null,
    prop: "orgId",
    width: 200,
    opt: []
  },
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "电话",
    value: null,
    prop: "otherMobile",
    width: 200,
  },
  {
    type: "input",
    placeholder: "对接人",
    value: null,
    prop: "dockingPerson",
    width: 200,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "业务员/分单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
])
export const orderiptData2 = ref([
  {
    type: "select",
    placeholder: "是否确认",
    value: null,
    prop: "isConfirm",
    width: 200,
    opt: [
      { name: '是', id: 1 }
      , { name: '否', id: 0 }
    ],
  },
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddressString",
    fieldNamesValue: 'label',
    changeOn: true,
    width: 200,
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddressString",
    fieldNamesValue: 'label',
    changeOn: true,
    width: 200,
  },
  {
    type: "select",
    placeholder: "结算方式",
    value: null,
    prop: "orderSettlement",
    width: 200,
    key: 'value',
    keyVal: 'label',
    opt: []
  },
  {
    type: "select",
    placeholder: "结算状态",
    value: null,
    prop: "settlementStatus",
    opt: [
      {
        name: "全部",
        id: ""
      },
      {
        name: "未结算",
        id: 0
      },
      {
        name: "部分结算",
        id: 1
      },
      {
        name: "已结算",
        id: 2
      },
    ],
    width: 200,
  },
  {
    type: "select",
    placeholder: "返款状态",
    value: null,
    prop: "isRefund",
    width: 200,
    opt: [
      { name: '是', id: 1 }
      , { name: '否', id: 0 }
    ],
  },
  {
    type: "select",
    placeholder: "返款结算",
    value: null,
    prop: "refundSettlementStatus",
    width: 200,
    opt: [
      { name: '已结算', id: 2 }
      , { name: '未结算', id: 0 }
    ],
  },
  {
    type: "select",
    placeholder: "小程序",
    value: null,
    prop: "isMiniOrder",
    width: 200,
    opt: [
      { name: '已结算', id: 2 }
      , { name: '未结算', id: 0 }
    ],
  },
  {
    type: "select",
    placeholder: "部门",
    value: null,
    prop: "orgId",
    width: 200,
    opt: []
  },
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "电话",
    value: null,
    prop: "otherMobile",
    width: 200,
  },
  {
    type: "input",
    placeholder: "对接人",
    value: null,
    prop: "dockingPerson",
    width: 200,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "业务员/分单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
])

export const vehicleiptData1 = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
])
export const vehiclecolumns1 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    slots: { customRender: 'customerName' },
    align: 'center',
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: { customRender: 'vinNo' },
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: { customRender: 'startAddress' },
    align: 'center',
  },
 
  {
    title: '业务员',
    dataIndex: 'sourceMan',
    align: 'center',
  },
  {
    title: '确认时间',
    dataIndex: 'orderTime',
    align: 'center',
  },
  {
    title: '预约时间',
    dataIndex: 'pickAppointTime',
    align: 'center',
  },
  {
    title: '跟单信息',
    dataIndex: 'documentaryDetailsArr',
    slots: {
      customRender: 'documentaryDetailsArr'
    },
    align: 'center',
  },
  {
    title: '委托',
    dataIndex: 'entrustAdd',
    slots: {
      customRender: 'entrustAdd'
    },
    align: 'center',
    width: 80,
  },
])
export const vehiclecolumns11 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    slots: { customRender: 'customerName' },
    align: 'center',
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: { customRender: 'vinNo' },
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: { customRender: 'startAddress' },
    align: 'center',
  },

  {
    title: '业务员',
    dataIndex: 'sourceMan',
    align: 'center',
  },
  {
    title: '确认时间',
    dataIndex: 'orderTime',
    align: 'center',
  },
  {
    title: '预约时间',
    dataIndex: 'pickAppointTime',
    align: 'center',
  },
  {
    title: '跟单信息',
    dataIndex: 'documentaryDetailsArr',
    slots: {
      customRender: 'documentaryDetailsArr'
    },
    align: 'center',
  },
])
export const vehiclecolumns2 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    slots: { customRender: 'customerName' },
    align: 'center',
  },
  {
    title: '始发地',
    dataIndex: 'startAddress',
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '目的地',
    dataIndex: 'endAddress',
    align: 'center',
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: { customRender: 'vinNo' },
    align: 'center',
  },
  {
    title: '发运状态',
    dataIndex: 'transStatus',
    slots: { customRender: 'transStatus' },
    align: 'center',
  },
  {
    title: '当前城市',
    dataIndex: 'nowCity',
    align: 'center',
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
    align: 'center',
  },
  {
    title: '上次位置+时间',
    dataIndex: 'lastGps',
    slots: {
      customRender: 'lastGps'
    },
    align: 'center',
  },
  {
    title: '推送时间',
    dataIndex: 'pushTime',
    align: 'center',
  },
])
export const vehiclecolumns21 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    slots: { customRender: 'customerName' },
    align: 'center',
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: { customRender: 'vinNo' },
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startDetails',
    slots: { customRender: 'startDetails' },
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '调度员',
    dataIndex: 'dispatcher',
    align: 'center',
  },
  {
    title: '发运时效',
    dataIndex: 'despatchTime',
    align: 'center',
  },
  {
    title: '预计发车时间',
    dataIndex: 'arrangeLoadTime',
    align: 'center',
  },
])
export const vehicleiptData3 = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
])
export const vehiclecolumns3 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    slots: { customRender: 'customerName' },
    align: 'center',
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: { customRender: 'vinNo' },
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: { customRender: 'startAddress' },
    align: 'center',
  },

  {
    title: '业务员',
    dataIndex: 'sourceMan',
    align: 'center',
  },
  {
    title: '送车信息',
    dataIndex: 'takeOutAddress',
    slots: { customRender: 'takeOutAddress' },
    align: 'center',
  },
  {
    title: '目的地取车人',
    dataIndex: 'picker',
    slots: { customRender: 'picker' },
    align: 'center',
  },
  {
    title: '上段完成时间',
    dataIndex: 'lastTime',
    align: 'center',
  },
  {
    title: '结算信息',
    dataIndex: 'orderSettlement',
    slots: { customRender: 'orderSettlement' },
    align: 'center',
  },
  {
    title: '跟单信息',
    dataIndex: 'documentaryDetailsArr',
    slots: {
      customRender: 'documentaryDetailsArr'
    },

    align: 'center',
  },
])
export const vehicleiptData5 = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
])
export const vehiclecolumns5 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    slots: { customRender: 'customerName' },
    align: 'center',
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: { customRender: 'vinNo' },
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: { customRender: 'startAddress' },
    align: 'center',
  },

  {
    title: '业务员',
    dataIndex: 'sourceMan',
    align: 'center',
  },
  {
    title: '所在大版',
    dataIndex: 'truckNo',
    align: 'center',
  },
  {
    title: '剩余公里数',
    dataIndex: 'estimatedKm',
    align: 'center',
  },
  {
    title: '当前城市',
    dataIndex: 'gpsTime',
    slots: { customRender: 'gpsTime' },
    align: 'center',
  },
  {
    title: '跟单信息',
    dataIndex: 'documentaryDetails',
    slots: { customRender: 'documentaryDetailsArr' },
    align: 'center',
  },
])
