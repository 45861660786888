<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn">
      <template #customFooter>
          <span class="m-l1"></span>
          <a-select show-search  allowClear v-model:value="orgName" style="width:200px" @change="selectChange" placeholder="部门">
            <a-select-option v-for="opt in orgpNameOpt" :key="opt.id" :value="opt.id">{{ opt.name }}</a-select-option>
          </a-select>
      </template>
    </SearchList>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle">共有&nbsp;<a>{{ data.length }}</a>&nbsp;条数据</div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #profitYear="{ record }">
          {{ record.profitYear + '-' + record.profitMonth }}
        </template>
        <template #add="{ record }">
          <a  @click="lookDetails(record)">查看明细</a>
        </template>
      </a-table>
    </div>
    <a-modal v-model:visible="visible" :title="title" destroyOnClose width="80%" :bodyStyle="{padding: '10px',background:'#EEEEEE'}" footer="">
      <Details :empId="empId" :info="info"></Details>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import moment from 'moment'
import { transitionTimeYM, getTableBody, IfNull } from '@/utils/util'
import { financeCenterOrg } from '@/api/financialCenter/financialCenter'
import Details from './details.vue'

const loading = ref(false)
const dataSource = ref([])
const data = ref([])
const pageSize = ref(50)
const tableRef = ref(null)
const visible = ref(false)
const title = ref('')
const empId = ref('')
const searchMsg = ref({})
const info = ref({})

const orgName = ref(null)
const orgpNameOpt = ref([])

const selectChange = () => {
  if(orgName.value){
    dataSource.value = data.value.filter(item =>{
        return item.salesOrg.includes(IfNull(orgName.value))
    })
    return
  }
}
const searchAdd = (e) => {
  orgName.value = null
  searchMsg.value = e
  getProfitList()
}
const resetBtn = () => {
  orgName.value = null
  searchMsg.value = {
    YM: transitionTimeYM(new Date().toLocaleDateString()),
  }
  getProfitList()
}
const lookDetails = (record) => {
  empId.value = record.empId
  title.value = record.salesOrg + '-' + record.profitYear + '-' + record.profitMonth
  visible.value = true
  info.value = {
    year:record.profitYear,
    month:record.profitMonth,
    orgId:record.orgId
  }
}
const getProfitList = () => {
  loading.value = true
  if (searchMsg.value.YM) {
    searchMsg.value.year = transitionTimeYandM(searchMsg.value.YM).year
    searchMsg.value.month = transitionTimeYandM(searchMsg.value.YM).month
  }
  financeCenterOrg({
    ...searchMsg.value,
  }).then(res => {
    if (res.code !== 10000) return
    data.value = res.data
    dataSource.value = data.value.slice(0, pageSize.value)
    let orgList = []
    res.data.forEach(item => {
    if(item.salesOrg){
      orgList.push({
        name:item.salesOrg,
        id:item.salesOrg
      })
    }else{
      item.salesOrg  = ''
    }
    })
    const uniqueArr = orgList.filter((item, index) => orgList.findIndex(i => i.name === item.name) === index);
    orgpNameOpt.value = uniqueArr
  }).finally(() => {
    loading.value = false
  })
}
const transitionTimeYandM = (time) => {
  if (!time) return ''
  const date = new Date(time)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  return {
    year: year,
    month: month
  }
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
onMounted(() => {
  searchMsg.value = {
    YM: transitionTimeYM(new Date().toLocaleDateString()),
  }
  getProfitList()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})
const iptData = ref([
  {
    type: "selectTimeYM",
    placeholder: "年月",
    value: moment(new Date()),
    prop: "YM",
    width: 200,
    allowClear:false
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '末级部门',
    dataIndex: 'salesOrg',
    align: 'left',
  },
  {
    title: '月份',
    dataIndex: 'profitYear',
    slots: {
      customRender: 'profitYear'
    },
    align: 'left',
  },
  {
    title: '交车台量',
    dataIndex: 'deliveredNum',
    align: 'right',
  },
  {
    title: '合同总金额',
    dataIndex: 'contractAmt',
    align: 'right',
  },
  {
    title: '运输成本',
    dataIndex: 'transportCost',
    align: 'right',
  },
  {
    title: '保险成本',
    dataIndex: 'insuranceCost',
    align: 'right',
  },
  {
    title: '系统税费',
    dataIndex: 'sysInvoiceCost',
    align: 'right',
  },
  {
    title: '实际税费',
    dataIndex: 'invoiceCost',
    align: 'right',
  },
  {
    title: '附加费',
    dataIndex: 'additionalFeeTotal',
    align: 'right',
  },
  {
    title: '交车毛利',
    dataIndex: 'grossProfit',
    align: 'right',
  },
  {
    title: '返款',
    dataIndex: 'refundFee',
    align: 'right',
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'left',
    slots: {
      customRender: 'add'
    },
    width: 120
  },
])
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>