<template>
  <h4 class="content-title">领取规则</h4>
  <div class="content-subject">
    <p style="color: #b5bbc5;font-size:14px">根据配置规则领取 公海客户，每次最多可领取1个，完成跟进并添加跟进记录或者标签后再领取。</p>
    <a-input-search v-model:value="empName" placeholder="请输入员工名称" style="width:200px;line-height: 38px;"
      @search="onSearch">
      <template #enterButton>
        <a-button>搜索</a-button>
      </template>
    </a-input-search>
    <a-button style="margin-bottom:15px" @click="visible = true">+添加员工领取规则</a-button>
    <a-table :columns="columns" :row-key="record => record.id" :data-source="dataSource" bordered :loading="loading">
      <template #operation="{ record }">
        <div>
          <a @click="confirm(record)">确定</a>
        </div>
      </template>
      <template #isAdmin="{ record }">
        <div>
          <a-switch size="default" checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="0"
            v-model:checked="record.isAdmin.value" @change="adminChange(record)" />
        </div>
      </template>
      <template #isPublicNotice="{ record }">
        <div>
          <a-switch size="default" checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="0"
            v-model:checked="record.isPublicNotice.value" @change="adminChange(record)" />
        </div>
      </template>
      <template #isAj="{ record }">
        <div>
          <a-switch size="default" checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="0"
            v-model:checked="record.isAj.value"  />
        </div>
      </template>
      <template #highSeasNum="{ record }">
        <div>
          <a-input v-model:value="record.highSeasNum" placeholder="" />
        </div>
      </template>
      <template #privateSeasTotalNum="{ record }">
        <div>
          <a-input v-model:value="record.privateSeasTotalNum" placeholder="" />
        </div>
      </template>
      <template #channels="{ record }">
        <!--        <div @click="setChannelList(record.channels)">-->
        <!--          <a-tag style="background:#f0f2f5;color:#344563" :closable="true" @close="chose(record,index)" v-for="(item,index) in record.channels" :key="item.value">{{item.label}}</a-tag>-->
        <!--        </div>-->
        <!--        <div>-->
        <!--          <a-select style="width:80%" mode="multiple" placeholder="请选择" @focus="focus(record.channels)">-->
        <!--            <a-select-option v-for="item in channelList" :key="item.name" :value="item.id" @click="tableChange(record,item)">{{item.name}}</a-select-option>-->
        <!--          </a-select>-->
        <!--        </div>-->
       <div class="flex al-center">
        <div class="tag-box flex wrap">
          <div v-for="item in record.channels" :key="item.value" style="margin-bottom: 5px">
               <a-tag closable @close="handleClose(item,record)"> {{ item.label }}</a-tag>
          </div>
         
          <!-- <a-select style="width:100%" mode="multiple" placeholder="请选择" v-model:value="record.channelIds">
            <a-select-option v-for="item in channelList" :key="item.name" :value="item.id">{{ item.name
            }}</a-select-option>
          </a-select> -->
        </div>
        <div style="width: 10%">
          <a @click="onEdit(record)" style="margin-left: 5px">修改</a>
        </div>
       </div>
      </template>
    </a-table>
  </div>
  <ConfigModal :selProvince="selProvince" @success="success" v-model:visible="configVisible"></ConfigModal>
  <a-modal v-model:visible="visible" @ok="add">
    <template #title>
      <div style="font-family: MicrosoftYaHei-Bold;font-weight: bold;color: #172b4d;font-size: 16px;">添加员工领取规则</div>
    </template>
    <div style="margin-bottom:15px">
      <p>员工</p>
      <a-select style="width:100%" mode="multiple" v-model:value="newData.empName"
        :not-found-content="fild ? undefined : null" :filter-option="false" show-search placeholder="请输入员工姓名或关键字"
        @inputKeyDown="search" @search="assignment" @change="memberChange">
        <a-select-option v-for="item in options" :key="item.id" :value="item.name">{{ item.name }}-{{ item.no
        }}</a-select-option>
        <template v-if="fild" #notFoundContent>
          <a-spin size="small" />
        </template>
      </a-select>
      <span style="color:#f00;font-size:14px">在输入完成后，按下回车搜索</span>
    </div>
    <div style="margin-bottom:15px">
      <p>每日领取总数(个)</p>
      <a-input v-model:value="newData.highSeasNum" placeholder="" min="0" type="number" />
    </div>
    <div style="margin-bottom:15px">
      <p>私海上限总数(个)</p>
      <a-input v-model:value="newData.privateSeasTotalNum" placeholder="" min="0" type="number" />
    </div>
    <div style="margin-bottom:15px">
      <!-- <a-select style="width:100%" mode="multiple" placeholder="请选择" @change="channelChange">
        <a-select-option v-for="item in channelList" :key="item.name" :value="item.id">{{ item.name }}</a-select-option>
      </a-select> -->
      <div class="tag-box" @click="channelChange">
            <div v-if="newData.channels.length === 0" style="color: #ccc;font-size: 14px;">
                  请选择
            </div>
            <div v-else class="flex wrap">
              <div v-for="item in newData.channels" :key="item.value" style="margin-bottom: 5px">
               <a-tag closable @close="handleClose(item,null)"> {{ item.label }}</a-tag>
          </div>
            </div>
      </div>
    </div>
    <div style="margin-bottom:15px">
      <p>是否为管理员</p>
      <a-switch size="default" checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="0"
        v-model:checked="addAdmin" @change="addAdminChange" />
    </div>
    <div style="margin-bottom:15px">
      <p>是否公海提醒</p>
      <a-switch size="default" checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="0"
        v-model:checked="addPublicNotice" @change="addPublicNoticeChange" />
    </div>
    <div style="margin-bottom:15px">
      <p>营销中控权限</p>
      <a-switch size="default" checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="0"
        v-model:checked="addIsAj" @change="addIsAjChange" />
    </div>
  </a-modal>
 
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { employeeList, getPageList, addRules, editConfig } from '@/api/crmManagement/setRules'
import { getChannelList } from '@/api/global.js'
import { message } from 'ant-design-vue'
import ConfigModal from './configModal.vue'

export default {
  components: {
    ConfigModal
  },
  setup () {
    const state = reactive({
      selProvinceId:null,
      selProvince:[],
      configVisible:false,
      empName: '',
      visible: false,
      fild: false,
      loading: false,
      addAdmin: 0,
      addPublicNotice: 0,
      addIsAj:0,
      timer: undefined,
      edit: {
        dayTotal: '',
        privateSeasTotalNum: ''
      },
      newData: {
        id: '',
        channels: [],
        empId: '',
        isAdmin: {},
        isPublicNotice: {},
        empName: [],
        highSeasNum: '',
        privateSeasTotalNum: ''
      },
      current: 1,
      mirrorData: null,
      options: [],
      setChannel: [],
      channelList: [],
      dataSource: [],
      columns: [
        {
          title: '员工',
          dataIndex: 'empName',
          width: '90px'
        }, {
          title: '是否为管理员',
          dataIndex: 'isAdmin',
          width: '10%',
          slots: {
            customRender: 'isAdmin'
          }
        }, {
          title: '是否公海通知',
          dataIndex: 'isPublicNotice',
          width: '10%',
          slots: {
            customRender: 'isPublicNotice'
          }
        },
        , {
          title: '营销中台',
          dataIndex: 'isAj',
          width: '10%',
          slots: {
            customRender: 'isAj'
          }
        }, {
          title: '每日领取数量(个)',
          dataIndex: 'highSeasNum',
          width: '10%',
          slots: {
            customRender: 'highSeasNum'
          }
        }, {
          title: '私海上限总数(个)',
          dataIndex: 'privateSeasTotalNum',
          slots: {
            customRender: 'privateSeasTotalNum'
          }
        }, {
          title: '可领取渠道',
          dataIndex: 'channels',
          slots: {
            customRender: 'channels'
          },
          width:500
        }, {
          title: '操作',
          dataIndex: 'operation',
          width: '90px',
          slots: {
            customRender: 'operation'
          }
        }
      ]
    })
    const handleClose = (removedTag,record) => {
      if(!record){
        const tags = state.newData.channels.filter(tag => tag !== removedTag);
        state.newData.channels = tags;
        return
      }
      const tags = record.channels.filter(tag => tag !== removedTag);
      const idx = record.channelIds.filter(tag => tag !== removedTag.value)
      record.channels = tags;
      record.channelIds = idx
    }
    const success = (e) => {
      if(state.selProvinceId){
        state.dataSource.forEach( item => {
        if(item.id === state.selProvinceId){
          item.channels = e
          item.channelIds = e.map(item => item.value)
        }
      })
      }else{
        state.newData.channels = e
      }
      state.configVisible = false
    }
    const onEdit = (record) => {
      state.selProvinceId = record.id
      state.selProvince = record.channels
      state.configVisible = true
    }
    const channelChange = () => {
      state.selProvince = state.newData.channels
      state.selProvinceId = null
       state.configVisible = true
    }
    // 员工名称搜索
    const onSearch = () => {
      if (state.empName !== '') {
        state.loading = true
        setTimeout(() => {
          state.dataSource = state.mirrorData.filter(item => {
            return item.empName.indexOf(state.empName) !== -1
          })
          state.loading = false
        }, 500)
      } else {
        state.loading = true
        setTimeout(() => {
          state.dataSource = state.mirrorData
          state.loading = false
        }, 500)
      }
    }
    const loadData = () => {
      state.loading = true
      getPageList({
        current: 1
      }).then(res => {
        if (res.code === 10000) {
          state.dataSource = res.data
          state.mirrorData = res.data // 镜像数据
          state.dataSource.forEach(e => {
            e.channelIds = e.channels.map(column => { return column.value })
          })
          state.loading = false
        }
      }).catch(err => { console.log(err) })
    }
    onMounted(() => {
      loadData()
      getChannelList({ channelLevel: 1 })
        .then(res => {
          if (res.code === 10000) {
            state.channelList = res.data
          }
        }).catch(err => console.log(err))
    })
    const memberChange = (e, val) => {
      if (e.length > 1) {
        e.splice(0, 1)
        val.splice(0, 1)
        e[0] = val[0].value
      }
      if (val.length > 0) {
        state.newData.empName = val[0].value
        state.newData.empId = val[0].key
      }
      state.options = []
    }
    const assignment = e => {
      if (e !== '') state.name = e
      // console.log(e)
    }
    const search = e => {
      if (e.keyCode === 13) {
        state.fild = true
        state.timer = setTimeout(() => {
          employeeList({
            current: 1,
            name: state.name,
            size: 10
          }).then(res => {
            if (res.code === 10000) {
              state.options = res.data
              state.fild = false
            }
          }).catch(err => { console.log(err) })
        }, 500)
      }
    }
    const chose = (record, index) => {
      record.channels.splice(index, 1)
    }
    const setChannelList = list => {
      console.log(list)
    }
    const focus = data => {
      state.setChannel = data
    }
    const confirm = record => {
      editConfig(record)
        .then(res => {
          if (res.code === 10000) message.success(res.msg + '成功')
        }).catch(err => { console.log(err) })
    }
    const tableChange = (record, item) => {
      const arr = []
      record.channels.forEach(val => {
        arr.push(val.value)
      })
      if (arr.includes(item.id)) message.error('注意:渠道已存在')
      else {
        record.channels.push({
          value: item.id,
          label: item.name
        })
      }
    }
 
    const channelEditChange = (e, val) => {
      state.editChannels = val
    }
    const addAdminChange = (e, v) => {
      if (e === 1) state.newData.isAdmin = { label: '是', value: e }
      else state.newData.isAdmin = { label: '否', value: e }
    }
    const addIsAjChange = (e, v) => {
      if (e === 1) state.newData.isAj = { label: '是', value: e }
      else state.newData.isAj = { label: '否', value: e }
    }
    const addPublicNoticeChange = (e, v) => {
      if (e === 1) state.newData.isPublicNotice = { label: '是', value: e }
      else state.newData.isPublicNotice = { label: '否', value: e }
    }
    const adminChange = record => {
      if (record.isAdmin.value === 1 || record.isAdmin.value === true) {
        record.isAdmin = {
          label: '是',
          value: 1
        }
      } else {
        record.isAdmin = {
          label: '否',
          value: 0
        }
      }
    }
    const publicNoticeChange = record => {
      if (record.isPublicNotice.value === 1 || record.isPublicNotice.value === true) {
        record.isPublicNotice = {
          label: '是',
          value: 1
        }
      } else {
        record.isPublicNotice = {
          label: '否',
          value: 0
        }
      }
    }
    const add = () => {
      state.newData.channels = state.newData.channels.map(item => { return { label: item.label, value: item.value } })
      state.newData.channelIds = state.newData.channels.map(e => e.value)
      addRules({ ...state.newData })
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.visible = false
            loadData()
            state.newData = {}
            state.newData.channels = []
            state.newData.empName = []
            state.addAdmin = 0
            state.addPublicNotice = 0
          }
        }).catch(err => { console.log(err) })
    }

    return {
      channelEditChange,
      ...toRefs(state),
      setChannelList,
      addAdminChange,
      channelChange,
      memberChange,
      addPublicNoticeChange,
      adminChange,
      tableChange,
      publicNoticeChange,
      assignment,
      onSearch,
      confirm,
      search,
      chose,
      focus,
      add,
      addIsAjChange,
      onEdit,
      success,
      handleClose
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';

:deep(.ant-input-wrapper>.ant-input) {
  line-height: 27px;
}

:deep(.ant-switch-checked) {
  background-color: #00c57d;
}
.tag-box{
  width: 100%;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
</style>
