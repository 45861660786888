<template>
  <div>
    <div class="echartsGroup">
      <!-- 返回中国地图 -->
      <a-button  v-show="isReturnChina" @click="returnChinaFn()">返回中国地图</a-button>
      <!-- echarts 图表 -->
      <div ref="china_map" v-if="isChina" style="height: 100%;width: 100%"></div>
      <div ref="china_map1" v-if="!isChina" style="height: 100%;width: 100%"></div>
    </div>
    <a-modal title="省数据" v-model:visible="visible" width="40%" :footer="null" @cancel="cancel">

    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import * as echarts from 'echarts'
import 'echarts/map/js/china.js' // 核心文件
require('echarts/theme/macarons') // 
const isReturnChina = ref(false)
const options = ref(null)
const china_map = ref(null)
const china_map1 = ref(null)
const isChina = ref(true)
const visible = ref(false)

const addArea = (params) => {
  if (!params.data) return
  visible.value = true
  console.log(params);
}
// 渲染地图
const chinaMaprsult = (name = null) => {
  name == 'china' ? isReturnChina.value = false : isReturnChina.value = true;
  let chinaMap = name === 'china' ? echarts.init(china_map.value) : echarts.init(china_map1.value)
  options.value = {
    tooltip: { // 鼠标移到图里面的浮动提示框
      trigger: 'item',  //数据项图形触发
    },
    visualMap: { // 左下角展示
      show: true,
      min: 0,
      max: 100,
      left: 10,
      bottom: 0,
      showLabel: true,
      textStyle: {
        color: '#000'
      },
      pieces: [//左下角的切换数据显示
        {
          gt: 75,
          lte: 100000000,
          label: '75~100',
          color: '#f7358c',
        }, {
          gt: 50,
          lte: 75,
          label: '50~75',
          color: '#fb9632',
        }, {
          gt: 25,
          lte: 50,
          label: '25~50',
          color: '#08b2ff',
        }, {
          gte: 0,
          lte: 25,
          label: '0~25',
          color: '#4d2ed2'
        }
      ]
    },
    // dataRange: {
    //
    // },
    geo: {
      map: name ? name : "china",
      zoom: 1,
      label: { show: true },
      itemStyle: {
        borderColor: 'rgba(0, 0, 0, 0.2)',
        // areaColor: '#8DBFEB',  //地图的背景色
        emphasis: { // 高亮状态下的多边形和标签样式
          shadowBlur: 20,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
      roam: true,
      tooltip: {
        formatter: function (params) {
          if (!params.data) return ''
          return params.name + ":" + params.data.value + "人";
        },
      }
    },
    series: [
      {
        name: "", type: "map", geoIndex: 0, label: { show: true }, data: [
          { "name": "北京", "value": 42, "rukuNum": 42, "chukuNum": 8 },
          { "name": "上海", "value": 688, "rukuNum": 688, "chukuNum": 299 },
          { "name": "黑龙江", "value": 7, "rukuNum": 7, "chukuNum": 0 },
          { "name": "大兴安岭地区", "value": 24, "rukuNum": 7, "chukuNum": 0 }
        ]
      }
    ],
  }
  chinaMap.setOption(options.value)
  chinaMapHidden(chinaMap)
}
const chinaMapHidden = (chinaMap) => {
  chinaMap.off('click')//这里解决多次触发点击事件 但是还会执行2次  引用echarts地图，点击各个省份时，点击一个调用两次接口，再点击一次，调用四次接口，再点击调用八次。。。。依次叠加，问题在于，没有将地图上的点击事件清空
  chinaMap.on('click', async function (params) {
    if (params.name === "南海诸岛") return
    isChina.value = false;
    if (params.name in provinces) {
      let s = await import(`echarts/map/js/province/${provinces[params.name]}.js`);
      if (s) {
        chinaMaprsult(params.name)
      }
    } else {
      addArea(params)
    }
  })
}
const funA = () =>{
  return new Promise((resolve, reject) => {
    isChina.value = true;
    resolve(isChina.value);
  })
}
const returnChinaFn = async() => {
     await funA();
    chinaMaprsult('china');
}
const provinces = {   //数据
  台湾: 'taiwan',
  河北: 'hebei',
  山西: 'shanxi',
  辽宁: 'liaoning',
  吉林: 'jilin',
  黑龙江: 'heilongjiang',
  江苏: 'jiangsu',
  浙江: 'zhejiang',
  安徽: 'anhui',
  福建: 'fujian',
  江西: 'jiangxi',
  山东: 'shandong',
  河南: 'henan',
  湖北: 'hubei',
  湖南: 'hunan',
  广东: 'guangdong',
  海南: 'hainan',
  四川: 'sichuan',
  贵州: 'guizhou',
  云南: 'yunnan',
  陕西: 'shanxi1',
  甘肃: 'gansu',
  青海: 'qinghai',
  新疆: 'xinjiang',
  广西: 'guangxi',
  内蒙古: 'neimenggu',
  宁夏: 'ningxia',
  西藏: 'xizang',
  北京: 'beijing',
  天津: 'tianjin',
  上海: 'shanghai',
  重庆: 'chongqing',
  香港: 'xianggang',
  澳门: 'aomen',
}

onMounted(() => {
  chinaMaprsult('china')
})
</script>


<style lang="less" scoped>
.echartsGroup {
  height: 640px;
  padding-bottom: 20px;
}
</style>