<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn">
    </SearchList>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle">共有&nbsp;<a>{{ data.length }}</a>&nbsp;条数据</div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #paidNormalAmt="{ record }">
          <a @click="openDetails(record,1,'paidNormalAmt')">{{ record.paidNormalAmt }}</a>
        </template>
        <template #paidOverdueAmt="{ record }">
          <a @click="openDetails(record,2,'paidOverdueAmt')">{{ record.paidOverdueAmt }}</a>
        </template>
        <template #monthNormalAmt="{ record }">
          <a @click="openDetails(record,3,'monthNormalAmt')">{{ record.monthNormalAmt }}</a>
        </template>
        <template #monthOverdueAmt="{ record }">
          <a @click="openDetails(record,4,'monthOverdueAmt')">{{ record.monthOverdueAmt }}</a>
        </template>
        <template #totalAmt="{ record }">
          <a @click="openDetails(record,0,'totalAmt')">{{ record.totalAmt }}</a>
        </template>
      </a-table>
    </div>
    <a-modal v-model:visible="visible" :title="title" destroyOnClose width="80%" :bodyStyle="{padding: '10px',background:'#EEEEEE'}" footer="">
      <Details :info="info" :title="title"></Details>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import { transitionTimeYM, getTableBody, IfNull } from '@/utils/util'
import { carrierReceivableTotal } from '@/api/financialCenter/financialCenter'
import Details from './details.vue'
import { useStore } from 'vuex'
const store = useStore()

const loading = ref(false)
const dataSource = ref([])
const data = ref([])
const pageSize = ref(50)
const tableRef = ref(null)
const visible = ref(false)
const title = ref('')
const searchMsg = ref({})
const info = ref({})

const arr = {
  0: '合计',
  1: '到收-正常',
  2: '到收-逾期',
  3: '记账/月结-正常',
  4: '记账/月结-逾期'
}
const openDetails = (record, type, key) => {
  if(record[key] == 0) return
  title.value = `承运商应收-${arr[type]}-` + record.creditor
  visible.value = true
  record.type = type
  record.key = key
  record.title = title.value
  info.value = record
}

const searchAdd = (e) => {
  searchMsg.value = e
  getProfitList()
}
const resetBtn = () => {
  searchMsg.value = {
  }
  getProfitList()
}

const getProfitList = () => {
  loading.value = true
  carrierReceivableTotal({
    ...searchMsg.value,
  }).then(res => {
    if (res.code !== 10000) return
    data.value = res.data
    dataSource.value = data.value.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}

const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
onMounted(() => {

  getProfitList()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})
const iptData = ref([
  {
    type: "select",
    placeholder: "部门",
    value: null,
    prop: "orgId",
    width: 200,
    opt:store.state.app.orgList
  },
  {
    type: "input",
    placeholder: "调度员",
    value: null,
    prop: "dispatcher",
    width: 200,
  },
  {
    type: "input",
    placeholder: "承运商（挂账对象）",
    value: null,
    prop: "creditor",
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '部门',
    dataIndex: 'dispatcherOrgName',
    align: 'left',
  },
  {
    title: '调度员',
    dataIndex: 'dispatcher',
    align: 'left',
    width: 100 
  },
  {
    title: '挂账对象',
    dataIndex: 'creditor',
    align: 'left',
    width: 200
  },
  {
    title: '到收',
    align: 'center',
    children: [
      {
        title: '正常',
        dataIndex: 'paidNormalAmt',
        slots: {
          customRender: 'paidNormalAmt'
        },
        align: 'right',
      },
      {
        title: '逾期',
        dataIndex: 'paidOverdueAmt',
        slots: {
          customRender: 'paidOverdueAmt'
        },
        align: 'right',
      },
    ]
  },
  {
    title: '月结/记账',
    align: 'center',
    children: [
      {
        title: '正常',
        dataIndex: 'monthNormalAmt',
        slots: {
          customRender: 'monthNormalAmt'
        },
        align: 'right',
      },
      {
        title: '逾期',
        dataIndex: 'monthOverdueAmt',
        slots: {
          customRender: 'monthOverdueAmt'
        },
        align: 'right',
      },
    ]
  },
  {
    title: '合计',
    dataIndex: 'totalAmt',
    slots: {
      customRender: 'totalAmt'
    },
    align: 'right',
  },
  {
    title: '回款描述',
    dataIndex: 'paymentCollectionRemark',
    align: 'left',
  },
])
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>