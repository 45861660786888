<template>
  <div class="flex ju-between wrap">
    <div v-for="opt in item.subItemList" :key="opt.id" class="optBox">
      <div class="flex-d ju-center itemBox al-center m-t1 m-b1 cur-p" @click="openModal(opt)">
        <img :src="require(`@/assets/images/financeCenter/fun${opt.pageType}.png`)" alt="">
        <div class="m-t1"> {{ opt.title }}</div>
      </div>
    </div>

    <a-modal :title="title" v-model:visible="stltsVisible" :destroyOnClose="true" width="80%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <SettlementStatistics></SettlementStatistics>
    </a-modal>
    <a-modal :title="title" v-model:visible="orgVisible" :destroyOnClose="true" width="80%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <OrgPerformance></OrgPerformance>
    </a-modal>
    <a-modal :title="title" v-model:visible="invoiceVisible" :destroyOnClose="true" width="80%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <InvoiceStatistics></InvoiceStatistics>
    </a-modal>
    <a-modal :title="title" v-model:visible="busVisible" :destroyOnClose="true" width="80%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <BusinessCollectionStatistics></BusinessCollectionStatistics>
    </a-modal>
    <a-modal :title="title" v-model:visible="carrierVisible" :destroyOnClose="true" width="80%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <CarrierCollectionStatistics></CarrierCollectionStatistics>
    </a-modal>
    <a-modal :title="title" v-model:visible="paymentVisible" :destroyOnClose="true" width="80%" footer="" :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <PaymentStatistics></PaymentStatistics>
    </a-modal>
  </div>
</template>

<script setup> 
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import SettlementStatistics from './settlementStatistics' //决算统计
import OrgPerformance from './orgPerformance' //部门业绩
import InvoiceStatistics from './invoiceStatistics' //发票统计
import PaymentStatistics from './paymentStatistics' //付款统计
import BusinessCollectionStatistics from './businessCollectionStatistics' //业务应收
import CarrierCollectionStatistics from './carrierCollectionStatistics' //承运商应收
import { message } from 'ant-design-vue'
const router = useRouter()

const props = defineProps({
  item: {
    type: Object,
    default: {}
  }
})
const title = ref('')
const stltsVisible = ref(false)
const orgVisible = ref(false)
const invoiceVisible = ref(false)
const busVisible = ref(false)
const carrierVisible = ref(false)
const paymentVisible = ref(false)

const openModal = (item) => {
  title.value = item.title
  switch (item.pageType) {
    case 71:
      stltsVisible.value = true
      break;
    case 72:
      orgVisible.value = true
      break;
    case 73:
      invoiceVisible.value = true
      break;
    case 74:
      // message.warning('功能暂未开发')
      // return
      paymentVisible.value = true
      break;
    case 75:
      busVisible.value = true
      break;
    case 76:
      carrierVisible.value = true
      break;
  }
}

</script>

<style lang="less" scoped>
.optBox {
  width: 33%;
  font-size: 14px;
  color: #332;
  img {
    width: 50px;
    height: 45px;
  }
}
</style>