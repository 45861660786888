<style lang="less" scoped>

</style>
<template>
  <a-col flex="auto">
    <a-card>
      <a-tabs type="card" v-model:activeKey="state.fromKey" @change="changeBrandKey">
        <a-tab-pane key=1 tab="添加城市">
          <div style="width:500px">
            <a-form ref="formRefBrand" :model="state.form" :rules="state.rules" :labelCol="{flex:'100px'}" :wrapperCol="{flex:'auto'}">
              <a-form-item label="选择城市" name="province" >
              <a-cascader v-model:value="state.form.provinces" :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true" @change="setStartAddressMethod" placeholder="" />
            </a-form-item>
              <a-form-item label="新增城市" name="city">
                <a-input v-model:value="state.form.city" />
              </a-form-item>
              <a-form-item>
                <a-row>
                  <a-col :span="24" style="text-align: right;margin-top:20px">
                    <a-button type="primary" :loading="state.confirmLoading" @click="save">添加城市</a-button>
                  </a-col>
                </a-row>
              </a-form-item>

            </a-form>
          </div>

        </a-tab-pane>
        <!-- <a-tab-pane key="2" tab="添加车辆型号">
          <div style="width:500px">
            <a-form ref="formRef" :model="state.form" :rules="state.rules" :labelCol="{flex:'100px'}" :wrapperCol="{flex:'auto'}">
              <a-form-item label="车辆品牌" name="brands">
                <a-select v-model:value="state.form.brands"  show-search @change="queryVehicle" placeholder="查询/选择 车牌" >
                  <a-select-option v-for=" item in state.carCustome" :key="item.value" :value="item.label">{{ item.label }}</a-select-option>
               </a-select>
              </a-form-item>
              <a-form-item label="车辆型号" name="model">
                <a-input v-model:value="state.form.model" /> 
              </a-form-item>
              <a-form-item>
                <a-row>
                  <a-col :span="24" style="text-align: right;margin-top:20px">
                    <a-button type="primary" @click="save()">添加车辆型号</a-button>
                  </a-col>
                </a-row>
              </a-form-item>
            </a-form>
          </div>
        </a-tab-pane> -->
      </a-tabs>
    </a-card>
  </a-col>

</template>
<script setup>
import { getCityList  } from '@/api/global'
import { onMounted, reactive, ref, toRefs,  } from 'vue'
import { message } from 'ant-design-vue'
import {  addGuowaiCity } from '@/api/system/setting'
import { useStore } from 'vuex'

const store = useStore()

const state = reactive({
  fromKey: ref('1'),
  brands: [],
  vehicleBrands: [],
  customers: [],
  carCustome: [],
  confirmLoading: false,
  form: {
    province:'',
    provinces:[],
    city:''
  },
  rules: {
    province: [{ required: true, message: '请选择城市', trigger: 'blur' }],
    city: [{ required: true, message: '请填写新增城市名称', trigger: 'blur' }],
  }
})
const formRefBrand = ref(null)
const formRef = ref(null)

const setStartAddressMethod = (e) => {
  if(e&&e.length>0){
  state.form.province = e[e.length-1]
  }else{
    state.form.province = null
  }
}
const loadData = () => {
  getCityList().then(response => {
    store.commit('setCityDict', response.data)
      })
}


const save = () => {
  formRefBrand.value.validate().then( () => {
    state.confirmLoading = true
  addGuowaiCity({
    cityId:state.form.province,
    newCityName:state.form.city,
    type:27
  }).then( res => {
    if(res.code !== 10000) return
    message.success('添加成功')
    state.form.city = ''
    state.form.province = ''
    state.form.provinces = []
    loadData()
  }).finally(() => {
    state.confirmLoading = false
  })
  })
 
}
const changeBrandKey = (v) => {

}

const queryVehicle = (value, choice) => {
  state.vehicleBrands = choice
}




</script>
