<template>
<div>
<a-modal :visible="visible" title="外派任务" footer="" @cancel="cancel" width="80%" :bodyStyle="{ padding:'10px',background:'#fff'}">
    <TaskAssignment :type="1"></TaskAssignment>
</a-modal>
</div>
</template>

<script setup>
import { ref } from 'vue'
import TaskAssignment from '@/views/components/transport/taskAssignment/taskAssignment.vue'
const props = defineProps({
  visible:{
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:visible'])
const cancel = () => {
  emit('update:visible', false)
}
</script>

<style lang="less" scoped>

</style>