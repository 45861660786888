<template>
  <div>
    <a-modal :visible="visible" title="大车查询" width="90%" :footer="null" :destroyOnClose="true" @cancel="cancel" :bodyStyle="{background:'#EEEEEE',padding:'10px'}">
      <div class="flex al-center tabBox">
        <div v-for=" (item,index) in statisticsData" :key="item.id" class="itemBox m-r4 cur-p" :class="activeKey == index ? 'active' : ''" @click="changeTab(item,index)">
          {{ item.truckStatus.label }}&nbsp;&nbsp;{{ item.num }}
        </div>
      </div>

      <div>
        <SearchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
      </div>

      <!-- 表格主体 -->
      <div class="table-data">
        <a-table ref="tableRef" size="small" :columns="columns" :row-key="record => record.transTruckId" :data-source="bigCarList" bordered :scroll="{ x: 2200,y:500}" :pagination="pagination" :loading="loading" @change="changePage">
          <template #operation="{ record }">
            <div style="position: relative;">
              <a class="ant-dropdown-link" @click="detailData = record, loadingVehShow = true">
                操作
              </a>
            </div>
          </template>
          <template #truckStatus="{ record }">
            <div>
              <span :style="{ color: statusColor[record.truckStatus.value] }">{{ record.truckStatus?.label
              }}</span>
            </div>
          </template>
          <template #nowLocation="{record}">
            <div v-show="record.nowLocation">
              <div>{{ record.nowLocation }}</div>
              <div>{{ record.gpsTime  }}</div>
            </div>
          </template>
        </a-table>
      </div>
       <!-- 大车操作 -->
       <LoadingVehilce :windowShow="loadingVehShow" :transTruckId="detailData.transTruckId" @statusShow="loadingVeh" />
    </a-modal>

  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import VehicleTable from './vehicleTable.vue';
import SearchList from '@/components/searchList'
import { getBigCarStatistics } from '@/api/transport/truck'
import { pageAll as bigPage } from '@/api/transport/vehicle'
import LoadingVehilce from '@/views/components/transport/bigVehicleOperate/loadingVehilce.vue'
import { addressTran } from '@/utils/util'
import { cloneDeep } from 'lodash-es';

const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})

const loadingVehShow = ref(false)
const searchMsg = ref({})
const truckStatus = ref(null)
const statisticsData = ref([])
const activeKey = ref(0)
const bigCarList = ref([])
const loading = ref(false)
const detailData = ref({})
const pagination = ref({
  showSizeChanger: true,
  showQuickJumper: true,
  current: 1,
  pageSize: 10,
  total: 0,
  showTotal: (totals) => `共 ${totals} 条`
})

const loadingVeh = () => {
  loadingVehShow.value = false
}
const searchAdd = (e) =>{
  pagination.value.current = 1
  let msg = cloneDeep(e)
  msg.startAddress = addressTran(msg.startAddress)
  msg.endAddress = addressTran(msg.endAddress)
  searchMsg.value = msg
  loadData()
}
const resetBtn = () => {
  pagination.value.current = 1
  searchMsg.value = {}
  loadData()
}
const changePage = (val) => {
  pagination.value = val
  loadData()
}
// 统计信息
const getStatistics = () => {
  getBigCarStatistics().then(res => {
    if (res.code === 10000 && res.data) {
      res.data.unshift(
        {
          truckStatus:{
            label:'全部',
            value:null
          }
        }
      )
      statisticsData.value = res.data
      let type = statisticsData.value[0].truckStatus.value
      truckStatus.value = type
      loadData(type)
    } else message.error('暂无统计数据')
  })
}
// 大车列表
const loadData = () => {
  loading.value = true
  bigPage({
    ...searchMsg.value,
    dispatchId: '',
    regionalType:1,
    truckStatus:  truckStatus.value,
    current: pagination.value.current,
    size: pagination.value.pageSize,
  }).then(res => {
    if (res.code === 10000) {
      if (res.data) {
        pagination.value.total = res.data.total
        bigCarList.value = res.data.records
      } else message.error('暂无数据')
    }
  }).finally(() => {
    loading.value = false
  })
}
const changeTab = (item,index) => {
  pagination.value.current = 1
  truckStatus.value = item.truckStatus.value
  activeKey.value = index
  loadData()
}
const cancel = () => {
  emit('update:visible', false)
}
onMounted(() => {
  getStatistics()

})
const statusColor = ref({
  0: '#FF8D1A',
  1: '#3470FF',
  2: '#0066CC',
  4: '#344563'
})
const columns = ref(
  [
    {
      title: '操作',
      align: 'center',
      width: '3%',
      slots: {
        customRender: 'operation'
      }
    },
    {
      title: '当前状态',
      width: '4%',
      align: 'center',
      slots: {
        customRender: 'truckStatus'
      }
    },
    {
      title: '承运商',
      dataIndex: 'carrierName',
      width: '9%',
      align: 'center'
    },
    {
      title: '总运费',
      dataIndex: 'freightCost',
      width: '3%',
      align: 'center'
    },
    {
      title: '大车车牌',
      dataIndex: 'carNo',
      width: '4%',
      align: 'center'
    },
    {
      title: '大车类型',
      dataIndex: 'truckType.label',
      width: '3%',
      align: 'center'
    },
    {
      title: '司机',
      dataIndex: 'driverName',
      width: '4%',
      align: 'center'
    },
    {
      title: '当前位置',
      dataIndex: 'nowLocation',
      width: '10%',
      align: 'center',
      slots: {
        customRender: 'nowLocation'
      }
    },
    {
      title: '起运地',
      dataIndex: 'startAddress',
      width: '10%',
      align: 'center'
    },
    {
      title: '目的地',
      dataIndex: 'endAddress',
      align: 'center',
      width: '10%'
    },
    {
      title: '安排人',
      dataIndex: 'arranger',
      align: 'center',
      width: '4%'
    },
    {
      title: '安排时间',
      dataIndex: 'createTime',
      align: 'center',
      width: '6%'
    },
    {
      title: '发车人',
      dataIndex: 'dispatcher',
      align: 'center',
      width: '4%'
    },
    {
      title: '发车时间',
      dataIndex: 'departureTime',
      align: 'center',
      width: '6%'
    },
    {
      title: '规定到达时间',
      dataIndex: 'arriveTime',
      width: '6%',
      align: 'center'
    },
    {
      title: '超时状态',
      align: 'overdueText',
      width: '4%',
      align: 'center',
    },
    {
      title: '已占车位',
      dataIndex: 'usedParkPlace',
      width: '4%',
      align: 'center',
    }
  ]
)
const iptData = ref([
  {
    type: "input",
    placeholder: "大车车牌",
    value: null,
    prop: "carNo",
    width: 150,
  },
  {
    type: "select",
    placeholder: "大车类型",
    value: null,
    prop: "truckType",
    width: 150,
    opt: [
      {
        id: 1,
        name: '大板车'
      },
      {
        id: 2,
        name: '小板车'
      },
      {
        id: 3,
        name: '货车'
      },
      {
        id: 4,
        name: '地跑'
      }
    ]
  },
  {
    type: "input",
    placeholder: "小车车牌",
    value: null,
    prop: "vehicleNo",
    width: 150,
  },
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    changeOn:true,
    width: 180,
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    changeOn:true,
    width: 180,
  },
  {
    type: "input",
    placeholder: "派车人",
    value: "",
    prop: "dispatcher",
    width: 150,
  },
  {
    type: "input",
    placeholder: "承运商名称",
    value: "",
    prop: "carrierName",
    width: 180,
  },
  {
    type: "select",
    placeholder: "是否更新",
    value: null,
    prop: "gpsUpdateStatus",
    width: 150,
    opt: [
      {
        id: 1,
        name: '是'
      },
      {
        id: 0,
        name: '否'
      }
    ]
  },
])
</script>

<style lang="less" scoped>
.tabBox {
  padding: 10px 0px 0px 10px;
  border-bottom: 1px solid #eee;
  background: #fff;
  color: #344563;
  font-size: 16px;
}

.itemBox {
  padding: 10px 5px;
  border-bottom: 3px solid transparent;
}

.active {
  color: #0066cc;
  border-bottom: 3px solid #0066cc;
}

.table-data {
  margin-top: 10px;
  padding: 15px 10px;
  background: #fff;
}
</style>