import { postBodyRequest, postRequest, getRequest } from '@/utils/axios'
// 中控台
export function consoleStatistics (params) {
  return postBodyRequest('/anal/finance-center-console/statistics', params)
}
// 财务应收看板
export function receivableTotal (params) {
  return postBodyRequest('/anal/finance-center-console/receivable-total', params)
}
// 应收看板-月份
export function receivableMonthTotal (params) {
  return postBodyRequest('/anal/finance-center-console/receivable-month-total', params)
}
// 应收看板-月份-明细
export function receivableMonthDetails (params) {
  return postBodyRequest('/anal/finance-center-console/receivable-month-details', params)
}
// 利润≤0车辆未报备
export function financeCenterEmp (params) {
  return postBodyRequest('/anal/finance-center-console/emp', params)
}
// 财务-利润部门明细(财务中台部门业绩)
export function financeCenterOrg (params) {
  return postBodyRequest('/anal/finance-center-console/org', params)
}
// 财务中台-部门业绩-详情明细
export function financeCenterOrgDetail (params) {
  return postBodyRequest('/anal/finance-center-console/org/detail', params)
}
// 发票统计
export function invoiceStatistic (params) {
  return postBodyRequest('/anal/finance-center-console/invoice-statistic', params)
}
// 发票统计金额
export function invoiceStatisticTotal (params) {
  return postBodyRequest('/anal/finance-center-console/invoice-statistic/total', params)
}
// 发票统计-开票车辆
export function invoiceStatisticVehicle (params) {
  return postBodyRequest('/anal/finance-center-console/invoice-statistic/vehicle', params)
}
// 承运商应收(财务中台)
export function carrierReceivableTotal(params) {
  return postBodyRequest('/anal/finance-center-console/carrier/receivable-total', params)
}
// 决算统计
export function finalAccountsStatistic(params) {
  return postBodyRequest('/anal/finance-center-console/final-accounts-statistic', params)
}
// 承运商应收-明细
export function carrierReceivableDetails(params) {
  return postBodyRequest('/anal/finance-center-console/carrier/receivable-month-details', params)
}
// 承运商应收-订单交车详情(财务中台)
export function carrierVehicleDetails(params) {
  return postBodyRequest('/anal/finance-center-console/carrier/receivable-vehicle-details', params)
}