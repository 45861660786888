<template>
  <div>
    <div v-if="styleType == 1">
      <KeyIssues  :subItemList="msg.subItemList" />
    </div>
    <div v-if="styleType == 7">
      <FunctionalArea :item="msg" :vehicleNumType="vehicleNumType" />
    </div>
   
  </div>
</template>

<script setup>
import FunctionalArea from './FunctionalArea'
import KeyIssues from './keyIssues'

const props = defineProps({
  styleType: {
    type: [String, Number],
    default: null
  },
  msg: {
    type: Object,
    default: {}
  },
  modal:{

  },
})
</script>

  <style lang="less" scoped>
</style>
