<template>
  <div>
    <a-modal :visible="visible" :title="title" centered footer="" @cancel="cancel" width="92%" :bodyStyle="{ padding:'10px',background:'#EEEEEE'}">
      <div class="statusBox flex">
        <div v-for="(item,index) in tabstatus" :key="item.id" class="item cur-p" @click="changeBtn(item)" :class="indexKey === item.colorType ?'changeItem':''">
          <span v-if="index !== 0"> </span>{{ item.name }}&nbsp;&nbsp;{{ item.total }}
        </div>
      </div>
      <slot name="searchTabel"></slot>
      <SearchList v-if="iptData.length > 0" ref="SearchListRef" :iptData="iptData" @searchChange="searchChange" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
      <div  class="m-t1 tableBox">
       <div class="flex al-center">
        <div class="sys-screenTitle">当前筛选出&nbsp;<a>{{ dataLength }}</a>&nbsp;条 </div>
        <!-- <div class="amtBox m-l2 " style="color:red" v-if="styleType===14">
          合计金额<span class="fz-16" >￥{{dataAmt}}</span>
        </div> -->
       </div>
        <a-table ref="tableRef" size="small" :dataSource="data" :rowKey="(item,index) => index" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500,x:x?x:false}">
          <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #paidNormalAmt="{ record }">
          <a @click="openDetails(record,1,'paidNormalAmt')">{{ record.paidNormalAmt }}</a>
        </template>
        <template #paidOverdueAmt="{ record }">
          <a @click="openDetails(record,2,'paidOverdueAmt')">{{ record.paidOverdueAmt }}</a>
        </template>
        <template #monthNormalAmt="{ record }">
          <a @click="openDetails(record,3,'monthNormalAmt')">{{ record.monthNormalAmt }}</a>
        </template>
        <template #monthOverdueAmt="{ record }">
          <a @click="openDetails(record,4,'monthOverdueAmt')">{{ record.monthOverdueAmt }}</a>
        </template>
        <template #totalAmt="{ record }">
          <a @click="openDetails(record,0,'totalAmt')">{{ record.totalAmt }}</a>
        </template>
        <template #receiveContent="{ record }">
          <div class="flex al-center">
            <div style="width: 90%;">
              <span class="m-r1" :style="{color:record.receiveLevel?.value === 1 ? '#15AD31':'#DB2107'}">{{ record.receiveLevel?.label }}</span>
              {{ record.receiveContent }}
            </div>
            <div>
              <!-- <div> <a @click="newAdd(record)">添加</a></div> -->
              <a v-if="record.receiveId" @click="recordLook(record)">记录</a>
            </div>
          </div>
        </template>
        <template #vinNo="{record}">
           {{ record.vinNo }}
           <div>{{ record.brand }} {{ record.model }}</div>
        </template>
        </a-table>
      </div>
    </a-modal>

    <a-modal v-model:visible="recVisible" title="回款描述" @cancel="value = null" @ok="submit">
      <div class="m-b1">回款情况<span style="color:red">*</span></div>
      <a-select style="width: 100%" v-model:value="receiveLevel" placeholder="请选择">
        <a-select-option :value="1">正常</a-select-option>
        <a-select-option :value="2">困难</a-select-option>
      </a-select>
      <div class="m-t2 m-b1">原因<span style="color:red">*</span></div>
      <a-textarea v-model:value="remValue" placeholder="请输入"></a-textarea>
    </a-modal>
    <a-modal title="回款描述记录" v-model:visible="recordLookVisible" footer="" @cancel="receiveData = []">
      <div  class="recordLookBox">
        <div v-for="item in receiveData" :key="item.id" class="m-b1">
          <a-card>
            <div>回款情况：<span>{{item.receiveLevel?.label}}</span></div>
            <div>添加人：<span>{{item.empName}}</span></div>
            <div>添加时间：<span>{{item.createTime}}</span></div>
            <div>添加内容：<span>{{ item.content }}</span></div>
          </a-card>
        </div>
      </div>
    </a-modal>
   <!-- 月份列表 -->
   <a-modal v-model:visible="monthVisible" :title="monTitle" footer="" width="80%"  :bodyStyle="{padding:'10px',background:'#eee'}" destroyOnClose>
      <DetailsModal :monthData="monthData"></DetailsModal>
    </a-modal>
  </div>
</template>
  
  <script setup>
import { onMounted, ref, reactive, nextTick } from 'vue'
import SearchList from '@/components/searchList'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'
import OrderInfo from '@/views/order/positionInfo/orderInfo'
import CustomerDetail from '@/views/crmManagement/tool/customerDetail/index.vue'
import FormList from '@/components/formList'
import ResultFollow from '@/views/components/followOrder'
import { cloneDeep } from 'lodash-es'
import { transitionTimeYMD, transitionTimeYM, addressTranString, addressTran } from '@/utils/util'
import DetailsModal from './keyIssues/detailsModal'
import {  receivePlanId } from '@/api/businessCenter/businessCenter'
const store = useStore()
const emit = defineEmits(['update:visible', 'searchAdd', 'resetBtn', 'setRemaekOk', 'download', 'changeBtn','searchChange'])
const props = defineProps({
  dataAmt:{
 type: Number,
    default: 0
  },
  styleType:{
    type:Number,
    default: null
  },
  pagination: {
    type: Object,
    default: {}
  },
  x: {
    type: Number,
    default: 0
  },
  item: {
    type: Object,
    default: {}
  },
  title: {
    type: String,
    default: ''
  },
  visible: {
    type: Boolean,
    default: false
  },
  iptData: {
    type: Array,
    default: () => []
  },
  data: {
    type: Array,
    default: () => []
  },
  columns: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  },
  totalClueNum: {
    type: Number,
    default: 0
  },
  subItemList: {
    type: Object,
    default: []
  },
  dataLength: {
    type: Number,
    default: 0
  },
  colorIdx: {
    type: Number,
    default: null
  },
  isAll: {
    type: Boolean,
    default: false
  },
  keyType: {
    type: String,
    default: 'pageType'
  }
})

const tableRef = ref(null)
const record = ref({})
const SearchListRef = ref(null)
const monTitle = ref('')
const monthVisible = ref(false)
const monthData = ref({})
const msg = ref({})
const recVisible = ref(false)
const remValue = ref(null)
const receiveLevel = ref(null)
const recordLookVisible = ref(false)
const receiveData = ref([])

const recordLook = (record) => {
  recordLookVisible.value = true
  receivePlanId(record.receiveId).then(res => {
    if (res.code !== 10000) return
    receiveData.value = res.data
  })
}
const newAdd = (record) => {
  msg.value = record
  recVisible.value = true
}
const arr = {
  0: '合计',
  1: '到收-正常',
  2: '到收-逾期',
  3: '记账/月结-正常',
  4: '记账/月结-逾期'
}
const openDetails = (record, type, key) => {
  if(record[key] == 0) return
  monTitle.value = `${props.item.title}-${arr[type]}-` + record.salesman
  monthVisible.value = true
  record.type = type
  record.key = key
  record.title = monTitle.value
  monthData.value = record
}
const searchChange = (e) => {
  emit('searchChange',e)
}




const cancel = () => {
  emit('update:visible', false)
}
const indexKey = ref(null)

const searchAdd = (e) => {
  console.log(e);
  let msg = cloneDeep(e)
  emit('searchAdd', msg)
}
const resetBtn = () => {
  emit('resetBtn')
}
const changeBtn = (e) => {
  indexKey.value = e.colorType
  emit('changeBtn', e.colorType, props.item.pageType)
}


const tabstatus = ref([
  {
    name: '全部',
    id: 1,
    total: 0,
    colorType: null
  },
])
function addEvent (element, eventType, callback) {
  if (element.addEventListener) {
    element.addEventListener(eventType, callback, false);
  } else if (element.attachEvent) {  // 兼容IE8及以下
    element.attachEvent('on' + eventType, callback);
  } else {
    element['on' + eventType] = callback;  // 兼容IE使用旧式方法
  }
}


onMounted(() => {
  if (props.subItemList.length > 0) {
    tabstatus.value[0].total = props.totalClueNum
    let list = []
    props.subItemList.forEach((opt) => {
      let msg = {
        name: opt.title,
        total: opt.num,
        colorType: opt[props.keyType]
      }
      if (props.isAll) {
        tabstatus.value.push(msg)
      } else {
        list.push(msg)
        tabstatus.value = list
      }
    })
  } else {
    tabstatus.value = []
  }
  indexKey.value = props.colorIdx
  nextTick(() => {
    setTimeout(() => {
      if (tableRef.value) {
        const tableBody = tableRef.value.$el.querySelector('.ant-table-body');
        addEvent(tableBody, 'scroll', (e) => {
          const { scrollTop, clientHeight, scrollHeight } = e.target;
          if (scrollTop + clientHeight >= scrollHeight - 100) {
            // 滚动条触底，执行加载数据的操作
            emit('theBottom')
          }
        })
      }
    }, 1000)
  })

})
defineExpose({
  SearchListRef
})

  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  height: 620px;
  background: #fff;
}

.statusBox {
  border-bottom: 1px solid #eee;
  background: #fff;
}

.item {
  padding: 10px;
  border-bottom: 4px solid transparent;
  margin-right: 40px;
  font-size: 16px;
}
.changeItem {
  border-bottom: 4px solid #0066cc;
  color: #0066cc;
}

.remark-content {
  width: 80%;
  max-height: 80px;
  overflow: hidden;
  text-align: left;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.isExpatriate {
  min-width: 16px;
  height: 16px;
  background-color: rgba(57, 106, 252, 1);
  border-radius: 2px;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
.ant-typography {
  margin: 0;
}
</style>