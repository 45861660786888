<template>
  <div >
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
    <div style="background: #fff;padding:10px" class=" m-t1">
      <div class=" ">当前筛选出<a>{{ dataSource.length }}</a>条&nbsp;&nbsp;总金额&nbsp;&nbsp;<a>{{totalAmt}}</a>&nbsp;&nbsp;元</div>
    <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" >
      <template #sort="{index}">
        {{ index+ 1 }}
      </template>
      <template #startAddress="{ record}">
        {{ record.startAddress }}<br>
        {{ record.endAddress }}
      </template>
      <template #settableAmt="{ record}">
        <span style="color:red">{{record.settableAmt }}</span>
      </template>
    </a-table>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import { receivableMonthDetails } from '@/api/financialCenter/financialCenter'
import { useStore } from 'vuex'
import {  compute, IfNull } from '@/utils/util'
const store = useStore()
const props = defineProps({
  monthData: {
    type: Object,
    default: {}
  },
})
const totalAmt = ref(0)
const data = ref([])
const dataSource = ref([])
const  columns = ref([
  {
    title:'序号',
    dataIndex: 'sort',
    align: 'center',
    slots:{
    customRender: 'sort'
    }
  },
  {
    title:'订单号',
    dataIndex: 'orderId',
    align: 'left',
  },
  {
    title:'分单员',
    dataIndex: 'sourceMan',
    align: 'left',
  },
  {
    title:'录单员',
    dataIndex: 'salesman',
    align: 'left',
  },
  {
    title:'起-终',
    dataIndex: 'startAddress',
    slots:{
    customRender: 'startAddress'
    },
    align: 'left',
  },
  {
    title:'完成时间',
    dataIndex: 'orderFinishTime',
    align: 'left',
  },
  {
    title:'结算方式',
    dataIndex: 'orderSettlement.label',
    align: 'left',
  },
  {
    title:'合同总金额',
    dataIndex: 'contractAmt',
    align: 'right',
  },
 
  {
    title:'已结算金额',
    dataIndex: 'settledAmt',
    align: 'right',
  },
  {
    title:'未结算金额',
    dataIndex: 'settableAmt',
    slots:{
    customRender: 'settableAmt'
    },
    align: 'right',
  },
  {
    title:'逾期天数',
    dataIndex: 'overdueDay',
    align: 'right',
  },
])
const loading = ref(false)
const searchAdd = (e) => {
  if(IfNull(e.orderId)) {
    dataSource.value = data.value.filter(item =>{
    return item.orderId.includes(e.orderId)
    })
    let list = dataSource.value.map(item => item.settableAmt)
    totalAmt.value  =  list.length === 0 ? 0 :  compute('+',...list).result
  }
}
const resetBtn = () => {
  dataSource.value = data.value
    let list = dataSource.value.map(item => item.settableAmt)
    totalAmt.value  =  list.length === 0 ? 0 :  compute('+',...list).result
 
}
onMounted(() => {
  loading.value = true
  receivableMonthDetails({
    customerId: props.monthData.customerId,
    salesmanId: props.monthData.salesmanId,
    type: props.monthData.type,
  }).then(res => {
    if (res.code !== 10000) return
    dataSource.value = res.data
    data.value = res.data
    let list = dataSource.value.map(item => item.settableAmt)
    totalAmt.value  =  list.length === 0 ? 0 :  compute('+',...list).result 
  }).finally(() => {
    loading.value = false
  })
})
const iptData = ref([
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
 
])
</script>

<style lang="less" scoped>
</style>