<template>
  <a-card :bodyStyle="{ padding: 0 }">
    <template #title>
      <a-form :model="searchForm" layout="inline">
        <a-form-item style="width: 13%">
          <a-input placeholder="标题" v-model:value="searchForm.name" allowClear>
          </a-input>
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input placeholder="摘要" v-model:value="searchForm.summary" allowClear>
          </a-input>
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input placeholder="流程实例ID" v-model:value="searchForm.flowInstanceId" allowClear>
          </a-input>
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-select placeholder="类型" v-model:value="searchForm.flowType" @change="handleChange" allowClear>
            <a-select-option :value="0">承运商结算(收款)</a-select-option>
            <a-select-option :value="1">承运商结算(付款)</a-select-option>
            <a-select-option :value="2">客户结算</a-select-option>
            <a-select-option :value="3">返款流程</a-select-option>
            <a-select-option :value="4">附加费流程</a-select-option>
            <a-select-option :value="5">质损流程</a-select-option>
            <a-select-option :value="6">开票流程</a-select-option>
            <a-select-option :value="7">作废流程</a-select-option>
            <a-select-option :value="8">红冲流程</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-select placeholder="审核状态" v-model:value="searchForm.flowInstanceStatus" allowClear>
            <a-select-option value="">全部</a-select-option>
            <a-select-option :value="1">审批中</a-select-option>
            <a-select-option :value="6">审批完成</a-select-option>
            <a-select-option :value="4">审批撤回</a-select-option>
            <a-select-option :value="5">审批拒绝</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-date-picker placeholder="提交时间起" style="width:100%" v-model:value="searchForm.submitStartTime"
            format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" allowClear />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-date-picker placeholder="提交时间止" style="width:100%" v-model:value="searchForm.submitEndTime"
            format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" allowClear />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-date-picker placeholder="审批时间起" style="width:100%" v-model:value="searchForm.doneStartTime"
            format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" allowClear />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-date-picker placeholder="审批时间止" style="width:100%" v-model:value="searchForm.doneEndTime" format="YYYY-MM-DD"
            valueFormat="YYYY-MM-DD" allowClear />
        </a-form-item>
        <a-form-item style="width: 13%;">
          <a-space>
            <a-button style="height:100%" type="primary" @click="searchData">
              <template #icon>
                <SearchOutlined />
              </template>
              搜索
            </a-button>
            <a-button @click="resetSearch" :loading="loading">重置</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </template>
    <div style="height:10px"></div>
    <a-table :dataSource="listData" rowKey="instanceId" :loading="loading" :customRow="customRow" bordered
      :pagination="false" :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)">
      <a-table-column width="20%" title="标题" data-index="instanceName">
      </a-table-column>
      <a-table-column width="30%" title="摘要" data-index="summary">
        <template #default="{ text: summary }">
          <div v-for="(item, index) in summary" :key="index">
            <div class="flex" >
              <div style="text-align: right;width: 100px;color:#6B778C">{{ item.label }}:</div><div class="m-l1" >{{ item.value }}</div>
            </div>
          </div>
        </template>
      </a-table-column>
      <a-table-column width="10%" title="发起人" data-index="sponsor">
      </a-table-column>
      <a-table-column width="10%" title="发起时间" data-index="createTime">
      </a-table-column>
      <a-table-column width="10%" title="最后操作时间" data-index="lastTime">
      </a-table-column>
      <a-table-column width="20%" title="流程状态" data-index="flowInstanceStatus">
        <template #default="{ text: flowInstanceStatus }">
          <span :style="{ color: statusType[flowInstanceStatus.value] }">{{ flowInstanceStatus.label }}</span>
        </template>
      </a-table-column>
    </a-table>

  </a-card>
  <a-modal v-model:visible="modalVisible" title="详情" width="80%" footer="" :maskClosable="false"
    :bodyStyle="{ height: '70vh', overflow: 'auto', background: '#f0f2f5', padding: '0 24px', }" destroyOnClose centered>
    <detail v-model:flowInstanceId="flowInstanceId" type="myProcessed" v-model:flowInstanceTaskId="flowInstanceTaskId"
      @ok="handleOk" />
  </a-modal>
  <view class="vPagination">
    <a-pagination :show-total="pagination.showTotal" show-size-changer v-model:current="pagination.current"
      v-model:pageSize="pagination.pageSize" :total="pagination.total" @change="handleTableChange"
      @showSizeChange="handleTableChange" />
  </view>
</template>
<script>
import { page } from '@/api/system/bpm/instance'
import { toRefs, reactive, onMounted } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue'
import { Form } from 'ant-design-vue'
import detail from './detail.vue'
export default {
  setup () {
    const searchForm = reactive({
      name: '',
      summary: '',
      flowInstanceId: '',
      flowType: null,
      flowInstanceStatus: null,
      submitStartTime: '',
      submitEndTime: '',
      doneStartTime: '',
      doneEndTime: ''
    })

    const state = reactive({
      open: false,
      loading: false,
      statusType: {
        0: '#26a5fe',
        1: '#1890FF',
        2: '#f9b603',
        3: '#88888',
        5: '#F70303',
        6: '#1BBA12'
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      listData: [],
      modalVisible: false,
      flowInstanceId: '',
      flowInstanceTaskId: ''
    })
    const useForm = Form.useForm
    const { resetFields } = useForm(searchForm, {})

    const loadData = (index) => {
      state.loading = true
      page('done', {
        ...searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
        state.loading = false
      })
    }
    onMounted(loadData)

    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const resetSearch = () => {
      state.pagination.current = 1
      resetFields()
      loadData()
    }
    const customRow = record => {
      return {
        onClick: () => {
          state.flowInstanceId = record.instanceId
          state.flowInstanceTaskId = record.instanceTaskId
          state.modalVisible = true
        }
      }
    }

    const handleOk = () => {
      state.modalVisible = false
      loadData()
    }
    const handleTableChange = (current, pageSize) => {
      console.log('current')
      state.pagination.current = current
      state.pagination.pageSize = pageSize
      loadData()
    }
    return {
      searchForm,
      ...toRefs(state),
      handleTableChange,
      loadData,
      resetSearch,
      resetFields,
      searchData,
      customRow,
      handleOk
    }
  },
  components: {
    SearchOutlined,
    detail
  }
}
</script>
<style lang="less" scoped>.ant-form-item {
  margin-bottom: 4px;
}

.vPagination {
  width: 100%;
  text-align: center;
}</style>
