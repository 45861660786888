<template>
  <suy-table :reload="loadData" v-model:columns="columns">
    <template #search>
      <a-form ref="formQueryRef" layout="inline" :model="searchForm">
        <a-select style="width: 100px" v-model:value="searchForm.regionalType"  @change="pagination.current = 1,loadData()" placeholder="选择地理类型">
          <a-select-option :value="1">国内</a-select-option>
          <a-select-option :value="2">国外</a-select-option>
        </a-select>
        <div style="width:10px"></div>
        <a-form-item label="">
          <a-input v-model:value="searchForm.name" placeholder="司机姓名" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="queryData()">
            <template #icon>
              <SearchOutlined />
            </template>查询
          </a-button>
          <a-button style="margin-left:10px" @click="addShow = true">+添加司机</a-button>
        </a-form-item>
      </a-form>
    </template>
    <a-table size="small" :columns="[sortColumn, ...columns]" :rowKey="(record, index) => { return index }" :data-source="listData" :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #sort="{ index }">
        {{ index + 1 }}
      </template>
      <template #operation="{ record }">
        <span>
          <a @click="onclick(record, '4')">确认司机</a>
        </span>
      </template>
    </a-table>
  </suy-table>
  <a-modal :width="750" v-model:visible="addShow" footer="" @cancel="onNoAddForm" destroyOnClose>
    <template #title><span style="font-size:20px">新增司机</span></template>
    <a-form layout="vertical" ref="formRef" :model="addForm" :rules="rules">
      <a-row :gutter="[24, 12]">
        <a-col :span="12">
          <a-form-item label="地理分类">
            <a-select :disabled="true" :value="2" placeholder="选择大车类型">
              <a-select-option :value="1">国内</a-select-option>
              <a-select-option :value="2">国外</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="司机姓名">
            <a-input v-model:value="addForm.name" placeholder="请填写司机姓名" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="司机电话" name="mobile">
            <a-input v-model:value="addForm.mobile" placeholder="请填写司机电话" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="身份证号">
            <a-input v-model:value="addForm.idNumber" placeholder="请填写司机身份证号" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="驾驶证" @click="flag = 1">
            <a-upload v-model:file-list="drivingLicense" name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :before-upload="beforeUpload" @change="handleChange">
              <img v-if="addForm.driverLicenseUrl.url" style="width:150px;height:150px" :src="addForm.driverLicenseUrl.url" />
              <div v-else>
                <loading-outlined v-if="drivingLoading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="货运资格从业证" @click="flag = 2">
            <a-upload v-model:file-list="qualificationLincense" name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :before-upload="beforeUpload" @change="handleChange">
              <img v-if="addForm.freightCertificateUrl.url" style="width:150px;height:150px" :src="addForm.freightCertificateUrl.url" />
              <div v-else>
                <loading-outlined v-if="qualificationLoading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div style="display:flex;justify-content: flex-end;margin-top:15px">
      <a-button size="large" :loading="btnLoading" style="margin-right:15px" @click="addShow = false">取消</a-button>
      <a-button size="large" type="primary" :loading="btnLoading" @click="addData">新增</a-button>
    </div>
  </a-modal>
</template>
<script>
import { toRefs, reactive, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { page } from '@/api/crm/carrer/driver'
import { fileUploadPublic as fileUpload } from '@/api/global'
import { addDriver } from '@/api/carrier/driverList'
import { mobileValidate, idNumberValidate } from '@/utils/validate'
import SuyTable from '@/components/SuyTable'

export default {
  components: {
    SuyTable,
    PlusOutlined,
    LoadingOutlined
  },
  props: { carrierId: String },
  setup (props, context) {
    const state = reactive({
      carrierId: '',
      flag: 0,
      addShow: false,
      visible: false,
      loading: false,
      btnLoading: false,
      drivingLoading: false,
      qualificationLoading: false,
      mirrorAddForm: {},
      addForm: {
        regionalType: 2,
        name: '',
        mobile: '',
        idNumber: '',
        driverLicenseUrl: { url: '' },
        driverLicense: '',
        freightCertificateUrl: { url: '' },
        freightCertificate: ''
      },
      pagination: {
        pageSize: 8,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['8', '20', '30', '40'],
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {
        regionalType: 2
      },
      drivingLicense: [],
      qualificationLincense: [],
      listData: [],
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '电话',
          dataIndex: 'mobile',
          key: 'mobile'
        },
        // {
        //   title: '司机类型',
        //   dataIndex: 'driverType.label',
        //   key: 'driverType.label'
        // },
        {
          title: '身份证号码',
          dataIndex: 'idNumber',
          key: 'idNumber'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 200,
          slots: {
            customRender: 'operation'
          }
        }
      ],
      rules: {
        // mobile: [{ required: false, message: '请填写司机电话', trigger: 'blur' }, { pattern: mobileValidate, message: '请填写正确的电话号码', trigger: 'blur' }],
        // idNumber: [{ required: false, message: '请填写司机身份证号', trigger: 'blur' }, { pattern: idNumberValidate, message: '请填写正确的身份证号码', trigger: 'blur' }]
      },
      form: {}
    })
    const loadData = () => {
      state.loading = true
      page({
        ...state.searchForm,
        carrierId: state.carrierId,
        current: state.pagination.current,
        size: state.pagination.pageSize,
        name: state.searchForm.name
      }, props.carrierId)
        .then(res => {
          if (res.code === 10000) {
            state.listData = res.data.records
            state.pagination.current = res.data.current
            state.pagination.pageSize = res.data.size
            state.pagination.total = res.data.total
            state.loading = false
          }
        })
    }
    const oncarid = (parameter) => {
      state.carrierId = parameter
      loadData()
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }

    const queryData = () => {
      state.pagination.current = 1
      state.pagination.pageSize = 10
      loadData()
    }
    onMounted(() => {
      if (props.carrierId) state.carrierId = props.carrierId
      state.mirrorAddForm = JSON.stringify(state.addForm)
      loadData()
    })
    const onNoAddForm = () => {
      state.addShow = false
      state.editShow = 0
      state.addForm = JSON.parse(state.mirrorAddForm)
    }
    const onclick = (recode, type) => {
      switch (type) {
        case '4':
          context.emit('onondriverName', recode)
          break
        default:
          message.success('出现错误' + type)
      }
    }
    const beforeUpload = file => {
      switch (state.flag) {
        case 1:
          state.drivingLicense.push(file)
          break
        case 2:
          state.qualificationLincense.push(file)
          break
      }
      return false
    }
    // 图片上传
    const handleChange = info => {
      const formData = new FormData()
      switch (state.flag) {
        case 1:
          state.drivingLoading = true
          formData.append('file', state.drivingLicense[0])
          fileUpload(formData).then(res => {
            if (res.code === 10000) {
              if (res.data.url !== '' || res.data.url !== null || res.data.url !== undefined) {
                state.addForm.driverLicenseUrl = res.data
                state.addForm.driverLicense = res.data.id
                state.drivingLoading = false
                state.drivingLicense = []
              }
            }
          })
          break
        case 2:
          state.qualificationLoading = true
          formData.append('file', state.qualificationLincense[0])
          fileUpload(formData).then(res => {
            if (res.code === 10000) {
              if (res.data.url !== '' || res.data.url !== null || res.data.url !== undefined) {
                state.addForm.freightCertificateUrl = res.data
                state.addForm.freightCertificate = res.data.id
                state.qualificationLoading = false
                state.qualificationLincense = []
              }
            }
          })
          break
      }
    }
    const addData = () => {
      state.btnLoading = true
      addDriver({ ...state.addForm, carrierId: state.carrierId })
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            loadData()
          }
        }).catch(err => { console.log(err) }).finally(() => { state.addShow = false })
        .finally(() => { state.btnLoading = false })
    }
    return {
      ...toRefs(state),
      loadData,
      handleTableChange,
      onclick,
      queryData,
      oncarid,
      addData,
      handleChange,
      beforeUpload,
      onNoAddForm
    }
  }
}
</script>
<style lang="less" scoped>
:deep(.ant-upload) {
  width: 150px !important;
  height: 150px !important;
}
</style>