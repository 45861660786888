<template>
  <div style="position: relative;">
    <div class="data-paginate" v-show="statisticsData.length > 10">
      <LeftCircleFilled style="font-size:20px;color:#B0B8BF;cursor: pointer;" @click="leftMove" />
      <RightCircleFilled style="font-size:20px;color:#B0B8BF;cursor: pointer;margin-left:5px" @click="rightMove" />
    </div>
    <!-- 头部信息统计模块 -->
    <div class="big-car">
      <div class="data-statistics">
        <div v-show="groupListData.length > 0"
          style="margin: 15px 0px 30px 15px;display:flex;flex-direction:column;justify-content:center;align-items:center">
          组内查询
          <a-select v-model:value="value1" style="width: 120px" :placeholder="$store.getters.userName">
            <a-select-option v-for="item in groupListData" :key="item.empId" :value="empId" @click="groupChange(item)">{{
              item.empName }}</a-select-option>
          </a-select>
        </div>
        <div v-for="(item, index) in statisticsData" :key="index" class="data-statistics-content">
          <div @click="statisticsSearch(item, index)" class="statistics-content"
            :style="{ flex: index === statisticsData.length - 1 ? '1' : '' }">
            <div class="statistics-title">{{ item.truckStatus?.label }}</div>
            <div class="data-statistics-data">{{ item.num }}</div>
            <div v-show="item.status === 1" class="data-status">待审核 00</div>
          </div>
          <a-divider type="vertical" style="height: 60px; background-color: #eee" />
        </div>
      </div>
    </div>
    <a-divider style="height: 10px;margin-top:0; background-color: #EEEEEE;border:none" />
    <GlobalTable :loading="loading">
      <!-- 搜索栏 -->
      <template #headerSearch>
        <a-form layout="inline" :model="searchForm">
          <a-form-item label="">
            <a-input style="width: 120px" v-model:value="searchForm.carNo" placeholder="大车车牌" />
          </a-form-item>
          <a-form-item label="">
            <a-select allowClear v-model:value="searchForm.truckType" placeholder="大车类型" style="width:120px">
              <a-select-option :value="1">大板车</a-select-option>
              <a-select-option :value="2">小板车</a-select-option>
              <a-select-option :value="3">货车</a-select-option>
              <a-select-option :value="4">地跑</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="">
            <a-input style="width: 120px" v-model:value="searchForm.vehicleNo" placeholder="小车车牌" />
          </a-form-item>
          <a-form-item label="">
            <a-cascader style="width:150px" :allowClear="true" :show-search="{ filter }"
              :options="$store.state.app.PClist" v-model:value="searchForm.start" :changeOnSelect="true"
              @change="setStartAddress" placeholder="起运地" />
          </a-form-item>
          <a-form-item label="">
            <a-cascader style="width:150px" :allowClear="true" :show-search="{ filter }"
              :options="$store.state.app.PClist" v-model:value="searchForm.end" :changeOnSelect="true"
              @change="setEndAddress" placeholder="目的地" />
          </a-form-item>
          <a-form-item label="">
            <a-input allowClear v-model:value="searchForm.dispatcher" placeholder="派车人" style="width:120px" />
          </a-form-item>
          <a-form-item label="">
            <a-input style="width:150px" v-model:value="searchForm.carrierName" placeholder="承运商名称" />
          </a-form-item>
          <a-form-item label=""> 
            <a-select v-model:value="searchForm.gpsUpdateStatus" :allowClear="true" placeholder="是否更新"
              style="width: 120px">
              <a-select-option :value="1">是</a-select-option>
              <a-select-option :value="0">否</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label=""> 
            <a-select v-model:value="searchForm.regionalType" :allowClear="true" placeholder="运单分类"
              style="width: 120px">
              <a-select-option :value="1">国内</a-select-option>
              <a-select-option :value="2">国外</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="">
            <div class="operate-btn">
              <a-button class="header-search-btn" @click="searchData" :loading="loading">搜索</a-button>
              <a-button class="header-reset-btn" @click="resetData" :loading="loading">重置</a-button>
            </div>
          </a-form-item>
        </a-form>
      </template>
      <!-- 操作按钮 -->
      <template #operateBtn>
        <a-button @click="addPlanShow = true">新增国内发运</a-button>
        <a-button @click="intlAddPlanShow = true">新增国外发运</a-button>
        <span>共{{ pagination.total }}条</span>
      </template>
      <!-- 表格主体 -->
      <div class="table-data">
        <!-- <a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, onSelect: onSelect }" -->
        <a-table ref="tableRef" size="small" :columns="columns" :row-key="record => record.transTruckId"
          :data-source="bigCarList" bordered :scroll="{ x: 2600, y: 400 }" :pagination="false" :loading="loading"
          @change="handleTableChange">
          <template #operation="{ record }">
            <div style="position: relative;">
              <a class="ant-dropdown-link" @click="detailData = record, loadingVehShow = true">
                操作
              </a>
              <!-- <a-dropdown>
                <template #overlay>
                  <a-menu>
                    <a-menu-item>
                      <a @click="detailShow = true">详情</a>
                    </a-menu-item>
                    <a-menu-item>
                      <a-popconfirm title="确认到达" ok-text="确认" cancel-text="关闭" @confirm="confirmArrival">
                        <a>到达</a>
                      </a-popconfirm>
                    </a-menu-item>
                    <a-menu-item v-if="record.truckStatus.value === 0 || record.truckStatus.value === 1">
                      <a @click="loadingVehShow = true">装车</a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown> -->
            </div>
          </template>
          <template #truckStatus="{ record }">
            <div>
              <span :style="{ color: statusColor[record.truckStatus.value] }">{{ record.truckStatus?.label
              }}</span>
            </div>
          </template>
          <template #nowLocation="{record}">
            <div v-show="record.nowLocation">
              <div>{{ record.nowLocation }}</div>
              <div>{{ record.gpsTime  }}</div>
            </div>
          </template>
        </a-table>
        <div style="text-align:right;margin-top:10px"><a-button @click="nextPage" :disabled="bigCarList.length >= pagination.total">下一页</a-button></div>
      </div>
    </GlobalTable>
    <!-- 新增大车运输任务 -->
    <AddPlan ref="truckPlan" :windowShow="addPlanShow" :transTruckId="detailData.transTruckId"
      @statusShow="closePlanModal" />
      <!-- 新增国外运输任务 -->
      <InternationalAddPlan  :windowShow="intlAddPlanShow" 
      @statusShow="intlAddPlanShow = false" />
    <!-- 装车 -->
    <LoadingVehilce :windowShow="loadingVehShow" :transTruckId="detailData.transTruckId" @statusShow="loadingVeh" />
    <!-- 详情 -->
    <Detail :transTruckId="detailData.transTruckId" :bigCarNo="detailData.carNo" :detailShow="detailShow"
      @status="getDetailData" />
  </div>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { pageAll as bigPage } from '@/api/transport/vehicle'
import { transArrive, getBigCarStatistics } from '@/api/transport/truck'
import { PlusOutlined, LoadingOutlined, LeftCircleFilled, RightCircleFilled } from '@ant-design/icons-vue'
import GlobalTable from '@/components/GlobalTable'
import Detail from './detail.vue'
import LoadingVehilce from './loadingVehilce.vue'
import AddPlan from './addPlan.vue'
import InternationalAddPlan from './internationalAddPlan.vue'


export default {
  components: {
    GlobalTable,
    PlusOutlined,
    LoadingOutlined,
    LeftCircleFilled,
    RightCircleFilled,
    LoadingVehilce,
    Detail,
    AddPlan,
    InternationalAddPlan,
    
  },
  props: {
    userData: Object
  },
  setup (props) {
    const tableRef = ref(null)
    const store = useStore()
    const state = reactive({
      loading: false,
      shareShow: false,
      detailShow: false,
      intlAddPlanShow:false,
      dispatchShow: false,
      dataDetailShow: false,
      loadingVehShow: false,
      addPlanShow: false,
      manualSchedulingShow: false,
      abnormalReportShow: false,
      expenseReportShow: false,
      unloadingAddress: false,
      vEle: undefined,
      timerId: null,
      startIndex: 0,
      globalIndex: null,
      statisticsVal: null,
      dispatchId: '',
      detailData: {},
      searchForm: {
        truckStatus: null,
        truckType: null,
        dispatcher: null,
        carNo: null,
        vehicleNo: null,
        carrierName: null,
        startAddress: {},
        endAddress: {},
        regionalType:null
      },
      userData: {
        empName: '',
        empType: { value: 0 }
      },
      mirrorSearchForm: {},
      transportStatusList: [
        {
          value: 0,
          label: '待安排',
        },
        {
          value: 1,
          label: '已安排'
        },
        {
          value: 2,
          label: '已发运'
        },
        {
          value: 4,
          label: '已完成'
        },
        {
          value: 5,
          label: '已取消'
        }
      ],
      statusColor: {
        0: '#FF8D1A',
        1: '#3470FF',
        2: '#0066CC',
        4: '#344563'
      },
      sliceTable: [],
      batchList: [],
      selectedRowKeys: [],
      batchList: [],
      groupListData: [],
      dataSource: [], // 大车数组-镜像
      bigCarList: [], // 大车数组-渲染
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      statisticsData: [],
      columns: [
        {
          title: '操作',
          align: 'center',
          width: '3%',
          slots: {
            customRender: 'operation'
          }
        },
        {
          title: '当前状态',
          width: '4%',
          align: 'center',
          slots: {
            customRender: 'truckStatus'
          }
        },
        {
          title: '承运商',
          dataIndex: 'carrierName',
          width: '9%',
          align: 'center'
        },
        {
          title: '总运费',
          dataIndex: 'freightCost',
          width: '3%',
          align: 'center'
        },
        {
          title: '大车车牌',
          dataIndex: 'carNo',
          width: '4%',
          align: 'center'
        },
        {
          title: '大车类型',
          dataIndex: 'truckType.label',
          width: '3%',
          align: 'center'
        },
        {
          title: '司机',
          dataIndex: 'driverName',
          width: '4%',
          align: 'center'
        },
        {
          title: '运单分类',
          dataIndex: 'regionalType.label',
          width: '4%',
          align: 'center'
        },
        {
          title: '当前位置',
          dataIndex: 'nowLocation',
          width: '10%',
          align: 'center',
          slots: {
            customRender: 'nowLocation'
          }
        },
        {
          title: '起运地',
          dataIndex: 'startAddress',
          width: '10%',
          align: 'center'
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          align: 'center',
          width: '10%'
        },
        {
          title: '安排人',
          dataIndex: 'arranger',
          align: 'center',
          width: '4%'
        },
        {
          title: '安排时间',
          dataIndex: 'createTime',
          align: 'center',
          width: '6%'
        },
        {
          title: '发车人',
          dataIndex: 'dispatcher',
          align: 'center',
          width: '4%'
        },
        {
          title: '发车时间',
          dataIndex: 'departureTime',
          align: 'center',
          width: '6%'
        },
        {
          title: '规定到达时间',
          dataIndex: 'arriveTime',
          width: '6%',
          align: 'center'
        },
        {
          title: '超时状态',
          align: 'overdueText',
          width: '4%',
          align: 'center',
        },
        {
          title: '已占车位',
          dataIndex: 'usedParkPlace',
          width: '4%',
          align: 'center',
        }
      ]
    })
    onMounted(() => {
      state.dispatchId = store.getters.info.id
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
      let DOM = document.querySelectorAll('.table-data .ant-table-body')
      // DOM[1].addEventListener('scroll', e => {
      //   if (e.target.scrollTop > 0) {
      //     clearTimeout(state.timerId)
      //     state.timerId = setTimeout(() => {
      //       if (e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) < 10) {
      //         if (state.bigCarList.length < state.pagination.total) state.pagination.current++, loadData()
      //       }
      //       state.timerId = null
      //     }, 500)
      //   }
      // })
    })
    const getEmpData = () => {
      state.userData = props.userData.userData
      state.groupListData = props.userData.groupListData
    }
    // 小组成员切换
    const groupChange = item => {
      state.dispatchId = item.empId
      state.bigCarList = []
      state.statisticsVal = null
      state.globalIndex = null
      loadData()
      let DOMS = document.querySelectorAll('.big-car .statistics-content')
      for (let i = 0; i < DOMS.length; i++) {
        DOMS[i].style.color = '#344563'
        DOMS[i].style.background = ''
      }
    }
    const leftMove = () => {
      let DOM = document.querySelector('.big-car .data-statistics')
      let left = Number(DOM.style.left.replace('vw', ''))
      if (left < 0) {
        DOM.style.left = left + 8 + 'vw'
      }
    }
    const rightMove = () => {
      let DOM = document.querySelector('.big-car .data-statistics')
      let left = Number(DOM.style.left.replace('vw', ''))
      DOM.style.left = left - 8 + 'vw'
    }
    // 统计查询
    const statisticsSearch = (item, index) => {
      let DOMS = document.querySelectorAll('.big-car .statistics-content')
      state.bigCarList = []
      if (state.globalIndex !== index) {
        state.globalIndex = index
        for (let i = 0; i < DOMS.length; i++) {
          DOMS[i].style.color = '#344563'
          DOMS[i].style.background = ''
          if (i === index) {
            DOMS[index].style.color = '#fff'
            DOMS[index].style.background = 'rgba(0,102,204,.8)'
            DOMS[index].style.borderRadius = '8px'
          }
        }
        state.statisticsVal = item.truckStatus.value
        state.pagination.current = 1
        loadData(state.statisticsVal)
      } else {
        DOMS[state.globalIndex].style.color = '#344563'
        DOMS[state.globalIndex].style.background = ''
        state.statisticsVal = null
        state.globalIndex = null
        state.pagination.current = 1
        loadData(state.statisticsVal)
      }
    }
    // 统计信息
    const getStatistics = () => {
      getBigCarStatistics().then(res => {
        if (res.code === 10000 && res.data) {
          state.statisticsData = res.data
        } else message.error('暂无统计数据')
      })
    }
    const loadData = (type) => {
      state.loading = true
      bigPage({
        ...state.searchForm,
        dispatchId: state.dispatchId === '' ? state.userData.empId : state.dispatchId,
        truckStatus: state.statisticsVal || type,
        current: state.pagination.current,
        size: 10,
      }).then(res => {
        if (res.code === 10000) {
          if (res.data) {
            state.pagination.total = res.data.total
            state.dataSource = JSON.stringify(res.data.records)
            if (state.bigCarList.length < res.data.total) {
              JSON.parse(state.dataSource).forEach(item => {
                state.bigCarList.push(item)
              })
            }
            if (state.bigCarList.length >= res.data.total) state.pagination.current--
          } else message.error('暂无数据')
        }
      }).finally(() => {
        state.loading = false
      })
      getStatistics()
    }
    const onSelectChange = (e, v) => {
      state.selectedRowKeys = e
      state.batchList = v
    }
    const onSelect = e => {
      console.log(e);
    }
    const handleTableChange = (pag, filters, sorter) => {
      // {current:1,pageSize:10}
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.current * 10
    }
    // 运输状态筛选 ---待删
    const transStatusChange = (e, v) => {
      state.searchForm.truckStatus = v
      state.searchForm.truckStatus = JSON.parse(JSON.stringify(state.searchForm.truckStatus))
    }
    const setStartAddress = (value, selectedOptions) => {
      state.searchForm.startAddress = {}
      state.searchForm.startAddress.province = value[0]
      state.searchForm.startAddress.city = value[1]
    }

    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.endAddress = {}
      state.searchForm.endAddress.province = value[0]
      state.searchForm.endAddress.city = value[1]
    }
    const resetData = () => {
      state.pagination.current = 1
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.bigCarList = []
      loadData()
    }
    const searchData = () => {
      state.pagination.current = 1
      state.bigCarList = []
      loadData()
      // state.loading = true
      // state.bigCarList = JSON.parse(state.dataSource)
      // // 查询筛选 ↓
      // for (const key in state.searchForm) {
      //   if (state.searchForm[key] == '') continue
      //   if (state.searchForm[key].value === null) continue // 选择框未选择则跳出本次循环并开始下一循环
      //   state.bigCarList = JSON.parse(JSON.stringify(state.bigCarList)).filter(item => {
      //     return JSON.stringify(state.searchForm[key]) == JSON.stringify(item[key])
      //   })
      // }
      // // 查询筛选 ↑
      // setTimeout(() => {
      //   fictitiousScroll()
      //   state.loading = false
      // }, JSON.parse(state.dataSource).length * 2 < 1000 ? 1000 : JSON.parse(state.dataSource).length * 2)
    }
    const startAddressChange = (e, v) => {
      state.startAddress = e
    }
    const endAddressChange = (e, v) => {
      state.endAddress = e
    }
    const acceptOrder = () => {
      state.batchList = []
      state.selectedRowKeys = []
      state.dataSource = []
      loadData()
    }
    const loadingVeh = e => {
      state.loadingVehShow = false
    }
    const getDetailData = e => {
      state.detailShow = e
    }
    // 新增任务
    const closePlanModal = (e) => {
      state.addPlanShow = false
    }
    // 到达
    const confirmArrival = () => {
      transArrive(state.detailData.transTruckId, {})
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            loadData()
          }
        })
    }
    const nextPage = () => {
      state.pagination.current++
      loadData()
    }

    return {
      ...toRefs(state),
      tableRef,
      leftMove,
      rightMove,
      loadData,
      getEmpData,
      nextPage,
      statisticsSearch,
      getStatistics,
      setStartAddress,
      setEndAddress,
      resetData,
      searchData,
      groupChange,
      getDetailData,
      transStatusChange,
      startAddressChange,
      endAddressChange,
      onSelect,
      acceptOrder,
      onSelectChange,
      handleTableChange,
      loadingVeh,
      confirmArrival,
      closePlanModal,
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';

:deep(.ant-form-item) {
  margin-bottom: 10px !important;
}

:deep(.ant-upload-picture-card-wrapper) {
  width: auto !important;
}

:deep(.ant-upload-select-picture-card) {
  margin-bottom: 0 !important;
}

:deep(.ant-table-row) {
  position: relative;
}

:deep(.ant-table-row>td) {
  height: 63px !important;
}

:deep(.ant-table-row>th:last-child) {
  background-color: #f00 !important;
  position: absolute !important;
  right: 0;
  top: 0;
}

:deep(.ant-table-row:nth-child(even)) {
  background-color: #FAFAFA;
}

:deep(.ant-steps-icon-dot) {
  background-color: #0C8226 !important;
}

:deep(.ant-steps-item-icon) {
  margin-left: 9px !important;
}

:deep(.ant-steps-item-tail) {
  width: 112rpx !important;
  margin-left: 47% !important;
}

:deep(.ant-steps-item:first-child) {
  .ant-steps-item-tail {
    display: none;
  }
}

:deep(.ant-steps-item-tail::after) {
  width: calc(100% - 11px) !important;
  height: 1px !important;
  margin-left: -192% !important;
  background-color: #0C8226 !important;
}

:deep(.ant-steps-item:last-child) {
  flex: 1 !important;
}

:deep(.ant-steps-item-error) {
  .ant-steps-icon-dot {
    background-color: #C1C7D0 !important;
  }

  .ant-steps-item-tail::after {
    background-color: #C1C7D0 !important;
  }

  .ant-steps-item-title {
    color: #C1C7D0 !important;
  }

  .ant-steps-item-description {
    color: #C1C7D0 !important;
  }
}

:deep(.ant-steps-item-active) {
  .ant-steps-item-icon {
    width: 8px !important;
    height: 8px !important;
  }

  .ant-steps-item-tail::after {
    background-color: #0066CC !important;
  }

  .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45) !important;
  }
}

:deep(.ant-steps-item-wait) {
  .ant-steps-icon-dot {
    background-color: #0066CC !important;
  }

  .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.85) !important;
  }
}

:deep(.ant-steps-item) {
  width: 50px;
}

:deep(.ant-steps-item:last-child) {
  display: none;
}

:deep(.ant-steps-item-content) {
  width: 100% !important;
  margin: 0 !important;
}

:deep(.ant-steps-item-title) {
  font-size: 12px !important;
}

:deep(.ant-steps-item-description) {
  font-size: 12px !important;
}

.ant-upload-text {
  background-color: #F0F2F5 !important;
  border: none;
  border-radius: 0 !important;
}

.operationBtn {
  position: absolute;
  top: 140%;
  left: 100%;
  display: flex;
  transition: all 1s !important;
  z-index: 99;

  .ant-btn {
    color: #fff;
    background-color: #0066CC;
  }
}

.batch-order-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;

  .ant-btn {
    background-color: #F0F2F5;
  }

  :deep(.ant-btn-primary) {
    color: #fff;
    border-color: #344563;
    background-color: #344563;
  }

  .ant-btn-primary:hover {
    color: #000;
    background-color: #fff;
  }
}

.animated-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #333;
  position: relative;
  overflow: hidden;
  animation: slide-in 2s forwards;
}

.table-data {
  // overflow: scroll;
  // max-height: 400px;
  // max-width: 100%;
}

.data-paginate {
  position: absolute;
  right: 10px;
  top: -10px;
  z-index: 99;
}


:deep(.ant-table-body) {
  // overflow: auto scroll;
  // width:1500px;
  // max-height: 400px;
}

:deep(.ant-table-wrapper) {
  // width: 2600px;
}
</style>