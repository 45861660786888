import { ref } from 'vue'
//  1应收预期未报备 2 应收预期已报备  3利润小于等于0报备 
export const orderiptData1 = ref([
  {
    type: "input",
    placeholder: "分单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "select",
    placeholder: "结算方式",
    value: null,
    prop: "orderSettlement",
    width: 200,
    key: 'value',
    keyVal: 'label',
    opt: []
  },
])
export const ordercolumns1 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '分单员部门',
    dataIndex: 'orgName',
    align: 'left',
  },
  {
    title: '分单员',
    dataIndex: 'salesman',
    align: 'left',
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'left',
  },

  {
    title: '到收',
    align: 'center',
    children: [
      {
        title: '正常',
        dataIndex: 'paidNormalAmt',
        slots: {
          customRender: 'paidNormalAmt'
        },
        align: 'right',
      },
      {
        title: '逾期',
        dataIndex: 'paidOverdueAmt',
        slots: {
          customRender: 'paidOverdueAmt'
        },
        align: 'right',
      }
    ]
  },
  {
    title: '记账/月结',
    align: 'center',
    children: [
      {
        title: '正常',
        dataIndex: 'monthNormalAmt',
        slots: {
          customRender: 'monthNormalAmt'
        },
        align: 'right',
      },
      {
        title: '逾期',
        dataIndex: 'monthOverdueAmt',
        slots: {
          customRender: 'monthOverdueAmt'
        },
        align: 'right',
      }
    ]
  },
  {
    title: '合计',
    dataIndex: 'totalAmt',
    slots: {
      customRender: 'totalAmt'
    },
    align: 'right',
  },
])
export const ordercolumns2 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '分单员部门',
    dataIndex: 'orgName',
    align: 'left',
  },
  {
    title: '分单员',
    dataIndex: 'salesman',
    align: 'left',
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'left',
  },
  {
    title: '到收',
    align: 'center',
    children: [
      {
        title: '正常',
        dataIndex: 'paidNormalAmt',
        slots: {
          customRender: 'paidNormalAmt'
        },
        align: 'right',
      },
      {
        title: '逾期',
        dataIndex: 'paidOverdueAmt',
        slots: {
          customRender: 'paidOverdueAmt'
        },
        align: 'right',
      }
    ]
  },
  {
    title: '记账/月结',
    align: 'center',
    children: [
      {
        title: '正常',
        dataIndex: 'monthNormalAmt',
        slots: {
          customRender: 'monthNormalAmt'
        },
        align: 'right',
      },
      {
        title: '逾期',
        dataIndex: 'monthOverdueAmt',
        slots: {
          customRender: 'monthOverdueAmt'
        },
        align: 'right',
      }
    ]
  },
  {
    title: '合计',
    dataIndex: 'totalAmt',
    slots: {
      customRender: 'totalAmt'
    },
    align: 'right',
  },
  {
    title: '上次报备时间',
    dataIndex: 'receiveReportTime',
    align: 'left',
  },
  {
    title: '报备次数',
    dataIndex: 'receivePlanNum',
    align: 'right',
  },
  {
    title: '回款描述',
    dataIndex: 'receiveContent',
    align: 'left',
    slots: {
      customRender: 'receiveContent'
    },
    width: 400
  },
])
export const orderiptData3 = ref([
  {
    type: "select",
    placeholder: "部门",
    value: null,
    prop: "orgId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "salesman",
    width: 200,
  },
 
])
export const ordercolumns3 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '部门',
    dataIndex: 'salesOrg',
    align: 'left',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'left',
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'left',
    slots: {
      customRender: 'customerName'
    },
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'left',
  },
  {
    title: '推送时间',
    dataIndex: 'pushTime',
    align: 'left',
  },
  {
    title: '保险成本',
    dataIndex: 'insuranceCost',
    align: 'right',
  },
  {
    title: '累计发车价',
    dataIndex: 'transportCost',
    align: 'right',
  },
  {
    title: '合同总金额',
    dataIndex: 'contractAmt',
    align: 'right',
  },
  {
    title: '税费',
    dataIndex: 'invoiceCost',
    align: 'right',
  },
  {
    title: '返款金额',
    dataIndex: 'refundFee',
    align: 'right',
  },
  {
    title: '其他费用',
    dataIndex: 'additionalFeeTotal',
    align: 'right',
  },
  {
    title: '毛利',
    dataIndex: 'grossProfit',
    align: 'right',
  },
  {
    title: '亏损原因',
    dataIndex: 'lossReason',
    align: 'left',
  },
])
