<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn">

    </SearchList>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle m-b1"><span class="fz-16">待开票金额(元)：&nbsp;</span><a class="fz-16 fz-cu">{{ totalAmt }}</a></div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="item => item.orderVehicleId" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #invoiceCarrier="{ record }">
          {{ record.invoiceCarrier }}
          <div>{{ record.taxItem }}{{ record.taxRate }}%</div>
        </template>
        <template #amt="{ record }">
         <a @click="lookDetails(record)"> {{ record.amt }}</a>
        </template>
      </a-table>
    </div>
    <a-modal v-model:visible="visible" :title="title" destroyOnClose width="80%" :bodyStyle="{padding: '10px',background:'#EEEEEE'}" footer="">
       <Details :remakItem="remakItem" :customerId="customerId"></Details>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import { orderInvoiceTotal } from '@/api/businessCenter/businessCenter'
import moment from 'moment'
import { transitionTimeYM, getTableBody, IfNull, compute } from '@/utils/util'
import Details from './details.vue'

const tableRef = ref(null)
const loading = ref(false)
const dataSource = ref([])
const data = ref([])
const pageSize = ref(50)
const totalAmt = ref('')
const title = ref('')
const visible = ref(false)
const customerId = ref('')
const remakItem = ref({})

const iptData = ref([
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "select",
    placeholder: "开票主体",
    value: null,
    prop: "invoiceCarrier",
    opt:[],
    width: 200,
  },
  {
    type: "select",
    placeholder: "税点",
    value: null,
    prop: "taxRate",
    opt:[],
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
    width:150
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '开票主体',
    dataIndex: 'invoiceCarrier',
    slots: {
      customRender: 'invoiceCarrier'
    },
    align: 'center',
  },
  {
    title: '开票金额',
    dataIndex: 'amt',
    slots: {
      customRender: 'amt'
    },
    align: 'center',
    width: 150
  },

])

const lookDetails = (record) => {
  remakItem.value = record
  customerId.value = record.customerId
  title.value = record.customerName + '-待开票车辆'
  visible.value = true
}
const searchAdd = (e) => {
  if(e.customerName){
    dataSource.value = data.value.filter(item => {
      return item.customerName == e.customerName
    })
  }else{
    dataSource.value = data.value
  }
  if(e.salesman){
    dataSource.value = dataSource.value.filter(item => {
      return item.salesman == e.salesman
    })
  }
  if(e.invoiceCarrier){
    dataSource.value = dataSource.value.filter(item => {
      return item.invoiceCarrier == e.invoiceCarrier
    })
  }
  if(e.taxRate){
    dataSource.value = dataSource.value.filter(item => {
      return item.taxRate == e.taxRate
    })
  }
    let list = dataSource.value.map(item => item.amt)
    totalAmt.value  =  list.length === 0 ? 0 :  compute('+',...list).result
 
}
const resetBtn = () => {
  pageSize.value = 50
  dataSource.value = data.value.slice(0,pageSize.value)
  let list = dataSource.value.map(item => item.amt)
  totalAmt.value  =  list.length === 0 ? 0 :  compute('+',...list).result
}
const getOrderRefundTotal = () => {
  loading.value= true
  orderInvoiceTotal({}).then( res => {
    if(res.code !== 10000) return
       console.log(res);
       data.value = res.data.list
       dataSource.value = res.data.list.slice(0,pageSize.value)
       totalAmt.value = res.data.totalAmt
         let list1 = data.value.map(item => {
         return {
           name: item.invoiceCarrier,
           id: item.invoiceCarrier
         }
       })
       iptData.value[2].opt =  uniqueBy(list1, item => item.id)
       let list2 = data.value.map(item => {
         return {
           name: item.taxRate,
           id:item.taxRate
         }
       })
       iptData.value[3].opt = uniqueBy(list2, item => item.id)
  }).finally(() => {
    loading.value = false
  })
}
const uniqueBy = (arr, key) => {
  const seen = new Set();
  return arr.filter((item) => {
    const k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
};
const theBottom = () => {
  if(dataSource.value.length === 0) return
  if(pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0,pageSize.value)

}
onMounted(() => {
  getOrderRefundTotal()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'),theBottom )
})
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>