
import { loadLanguageAsync } from '@/locale'
import { getCityList, getPCList, getOnlyCityList, getCityStatusList, getMonitorData,  orgBusinessOrgList} from '@/api/global'
import { labelList } from '@/api/transport/labelDeploy'
import { getLabelAll } from '@/api/crmManagement/follow'
import { getOffice } from '@/api/transport/truck'

import router from '@/router/index'
import { homeName } from '@/config/defaultSettings'
import { routeHasExist } from '@/utils/util'


const app = {

  state: {
    lang: '',
    tabs: [],
    cachePage: [],
    breadCrumbList: [],
    homeRoute: {},
    cityDict: [],
    cityTypeDict: [],
    monitorTypeLevel: [],
    PClist: [],
    OnlyCityList: [],
    orgTypeList: [],
    orgList: [],
    carBrand: [],
    storeCity: [],
    labelAll: [],
    labelList:[],
    orgLevelList:[],
    orgBusList:[]
  },

  mutations: {
    SET_LANG (state, lang) {
      state.lang = lang
      localStorage.setItem('app_language', lang)
    },
    TABS (state, route) {
      const tab = state.tabs[route.index]
      if (route.params) {
        tab.params = route.params
      }
      if (route.query) {
        tab.query = route.query
      }
      state.pageOpenedList.splice(route.index, 1, tab)
      localStorage.pageOpenedList = JSON.stringify(state.pageOpenedList)
    },
    SET_TABS (state) {
      state.tabs = localStorage.tabs ? JSON.parse(localStorage.tabs) : [homeName]
    },
    ADD_TAB (state, route) {
      if (route.meta && route.meta.keepAlive) {
        state.cachePage.push(route.name)
        // localStorage.cachePage = JSON.stringify(state.cachePage)
      }
      if (!routeHasExist(state.tabs, route)) {
        state.tabs.push(route)
        localStorage.tabs = JSON.stringify(state.tabs)
      }
    },
    CLOSE_TAB (state, name) {
      const tabIndex = state.tabs.findIndex(item => item.name === name)
      const closeTabs = state.tabs.splice(tabIndex, 1).map(item => item.name)
      const newCachePage = state.cachePage.filter(item => {
        return !closeTabs.includes(item)
      })
      state.cachePage = newCachePage
      localStorage.tabs = JSON.stringify(state.tabs)
    },
    CLOSE_LEFT_TAB (state, name) {
      const tabIndex = state.tabs.findIndex(item => item.name === name)
      const closeTabs = state.tabs.splice(1, tabIndex - 1).map(item => item.name)
      const newCachePage = state.cachePage.filter(item => {
        return !closeTabs.includes(item)
      })

      state.cachePage = newCachePage
      localStorage.tabs = JSON.stringify(state.tabs)
    },
    CLOSE_RIGHT_TAB (state, name) {
      const tabIndex = state.tabs.findIndex(item => item.name === name)
      const closeTabs = state.tabs.splice(tabIndex + 1).map(item => item.name)
      const newCachePage = state.cachePage.filter(item => {
        return !closeTabs.includes(item)
      })
      state.cachePage = newCachePage
      localStorage.tabs = JSON.stringify(state.tabs)
    },
    CLOSE_OTHER_TAB (state, name) {
      const tabIndex = state.tabs.findIndex(item => item.name === name)
      if (tabIndex === 0) {
        state.tabs.splice(1)
      } else {
        state.tabs.splice(tabIndex + 1)
        state.tabs.splice(1, tabIndex - 1)
      }
      const newCachePage = state.cachePage.filter(item => {
        return item !== name
      })
      state.cachePage = newCachePage
      localStorage.tabs = JSON.stringify(state.tabs)
    },
    CLOSE_ALL_TAB (state) {
      state.tabs.splice(1)
      state.cachePage.length = 0
      localStorage.tabs = JSON.stringify(state.tabs)
      router.push(state.tabs[0])
    },
    CLOSE_CACHE_PAGE (state, name) {
      state.cachePage.forEach((item, index) => {
        if (item === name) {
          state.cachePage.splice(index, 1)
        }
      })
    },
    setBreadCrumb (state, route) {
      const routeMetched = route.matched
      if (routeMetched.some(item => item.name === state.homeRoute.name)) state.breadCrumbList = [state.homeRoute]
    },
    setCityDict (state, citys) {
      state.cityDict = citys
    },
    setLabelList(state, labelList){
      state.labelList = labelList
    },
    setCityTypeDict (state, cityTypeDict) {
      state.cityTypeDict = cityTypeDict
    },
    setPClist (state, citys) {
      state.PClist = citys
    },
    setOnlyCityList (state, citys) {
      state.OnlyCityList = citys
    },
    ORG_TYPE_LIST (state, data) {
      state.orgTypeList = data
    },
    ORG_LEVEL_ONE(state, data){
      state.orgLevelList = data
    },
    ORG_LIST (state, data) {
      state.orgList = data
    },
    CAR_BRAND (state, data) {
      state.carBrand = data
    },
    SET_STORE_CITY (state, data) {
      state.storeCity = data
    },
    SET_MONITOR(state,list){
      state.monitorTypeLevel = list
    },
    SET_LABELALL(state,list){
      state.labelAll = list
    },
    setOrgBus(state,list){
      state.orgBusList = list
    }
  },
  actions: {
    getCitys ({ commit }) {
      getLabelAll().then(response => {
        if(response.data.CUSTOMER_VEHICLE_LEVEL){
          const createList = {
            '一级客户':'3个月内 150台以上',
            '二级客户':'3个月内 61-150台',
            '三级客户':'3个月内 10-60台',
            '四级客户':'3个月内 1-9台',
            '五级客户':'3个月内 0台'
          }
          response.data.CUSTOMER_VEHICLE_LEVEL.forEach(e => {
            e.label =e.label + '' +` (${createList[e.label]})` 
          })
        }
        if(response.data.INTENTION_LEVEL){
          response.data.INTENTION_LEVEL_DIS = response.data.INTENTION_LEVEL.slice(1, 5)
        }
        commit('SET_LABELALL', response.data)
      })
      getMonitorData().then(response => {
        commit('SET_MONITOR', response.data)
      })
      getCityList().then(response => {
        commit('setCityDict', response.data)
      })
      orgBusinessOrgList().then( res => {
        commit('setOrgBus', res.data)
      })
      getCityStatusList().then(response => {
        commit('setCityTypeDict', response.data)
      })
      getPCList().then(response => {
        commit('setPClist', response.data)
      })
      labelList({}).then( response => {
        commit('setLabelList', response.data)
      })
      getOnlyCityList().then(response => {
        response.data.forEach(e => {
          e.children = null
        })
        commit('setOnlyCityList', response.data)
      })
      getOffice().then(res => {
        commit('SET_STORE_CITY', res.data)
      })
    },
    setLang ({ commit }, lang) {
      return new Promise((resolve, reject) => {
        commit('SET_LANG', lang)
        loadLanguageAsync(lang).then(() => {
          resolve()
        }).catch((e) => {
          reject(e)
        })
      })
    }
  }
}
export default app
