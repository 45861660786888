<template>
<div>
  <div class=" tableBox">
      <div class="flex  ju-between m-b1">
        <div class="sys-screenTitle">共&nbsp;<a>{{ data.length }}</a>&nbsp;条数据</div>
      </div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #customerName="{ record }">
          <a  @click="lookDetails(record)">
          <span v-if="record.customerName">{{ record.customerName }}</span>
          <span v-else>无</span>
        </a>
        </template>
        <template #rate="{ record }">
         <span v-if="record.rate">{{ record.rate }}%</span>
        </template>
      </a-table>
    </div>
       <!-- 客户详情 -->
       <a-drawer title="详情" width="80%" placement="right"  v-model:visible="detailShow" destroyOnClose>
      <CustomerDetail v-model:customerId="customerId" :type="detailType" />
    </a-drawer>
</div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { conversionDealList } from '@/api/businessCenter/businessCenter'
import { transitionTimeYM, getTableBody, IfNull } from '@/utils/util'
import CustomerDetail from '@/views/crmManagement/tool/customerDetail/index.vue'
const props = defineProps({
  info: {
    type: Object,
    default: {}
  }
})
const dataSource = ref([])
const loading = ref(false)
const data = ref([])
const pageSize = ref(50)
const detailShow = ref(false)
const customerId = ref('')
const detailType = ref('')
const tableRef = ref(null)

const lookDetails = (record) => {
  if(record.customerClass){
    detailType.value = String(record.customerClass.value - 1) 
  }else{
    detailType.value = '2'
  }
  detailShow.value = true
  customerId.value = record.customerId
}
const getData = () => {
  conversionDealList({
    ...props.info
  }).then( res => {
    if(res.code !== 10000) return
    data.value = res.data
    dataSource.value = data.value.slice(0, pageSize.value)
  })
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
onMounted(() => {
  getData()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    slots:{
      customRender: 'customerName'
    },
    align: 'center',
  },
  {
    title: '领取时间',
    dataIndex: 'enterPrivateTime',
    align: 'center',
  },
  {
    title: '成交时间',
    dataIndex: 'dealTime',
    align: 'center',
  },
  {
    title: '跟进次数',
    dataIndex: 'followNum', 
    align: 'center',
  },
  {
    title: '成交毛利（交车）',
    dataIndex: 'totalGrossProfit',
    align: 'center',
  },
  {
    title: '成交台量',
    dataIndex: 'dealVehicleNum',
    align: 'center',
  },
])
</script>

<style lang="less" scoped>
.tableBox {
  padding: 0px 10px;
  background: #fff;
}
</style>