<template>
  <!-- <a-modal v-model:visible="windowShow" title="交车" @ok="handleOk" width="35%" @cancel="cancelClose"
    :bodyStyle="{ padding: '15px', paddingTop: 0 }" :confirm-loading="confirmLoading"> -->
    <div style="padding:10px;">
      <div>
        <div>已选车辆&emsp;{{ vehicleArr.length }}</div>
        <div class="vehicle-data" v-for="(item, index) in vehicleArr" :key="index">
          <div>
            <span style="margin-right:15px">{{ item.vinNo }}</span>
            <span>{{ item.startAddress }}——{{ item.endAddress }}</span>
          </div>
          <div><a @click="deleleData(index)">移除</a></div>
        </div>
      </div>
      <!--  -->
      <div>
        <div>收款对象<span style="color:#f00">*</span></div>
        <a-select ref="select" v-model:value="form.deliveryType" style="width: 120px">
          <a-select-option :value="1">承运商</a-select-option>
          <a-select-option :value="3">业务员</a-select-option>
        </a-select>
        <div v-if="vehicleArr.length > 0">
          <div>交车条</div>
          <a-upload v-model:file-list="imgFileList" accept="image/*" list-type="picture" :remove="handleRemove"
            :before-upload="beforeUpload">
            <a-button :disabled="imgFileList.length > 0">
              <CameraOutlined />
              上传交车条
            </a-button>
          </a-upload>
        </div>
        <div>交车说明</div>
        <a-textarea v-model:value="form.remark" />
      </div>
      <div style="margin-top:10px;">
        <a-button type="primary" :loading="confirmLoading" @click="handleOk">确定</a-button>
      </div>
    </div>
  <!-- </a-modal> -->
</template>

<script>
import { reactive, toRefs, onMounted, watch } from 'vue'
import { CameraOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'

export default {
  components: {
    CameraOutlined
  },
  props: {
    // windowShow: Boolean,
    vehicleArr: []
  },
  setup (props, context) {
    const state = reactive({
      windowShow: false,
      confirmLoading: false,
      vehicleArr: [],
      imgFileList: [],
      mirrorForm: {},
      form: {
        remark: '',
        deliveryType: null,
        deliveryOfficeOrg: 0,
      }
    })
    watch(() => props.vehicleArr, (newVal, oldVal) => {
      // state.windowShow = newVal
        state.vehicleArr = props.vehicleArr
      // } else state.confirmLoading = false
    })
    onMounted(() => {
      state.mirrorForm = JSON.stringify(state.form)
    })
    const handleOk = () => {
      if (state.form.deliveryType !== null) {
        if (state.form.deliveryType === 2) {
          if (state.form.deliveryOfficeOrg !== null) {
            const formData = new FormData()
            state.imgFileList.forEach(file => {
              formData.append('file', file.originFileObj)
            })
            setTimeout(() => {
              context.emit('statusShow', { ...state.form, formData: formData })
            }, 10)
          } else message.error('请选择是否到达办事处')
        } else {
          const formData = new FormData()
          state.imgFileList.forEach(file => {
            formData.append('file', file.originFileObj)
          })
          setTimeout(() => {
            if(state.imgFileList.length > 0){
              context.emit('statusShow', { ...state.form, formData: formData })
            }else message.error('请上传交车条')
          }, 10)
        }
      } else message.error('请选择收款对象')
    }
    const cancelClose = () => {
      state.windowShow = false
      state.form = JSON.parse(state.mirrorForm)
      state.imgFileList = []
      state.vehicleArr = []
      context.emit('statusShow', false)
    }
    const deleleData = index => {
      if (state.vehicleArr.length > 1) state.vehicleArr.splice(index, 1)
      else message.error('移除失败-已经是最后一个啦')
    }
    const beforeUpload = file => {
      state.imgFileList = [...state.imgFileList, file]
      return false
    }
    const handleRemove = file => {
      const index = state.imgFileList.indexOf(file)
      const newFileList = state.imgFileList.slice()
      newFileList.splice(index, 1)
      state.imgFileList = newFileList
    }
    return {
      ...toRefs(state),
      handleOk,
      deleleData,
      beforeUpload,
      handleRemove,
      cancelClose
    }
  }
}
</script>

<style lang="less" scoped>
.vehicle-data {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #6B778C;
  border: 1px solid #ccc;
  background: #F0F0F0;
  padding: 5px;
  margin-bottom: 10px;
}
</style>