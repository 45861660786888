<template>
  <div style="height: 780px;" class="flex">
    <div id="mapDiv" style="width:87%; height:100%"></div>
    <div style="width: 13%;" class="contenBox" v-if="dataLiat.length>0">
      <div>
        <div>概览</div>
        <div class="lineOne"></div>
        <div class="content m-t1">
          <div>最近位置上报时地址：
            <span class="text">{{ address }}</span>
          </div>
          <!-- <div class="m-t1">设备停留时间：
            <span class="text">55天</span>
          </div> -->
          <div class="lineTwo m-t2"></div>
          <div class="m-t1">
            <div class="flex">
              <div style="width: 60%;">
                <div>起点</div>
                <div class="text">{{ gpsShipment?.startStop }}</div>
              </div>
              <div>
                <div> 终点</div>
                <div class="text">{{ gpsShipment?.endStop }}</div>
              </div>
            </div>
          </div>
          <div class="lineTwo m-t2"></div>
          <div class="m-t1">
            <span class="text">不适用</span>
            <div class="flex m-t1">
              <div style="width: 60%;">
                <div>当前站</div>
                <div class="text">{{ gpsShipment?.currentStop }}</div>
              </div>
              <!-- <div>
                <div> 下一站</div>
                <div class="text">圣比迪堡</div>
              </div> -->
            </div>
          </div>
          <!-- <div class="lineTwo m-t2"></div>
          <div  class="m-t1">
            最后警告：<span class="text">无</span>
          </div> -->
        </div>
      </div>

      <!-- <div class="m-t1">
        <div>统计信息</div>
        <div class="lineOne  m-b1"></div>
        <div v-for="item in statistics" :key="item.id" class="content" style="margin-bottom: 7px;">
           {{ item.title }}
           <span  class="text">{{ item.value }}</span>
        </div> 
      </div>

      <div class="m-t1">
        <div>运单日志</div>
        <div class="lineOne m-b1"></div>
        <div v-for="(item,index) in waybill" :key="item.id" class="content" style="margin-bottom: 7px;">
            <div class="flex">
              <div class="sort flex al-center ju-center">{{ index + 1 }}</div>
              <div class="m-l1">
                <div>{{ item.time }}</div>
                <div>历时：{{item.value}}</div>
              </div>
            </div>
            <div style="margin-bottom: 5px;">{{ item.info }}</div>
            <div class="lineTwo m-t1"></div>
        </div> 
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { message } from 'ant-design-vue';
import { cloneDeep } from 'lodash-es';
import { onMounted, ref } from 'vue'
const props = defineProps({
  transPosition: {
    type: Array,
    default: []
  },
  gpsShipment: {
    type: Object,
    default: {}
  }
})
const dataLiat = ref([])
const address = ref('')

const transData = () => {
  let list = []
  props.transPosition.map(item => {
    if (item.lon) {
      list.push({
        location: item.location,
        recordTime: item.recordTime,
        lng: item.lon,
        lat: item.lat
      })
    }
  })
  return list
}
var map;
var zoom = 9;
const createMap = async () => {
  let list = transData()
  let start = cloneDeep(list[0])
  let end = cloneDeep(list[list.length - 1])
  if (list.length > 10 && list.length < 20) {
    let kok = list.slice(1, list.length - 2)
    let delNum = kok.length - 10
    let falg = Math.floor(kok.length / delNum)
    kok.forEach((item, index) => {
      if (index % falg == 0) {
        kok.splice(index, 1)
      }
    })
    list = [start, ...kok, end]
  }
  if (list.length >= 20 && list.length < 40) {
    let kok = list.slice(1, list.length - 2)
    let delNum = Math.ceil(kok.length / 10)
    let arr = []
    kok.forEach((item, index) => {
      if (index % delNum === 0) {
        arr.push(item)
      }
    })
    list = [start, ...arr, end]
  }
  if (list.length >= 40) {
    let kok = list.slice(1, list.length - 2)
    let delNum = Math.ceil(kok.length / 20)
    let arr = []
    kok.forEach((item, index) => {
      if (index % delNum === 0) {
        arr.push(item)
      }
    })
    list = [start, ...arr, end]
  }
  dataLiat.value = transData()
  if (list.length === 0) {
    message.info('暂无位置信息')
    return
  }
  address.value = list[0].location
  map = new T.Map('mapDiv');
  map.centerAndZoom(new T.LngLat(list[0].lng, list[0].lat), zoom);
  //创建对象
  var geocode = new T.Geocoder();

  map.setMapType(T.MapType.VECTOR);
  let points = [];
  list.forEach(item => {
    points.push(new T.LngLat(item.lng, item.lat))
    item.latLng = new T.LngLat(item.lng, item.lat)
  })
  //创建线对象
  var line = new T.Polyline(points, {
    weight: 3,
    color: '#0066CC',
    lineStyle: 'solid',
    opacity: 1
  })

  list.forEach((item, index) => {
    var marker = new T.Marker(item.latLng);// 创建标注
    marker.setZIndexOffset({ num: 1 })
    map.addOverLay(marker);
    var label = new T.Label({
      text: `<div>${list.length - index}：${item.recordTime}</div>`,
      position: item.latLng,
      offset: new T.Point(-9, -45)
    });
    map.addOverLay(label);
    var infoWin = new T.InfoWindow();
    marker.addEventListener("mouseover", function (e) {
      geocode.getLocation(e.lnglat, function searchResult (result) {
        let text = result.addressComponent.nation + result.addressComponent.address + result.addressComponent.address_position + getAddress(result.addressComponent.address_distance)
        infoWin.setContent(`<div>${item.location}</div>${text}`);
        marker.openInfoWindow(infoWin);
      })
    })
    marker.addEventListener("mouseout", function (e) {
      marker.closeInfoWindow(infoWin)
    })
  })

  function getAddress (item) {
    if (!item) return ''
    let num = Number(item) / 1000
    if (!Math.round(num)) return ''
    return Math.round(num) + 'km'
  }
  // 向地图上添加线
  map.addOverLay(line);


}
const waybill = ref([
  {
    title: '运单号：',
    value: '12',
    time: '2021-01-01',
    info: '已到目的地'
  },
  {
    title: '运单状态：',
    value: '10',
    time: '2021-01-01',
    info: '已到目的地'
  },

])
const statistics = ref([
  {
    title: '历时(天)/剩余：',
    value: '171天/10天'
  },
  {
    title: '告警数/上报数：',
    value: '171/10'
  },
  {
    title: '已运输里程(km)：',
    value: '17110'
  },
  {
    title: '离目的地距离(km)：',
    value: '17110'
  },
])
onMounted(() => {
  createMap()
})

</script>


<style lang="less" scoped>
.contenBox {
  padding: 10px;
  color: #333;
  font-size: 14px;
}

.lineOne {
  width: 100%;
  height: 2px;
  background: #a4a4a4;
}
.lineTwo {
  width: 100%;
  height: 1px;
  background: #a4a4a4;
}

.content {
  font-size: 12px;
}
.text {
  color: #666666;
}
.sort {
  font-size: 18px;
  width: 35px;
  height: 35px;
  border-radius: 8px;
  border: 1px solid #d4d4d4;
}
</style>