<template>
  <HxTable v-model:columns="columns" :reload="loadData">
    <template #search>

      <a-form layout="inline">
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.carrierLevel" placeholder="承运商等级">
            <a-select-option :value="1">一星</a-select-option>
            <a-select-option :value="2">二星</a-select-option>
            <a-select-option :value="3">三星</a-select-option>
            <a-select-option :value="4">四星</a-select-option>
            <a-select-option :value="5">五星</a-select-option>
            <a-select-option :value="0">零星</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.stateName" :options="auditArr" @change="auditChange" placeholder="审核状态" />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select allowClear v-model:value="searchForm.settlementMode" :options="$store.state.enumAll.CustomerSettlementEnum" placeholder="请选择结算方式" @change="settlementChange" />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.isSettled" :options="$store.state.enumAll.BooleanFlagEnum" placeholder="是否固化承运商" />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.isBlock" :options="$store.state.enumAll.BooleanFlagEnum" placeholder="是否拉黑" />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.carrierType" placeholder="承运商类型">
            <a-select-option :value="1">内部</a-select-option>
            <a-select-option :value="2">外部</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.periodType" placeholder="所属周期">
            <a-select-option :value="1">未审核</a-select-option>
            <a-select-option :value="2">新入期</a-select-option>
            <a-select-option :value="3">试用期</a-select-option>
            <a-select-option :value="4">合作期</a-select-option>
            <a-select-option :value="5">淘汰期</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="searchForm.carrierName" placeholder="承运商名称" />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="searchForm.charge" placeholder="负责人" />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="searchForm.chargeMobile" placeholder="负责人电话" />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="searchForm.carNo" placeholder="车牌号" />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="searchForm.levelScore" placeholder="承运商评分（大于等于）" />
        </a-form-item>
        <a-space>
          <a-button class="searchLoading-button" :loading="loading" type="primary" @click="onSearch" :disabled="loading">
            查询
          </a-button>
          <a-button @click="reset">
            重置
          </a-button>
        </a-space>
      </a-form>

    </template>
    <template #toolbarLeft>
      <a-space>
        <a-button @click="addWindow = true">
          新增
        </a-button>
        <a-button @click="openStaffWindow">
          员工列表
        </a-button>
      </a-space>
    </template>

    <a-table size="small" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #carrierName="{ record }">
        <div>
          <a @click="detailShow = true, carrierId = record.id, carrierData = record">{{ record.carrierName }}</a>
        </div>
      </template>
      <!-- <template #license="{record}">
        <div>
          <a-image :width="30" :src="record.license" />
        </div>
      </template> -->
      <!-- <template #idCard="{record}">
        <div>
          <a-image :width="30" :src="record.idCard" />
        </div>
      </template> -->
      <template #carrierLevel="{ record }">
        <div v-if="record.carrierLevel">
          <a-rate v-model:value="record.carrierLevel.value" :disabled="true" />
        </div>
      </template>
    </a-table>
  </HxTable>
  <a-modal :width="800" v-model:visible="addWindow" destroyOnClose footer="">
    <template #title>
      <div style="font-size:20px">
        新增承运商
      </div>
    </template>
    <p style="font-size:18px">基本信息</p>
    <a-form layout="vertical" ref="formRef" :model="addState" :rules="rules">
      <a-row :gutter="[24, 6]">
        <a-col :span="12">
          <a-form-item label="主体类型" :name="['carrierNatureType', 'value']">
            <a-select v-model:value="addState.carrierNatureType.value" :options="carrierNatureTypetEnum" placeholder="请选择主体类型" @change="onCarrierNatureType" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="承运商类型" :name="['carrierType', 'value']">
            <a-select v-model:value="addState.carrierType.value" :options="$store.state.enumAll.carrierType" @change="carrierChange" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <div style="display:flex;justify-content: space-between;">
            <div style="flex:1">
              <a-form-item label="承运商名称" name="name" v-if="addState.carrierNatureType.value === 1">
                <a-input v-model:value="addState.name" />
              </a-form-item>
              <a-form-item label="承运商名称" name="name" v-else>
                <a-select v-model:value="addState.name" placeholder="请输入至少三位承运商名称" :filter-option="false" :show-arrow="false" @search="entpSearch" @select="entpSelect" show-search>
                  <a-select-option v-for="item in enterprises" :key="item.creditCode" :value="item.name">
                    <span>{{ item.name }}</span>
                    <span style="float:right;">{{ item.operName }}</span>
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>
            <div v-show="addState.carrierType?.value === 1">
              <a-form-item label="承运商别名" name="alias">
                <a-input v-model:value="addState.alias" />
              </a-form-item>
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <a-form-item label="结算方式" :name="['settlementMode', 'value']">
            <a-select v-model:value="addState.settlementMode.value" :options="CustomerSettlementEnum" placeholder="请选择结算方式" @change="settlementModeChangeAdd" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="是否自有大板" :name="['isBigCar', 'value']">
            <a-select v-model:value="addState.isBigCar.value" :options="$store.state.enumAll.BooleanFlagEnum" @change="booleanChange" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="负责人姓名" name="charge">
            <a-input v-model:value="addState.charge" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="负责人电话" name="chargeMobile">
            <a-input v-model:value="addState.chargeMobile" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="业务类型" :name="['carrierBusinessType', 'value']">
            <a-select v-model:value="addState.carrierBusinessType.value" :options="$store.state.enumAll.businessType" @change="businessChange" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="营业执照照片" @click="flag = 1" name="licenseFileId">
            <a-upload v-model:file-list="licenseFile" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :before-upload="beforeUpload1" @change="handleChange(1)">
              <img v-if="licenseFileUrl" style="width:104px;height:104px" :src="licenseFileUrl" />
              <div v-else>
                <loading-outlined v-if="licenseLoading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
          </a-form-item>
        </a-col>
        <a-col :span="12" style="display: flex;justify-content: space-between;">
          <a-form-item label="法人身份证正面" @click="flag = 2" name="fileId">
            <a-upload v-model:file-list="idCardseFile" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :before-upload="beforeUpload2" @change="handleChange(2)">
              <img v-if="fileUrl" style="width:104px;height:104px" :src="fileUrl" />
              <div v-else>
                <loading-outlined v-if="idCardseLoading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
          </a-form-item>
          <a-form-item label="法人身份证反面" @click="flag = 3" name="idCardEnd">
            <a-upload v-model:file-list="idCardseFile" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :before-upload="beforeUpload3" @change="handleChange(3)">
              <img v-if="idCardEndUrl" style="width:104px;height:104px" :src="idCardEndUrl" />
              <div v-else>
                <loading-outlined v-if="idCardEndLoading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <div style="display: flex;">
            <div style="flex:1;margin-right:10px">
              <a-form-item label="运力属性" :name="['carrierCapacityType', 'value']">
                <a-select v-model:value="addState.carrierCapacityType.value" :options="$store.state.enumAll.Attribute" @change="attributeChange" />
              </a-form-item>
            </div>
            <div style="flex:1">
              <a-form-item label="是否为固化承运商" :name="['isSettled', 'value']">
                <a-select v-model:value="addState.isSettled.value" :options="$store.state.enumAll.BooleanFlagEnum" placeholder="请选择" />
              </a-form-item>
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <a-form-item label="承运方对接人" name="dockingPerson">
            <a-input v-model:value="addState.dockingPerson" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="承运方对接人电话" name="dockingMobile">
            <a-input v-model:value="addState.dockingMobile" />
          </a-form-item>
        </a-col>
        <!--        <a-col :span="12">-->
        <!--          <a-form-item label="标签">-->
        <!--            <a-select v-model:value="addState.tagId" :options="$store.state.enumAll.CarrierEnum" @change="tagChange" />-->
        <!--          </a-form-item>-->
        <!--        </a-col>-->
        <a-col :span="12">
          <a-form-item label="常用地址" name="addressArr">
            <a-cascader v-model:value="addState.addressArr" :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true" @change="addressChange" placeholder="请选择省市区" />
            <a-input v-model:value="addState.details" placeholder="请输入具体位置" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="备注">
            <a-input v-model:value="addState.remark" />
          </a-form-item>
        </a-col>
        <a-col :span="24" style="font-size:18px">财务信息</a-col>
        <a-col :span="12">
          <a-form-item label="银行账号" :name="account">
            <a-input v-model:value="addState.account" type="number" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="纳税人识别号" :name="taxIdentNum">
            <a-input v-model:value="addState.taxIdentNum" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="开户行" :name="bank">
            <a-input v-model:value="addState.bank" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="开户行地址" :name="bankAddress">
            <a-input v-model:value="addState.bankAddress" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="财务对接人" :name="financialPerson">
            <a-input v-model:value="addState.financialPerson" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="发票类型" :name="['invoiceType', 'value']">
            <a-select v-model:value="addState.invoiceType.value" :options="invoiceTypeArr" @change="invoiceChange" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div style="display:flex;justify-content: flex-end;margin-top:15px">
      <a-button size="large" style="margin-right:15px" @click="addWindow = false">取消</a-button>
      <a-button size="large" class="searchLoading-button" @click="addData">新增</a-button>
    </div>
  </a-modal>
  <a-modal v-model:visible="staffShow" title="所有员工列表" footer="">
    <div>
      <a-table size="small" :columns="staffColums" :row-key="record => record.id" :data-source="staffList" bordered :pagination="staffPagination" :loading="staffLoading" @change="staffTableChange">
      </a-table>
    </div>
  </a-modal>

  <a-drawer width="80%" placement="right" v-model:visible="detailShow" destroyOnClose @close="detailShow = false, loadData()">
    <CarrierDetail :carrierId="carrierId" :detail="carrierData" type="1" />
  </a-drawer>
</template>

<script>
import { onMounted, reactive, toRefs, ref } from 'vue'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import HxTable from '@/components/HxTable'
import CarrierDetail from '../toolDetail/index.vue'
import { mobileValidate, carrierName } from '@/utils/validate'
import { getPage, addCarrier, black } from '@/api/carrier/carrierList'
import { fileUploadPublic as fileUpload, getAllEmployeeList } from '@/api/global'
import { message } from 'ant-design-vue'
import { enterprise } from '@/api/system/setting'

export default {
  components: {
    HxTable,
    CarrierDetail,
    LoadingOutlined,
    PlusOutlined,
  },
  setup () {
    const formRef = ref()
    const store = useStore()
    const state = reactive({
      enterprises: [],
      flag: 0,
      carrierId: '',
      licenseFileUrl: null,
      fileUrl: null,
      idCardEndUrl: null,
      staffLoading: false,
      staffShow: false,
      loading: false,
      addWindow: false,
      detailShow: false,
      licenseLoading: false,
      idCardseLoading: false,
      idCardEndLoading: false,
      empty: {},
      carrierData: {},
      licenseFile: [],
      idCardseFile: [],
      idCardEndFile: [],
      listData: [],
      staffList: [],
      selectedRowKeys: [],
      CustomerSettlementEnum: [],
      searchForm: {
        carrierName: '',
        charge: '',
        chargeMobile: '',
        carrierType: null,
        stateName: ''
      },
      addState: {
        name: '',
        charge: '',
        alias: null,
        isSettled: { value: null },
        tagId: '',
        carrierNatureType: {
          label: '',
          value: ''
        },
        carrierType: {
          label: '',
          value: ''
        },
        isBigCar: {
          label: '',
          value: ''
        },
        chargeMobile: '',
        // licenseFileUrl: '',
        // fileUrl: '',
        // idCardEndUrl: '',
        idCardEnd: '',
        fileId: '',
        licenseFileId: '',
        addressArr: [],
        address: {},
        details: '',
        remark: '',
        settlementMode: {
          value: '',
          label: ''
        },
        carrierBusinessType: {
          value: '',
          label: ''
        },
        carrierCapacityType: {
          value: '',
          label: ''
        },
        account: '',
        bankAddress: '',
        bank: '',
        financialPerson: '',
        taxIdentNum: '',
        invoiceType: {
          label: '',
          value: ''
        }
      },
      invoiceTypeArr: [
        {
          label: '普通发票',
          value: 1
        },
        {
          label: '企业发票',
          value: 2
        }
      ],
      carrierNatureTypetEnum: [
        {
          label: '个体',
          value: 1
        },
        {
          label: '企业',
          value: 2
        }
      ],
      auditArr: [
        {
          label: '全部',
          value: ''
        },
        {
          label: '审核中',
          value: 0
        },
        {
          label: '已通过',
          value: 1
        },
        {
          label: '未通过',
          value: 2
        }
      ],
      rules: {
        licenseFileId: {
          required: true,
          message: '请上传营业执照照片',
          trigger: 'blur'
        },
        fileId: {
          required: true,
          message: '请上传法人身份证正面',
          trigger: 'blur'
        },
        idCardEnd: {
          required: true,
          message: '请上传法人身份证反面',
          trigger: 'blur'
        },
        alias: {
          required: true,
          message: '请填写承运商别名',
          trigger: 'blur'
        },
        name: {
          required: true,
          message: '请填写承运商名称',
          trigger: 'blur'
        },
        carrierType: {
          value: {
            required: true,
            message: '请填写承运商类型',
            trigger: 'blur',
            type: 'number'
          }
        },
        isBigCar: {
          value: {
            required: true,
            message: '请选择是否自有大板',
            trigger: 'blur',
            type: 'number'
          }
        },
        settlementMode: {
          value: {
            required: true,
            message: '请选择结算方式',
            trigger: 'blur',
            type: 'number'
          }
        },
        carrierNatureType: {
          value: {
            required: true,
            message: '请选择主体方式',
            trigger: 'blur',
            type: 'number'
          }
        },
        charge: {
          required: true,
          message: '请填写负责人姓名',
          trigger: 'blur'
        },
        chargeMobile: [{
          required: true,
          message: '请填写负责人电话',
          trigger: 'blur'
        }, {
          pattern: mobileValidate,
          message: '请填写正确的电话号码',
          trigger: 'blur'
        }],
        isSettled: {
          value: {
            required: true,
            message: '请选择是否固化承运商',
            trigger: 'blur',
            type: 'number'
          }
        },
        carrierBusinessType: {
          value: {
            required: true,
            message: '请选择业务类型',
            trigger: 'blur',
            type: 'number'
          }
        },
        carrierCapacityType: {
          value: {
            required: true,
            message: '请选择运力属性',
            trigger: 'blur',
            type: 'number'
          }
        },
        addressArr: {
          required: true,
          message: '请填写常用地址',
          trigger: 'blur',
          type: 'array'
        },
        dockingPerson: {
          required: true,
          message: '请填写业务对接人',
          trigger: 'blur'
        },
        dockingMobile: [{
          required: true,
          message: '请填写业务对接人电话',
          trigger: 'blur'
        }, {
          pattern: mobileValidate,
          message: '请填写正确的电话号码',
          trigger: 'blur'
        }],
        account: [{
          required: true,
          message: '请填写银行账户',
          trigger: 'blur'
        }],
        taxIdentNum: {
          required: true,
          message: '请填写纳税人识别号',
          trigger: 'blur'
        },
        bank: {
          required: true,
          message: '请填写开户行',
          trigger: 'blur'
        },
        bankAddress: {
          required: true,
          message: '请填写开户行地址',
          trigger: 'blur'
        },
        financialPerson: {
          required: true,
          message: '请填写财务对接人',
          trigger: 'blur'
        },
        invoiceType: {
          required: true,
          message: '请选择发票类型',
          trigger: 'blur',
          type: 'object'
        }
      },
      columns: [
        {
          title: '承运商名称',
          // width: '10%',
          align: 'center',
          dataIndex: 'carrierName',
          slots: {
            customRender: 'carrierName'
          }
        },
        {
          title: '承运商等级',
          align: 'center',
          dataIndex: 'carrierLevel',
          width: '8%',
          slots: {
            customRender: 'carrierLevel'
          }
        },
        {
          title: '评分',
          dataIndex: 'levelScore',
          align: 'center'
        },
        {
          title: '承运商地址',
          dataIndex: 'carrierAddress',
          align: 'center'
          // width: '10%',
        },
        {
          title: '承运商类型',
          dataIndex: 'carrierType.label',
          align: 'center'
        },
        {
          title: '负责人',
          dataIndex: 'charge',
          align: 'center',
          width: '5%'
        },
        {
          title: '负责人电话',
          dataIndex: 'chargeMobile',
          align: 'center',
          width: '7%'
        },
        // {
        //   title: '营业执照',
        //   dataIndex: 'license',
        //   align: 'center',
        //   width: '5%',
        //   slots: {
        //     customRender: 'license'
        //   }
        // },
        // {
        //   title: '法人身份证',
        //   dataIndex: 'idCard',
        //   align: 'center',
        //   width: '6%',
        //   slots: {
        //     customRender: 'idCard'
        //   }
        // },
        {
          title: '业务类型',
          dataIndex: 'carrierBusinessType.label',
          align: 'center',
          width: '5%'
        },
        {
          title: '运力属性',
          dataIndex: 'carrierCapacityType.label',
          align: 'center',
          width: '5%'
        },
        {
          title: '是否自有大板',
          dataIndex: 'isBigCar.label',
          align: 'center',
          width: '7%'
        },
        {
          title: '结账方式',
          dataIndex: 'settlementMode.label',
          align: 'center',
          width: '5%'
        },
        {
          title: '所属周期',
          dataIndex: 'periodType.label',
          align: 'center',
          width: '4%'
        },
        {
          title: '是否固化',
          dataIndex: 'isSettled.label',
          align: 'center',
          width: '4%'
        },
        {
          title: '是否拉黑',
          dataIndex: 'isBlock.label',
          align: 'center',
          width: '4%'
        },
        {
          title: '审核状态',
          dataIndex: 'status.label',
          align: 'center',
          width: '5%'
        }
        // {
        //   title: '标签',
        //   align: 'center',
        //   dataIndex: 'tagName',
        //   width: '5%'
        // }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      staffColums: [
        {
          title: '承运商名称',
          dataIndex: 'carrierName',
          align: 'center'
        },
        {
          title: '员工名称',
          dataIndex: 'empName',
          align: 'center'
        },
      ],
      staffPagination: {
        current: 1,
        pageSize: 10,
        showTotal: (total, range) => `共 ${total} 条`
      },
      timeOutId: null,
    })
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }
    // 搜索筛选查询
    const auditChange = (e, v) => {
      state.searchForm.status = v
      state.searchForm.stateName = v.label
    }
    const settlementChange = (e, v) => {
      state.settlementMode = v
    }
    const onSearch = () => {
      state.loading = true
      state.pagination.current = 1
      loadData()
    }

    const loadData = () => {
      state.loading = true
      getPage({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).catch(err => {
        console.log(err)
      })
        .finally(() => {
          state.loading = false
        })
    }

    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // 重置
    const reset = () => {
      state.searchForm = {}
      loadData()
    }
    onMounted(() => {
      loadData()
      state.empty = JSON.parse(JSON.stringify(state.addState))
      setTimeout(() => {
        state.CustomerSettlementEnum = store.state.enumAll.CustomerSettlementEnum
        if (state.CustomerSettlementEnum[0].value === '' || state.CustomerSettlementEnum[0].value === 0) state.CustomerSettlementEnum.splice(0, 1)
      }, 200)
    })
    // 新增承运商--事件
    const carrierChange = (e, v) => {
      state.addState.carrierType = v
      state.addState.alias = null
      if (v.value === 1) {
        state.rules.alias.required = true
      } else {
        state.rules.alias.required = false
      }
    }
    const settlementModeChangeAdd = (e, v) => {
      state.addState.settlementMode = v
    }
    const onCarrierNatureType = (e, v) => {
      if (e === 1) {

        state.rules.idCardEnd.required = true
        state.rules.fileId.required = true
        state.rules.licenseFileId.required = false
      } else {
        state.rules.licenseFileId.required = true
        state.rules.idCardEnd.required = false
        state.rules.fileId.required = false
      }
      state.addState.carrierNatureType = v
      state.addState.name = ''
    }
    const booleanChange = (e, v) => {
      state.addState.isBigCar = v
    }
    const businessChange = (e, v) => {
      state.addState.carrierBusinessType = v
    }
    const addressChange = (value, selectedOptions) => {
      state.addState.addressData = value
      state.addState.address.province = value[0]
      state.addState.address.city = value[1]
      state.addState.address.area = value[2]
    }
    const beforeUpload1 = file => {
      state.licenseFile[0] = file
      return false
    }
    const beforeUpload2 = file => {
      state.idCardseFile[0] = file
      return false
    }
    const beforeUpload3 = file => {
      state.idCardEndFile[0] = file
      return false
    }
    // 图片上传
    const handleChange = (type) => {
      state.flag = type
      switch (state.flag) {
        case 1:
          state.licenseLoading = true
          const formData = new FormData()
          formData.append('file', state.licenseFile[0])
          fileUpload(formData).then(res => {
            if (res.code === 10000) {
              if (res.data.url !== '' || res.data.url !== null || res.data.url !== undefined) {
                // state.addState.licenseFileUrl = res.data.url
                state.addState.licenseFileId = res.data.id
                state.licenseFileUrl = res.data.url
                state.licenseFile = []
              }
            }
          }).finally(() => {
            state.licenseLoading = false
          })
          break
        case 2:
          state.idCardseLoading = true
          const fileImg = new FormData()
          fileImg.append('file', state.idCardseFile[0])
          fileUpload(fileImg).then(res => {
            if (res.code === 10000) {
              if (res.data.url !== '' || res.data.url !== null || res.data.url !== undefined) {
                // state.addState.fileUrl = res.data.url
                state.addState.fileId = res.data.id
                state.fileUrl = res.data.url
                state.idCardseFile = []
              }
            }
          }).finally(() => {
            state.idCardseLoading = false
          })
          break
        case 3:
          state.idCardEndLoading = true
          const idEnd = new FormData()
          idEnd.append('file', state.idCardEndFile[0])
          fileUpload(idEnd).then(res => {
            if (res.code === 10000) {
              if (res.data.url !== '' || res.data.url !== null || res.data.url !== undefined) {
                // state.addState.idCardEndUrl = res.data.url
                state.addState.idCardEnd = res.data.id
                state.idCardEndUrl = res.data.url
                state.idCardEndFile = []
              }
            }
          }).finally(() => {
            state.idCardEndLoading = false
          })
          break
      }
    }
    const attributeChange = (e, v) => {
      state.addState.carrierCapacityType = v
    }
    const invoiceChange = (e, v) => {
      state.addState.invoiceType = v
    }
    const tagChange = (e, v) => {
      state.addState.tagName = v.label
    }
    const addData = () => {
      formRef.value.validate().then(() => {
        // if (state.addState.fileUrl === null) {
        //   state.addState.fileUrl = ''
        // }
        // if (state.addState.licenseFileUrl === null) {
        //   state.addState.licenseFileUrl = ''
        // }
        // if (state.addState.carrierNatureType.value === 1 && state.addState.fileUrl.length === 0) {
        //   message.error('必须上传法人图片')
        // } else if (state.addState.carrierNatureType.value === 2 && state.addState.licenseFileUrl.length === 0) {
        //   message.error('必须上传营业执照图片')
        // } else {
        //   onAdd()
        // }
        onAdd()
      })
    }
    const onAdd = () => {
      delete state.addState.addressData
      state.addState.address.details = state.addState.details
      state.addState.licenseFileUrl = null
      state.addState.fileUrl = null
      state.addState.idCardEndUrl = null
      addCarrier(state.addState)
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.addState = state.empty
            state.empty = JSON.parse(JSON.stringify(state.addState))
            state.licenseFileUrl = null
            state.fileUrl = null
            state.idCardEndUrl = null
            state.rules.idCardEnd.required = true
            state.rules.fileId.required = true
            state.rules.licenseFileId.required = true
            loadData()
            state.addWindow = false
          }
        }).catch(err => {
          console.log(err)
        })
        .finally(() => {

        })
    }
    const entpSelect = async (val, option) => {
      state.addState.certificateNumber = option.key
      state.enterprises = []
    }
    const entpSearch = async val => {
      if (val.length > 3) {
        const filterVal = val.replace(new RegExp('[a-z]', 'ig'), '').replace(new RegExp('[0-9]', 'g'), '')
        if (filterVal.length < 4) {
          return
        }
        if (state.timeOut !== null) {
          clearTimeout(state.timeOut)
        }
        state.timeOut = setTimeout(() => {
          enterprise({ name: val }).then((res) => {
            if (res.code === 10000) {
              state.enterprises = res.data
            } else {
              state.enterprises = []
            }
          })
        }, 500)
      } else {
        state.enterprises = []
      }
    }
    const openStaffWindow = () => {
      state.staffShow = true
      state.staffLoading = true
      getAllEmployeeList({
        current: state.staffPagination.current,
        size: 10
      }).then(res => {
        if (res.code === 10000) {
          state.staffList = res.data
        }
      }).finally(() => { state.staffLoading = false })
    }
    const staffTableChange = (page) => {
      state.staffPagination.current = page.current
    }
    return {
      ...toRefs(state),
      reset,
      formRef,
      addData,
      onSearch,
      loadData,
      tagChange,
      auditChange,
      beforeUpload1,
      beforeUpload2,
      beforeUpload3,
      handleChange,
      carrierChange,
      booleanChange,
      addressChange,
      invoiceChange,
      onSelectChange,
      businessChange,
      attributeChange,
      settlementChange,
      handleTableChange,
      staffTableChange,
      openStaffWindow,
      settlementModeChangeAdd,
      onAdd,
      onCarrierNatureType,
      entpSelect,
      entpSearch
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../toolDetail/common.less';
</style>
