<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn">
      <template #customFooter>
        <a-date-picker mode="year" :allowClear="false" :open='yearShowOne' placeholder="选择年度" @openChange="openChangeOne" @panelChange="panelChangeOne" v-model:value="year" format='YYYY' />
        <span class="m-l1"></span>
        <a-select show-search allowClear v-model:value="orgId" style="width:200px" placeholder="部门">
          <a-select-option v-for="opt in $store.state.app.orgBusList" :key="opt.id" :value="opt.id">{{ opt.name }}</a-select-option>
        </a-select>
      </template>
      <template #tail>
        <a-button  type="primary" @click="exportAllExcel">导出全部</a-button>
      </template>
    </SearchList>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle">共有&nbsp;<a>{{ data.length }}</a>&nbsp;条数据</div>
    
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #add="{ record }">
          <a v-if="record.empId" @click="lookDetails(record)">查看明细</a>
        </template>
      </a-table>
    </div>

  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import moment from 'moment'
import { transitionTimeYYY, transitionTimeYM, getTableBody, IfNull } from '@/utils/util'
import { finalAccountsStatistic } from '@/api/financialCenter/financialCenter'
import * as XLSX from 'xlsx';

const loading = ref(false)
const dataSource = ref([])
const data = ref([])
const pageSize = ref(50)
const tableRef = ref(null)
const searchMsg = ref({})
const year = ref(moment(new Date()))
const yearShowOne = ref(false)
const orgId = ref(null)

const openChangeOne = (status) => {
  if (status) {
    yearShowOne.value = true
  }
}
const panelChangeOne = (value) => {
  year.value = value
  yearShowOne.value = false
}

const searchAdd = (e) => {
  searchMsg.value.year = transitionTimeYYY(year.value)
  searchMsg.value.orgId = orgId.value
  getProfitList()
}
const resetBtn = () => {
  year.value = moment(new Date())
  orgId.value = null
  searchMsg.value = {
    year: transitionTimeYYY(new Date().toLocaleDateString()),
  }
  getProfitList()
}

const getProfitList = () => {
  loading.value = true
  finalAccountsStatistic({
    ...searchMsg.value,
  }).then(res => {
    if (res.code !== 10000) return
    if (res.data) {
      data.value = res.data
      dataSource.value = data.value.slice(0, pageSize.value)
    } else {
      dataSource.value = []
    }
  }).finally(() => {
    loading.value = false
  })
}

const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
onMounted(() => {
  searchMsg.value = {
    year: transitionTimeYYY(new Date().toLocaleDateString()),
  }
  getProfitList()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})
const exportAllExcel = () => {
  // 获取用户列表
  let tableData = [
    ['序号', '科目', '年度指标', '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']//导出表头
  ] // 表格表头
  data.value.forEach((item, index) => {
    let rowData = []
    //导出内容的字段
    rowData = [
      index+1,
      item.subjectName,
      item.yearTotal,
      item.january,
      item.february,
      item.march,
      item.april,
      item.may,
      item.june,
      item.july,
      item.august,
      item.september,
      item.october,
      item.november,
      item.december
    ]
    tableData.push(rowData)
  })

  let workSheet = XLSX.utils.aoa_to_sheet(tableData);
  let bookNew = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(bookNew, workSheet, '') // 工作簿名称
  let name = '决算统计' + '.xlsx'
  XLSX.writeFile(bookNew, name) // 保存的文件名
}
const iptData = ref([
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '科目',
    dataIndex: 'subjectName',
    align: 'left',
  },
  {
    title: '年度指标',
    dataIndex: 'yearTotal',
    align: 'right',
  },
  {
    title: '1月',
    dataIndex: 'january',
    align: 'right',
  },
  {
    title: '2月',
    dataIndex: 'february',
    align: 'right',
  },
  {
    title: '3月',
    dataIndex: 'march',
    align: 'right',
  },
  {
    title: '4月',
    dataIndex: 'april',
    align: 'right',
  },
  {
    title: '5月',
    dataIndex: 'may',
    align: 'right',
  },
  {
    title: '6月',
    dataIndex: 'june',
    align: 'right',
  },
  {
    title: '7月',
    dataIndex: 'july',
    align: 'right',
  },
  {
    title: '8月',
    dataIndex: 'august',
    align: 'right',
  },
  {
    title: '9月',
    dataIndex: 'september',
    align: 'right',
  },
  {
    title: '10月',
    dataIndex: 'october',
    align: 'right',
  },
  {
    title: '11月',
    dataIndex: 'november',
    align: 'right',
  },
  {
    title: '12月',
    dataIndex: 'december',
    align: 'right',
  },
])
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>