<template>
  <a-page-header class="ant-page-header" title="账单查询" :ghost="false">
    <div class="ant-page-header-search">
      <a-input-group class="ant-page-header-search-input" compact>
        <a-select v-model:value="reconciliationAdd.type" style="width: 200px" size="large" @change="typeSelect">
          <a-select-option :value="1">客户</a-select-option>
          <a-select-option :value="2">承运商</a-select-option>
        </a-select>
        <a-select v-model:value="reconciliationAdd.customerId" placeholder="请输入" style="width: 100%" :filter-option="false" :show-arrow="false" size="large" @change="carrierSelect" @search="carrierAutoComplete" show-search>
          <a-select-option v-for="item in carriers" :key="item.id">
            <span>{{ item.name }}-{{item.mobile}}</span>
            <span style="float:right;">{{ item.taxpayerIdentificationNum }}</span>
          </a-select-option>
        </a-select>
        <a-button type="primary" size="large" @click="onSearch">搜索</a-button>
        <a-button size="large" @click="addReconciliation" danger>保存账单</a-button>
      </a-input-group>
    </div>
  </a-page-header>

  <a-card title="订单明细" :style="{ marginTop: '24px' }" :bodyStyle="{padding:0}">
    <a-tag color="pink" class="select-table-info">
      <template #icon>
        <InfoCircleFilled />
      </template>
      已选择 <strong>{{selectedOrderRowKeys.length}}</strong> 项
      订单金额总计 <strong>{{orderAmt}}</strong> 元
      <a @click="selectedOrderRowKeys = []">清空选择</a>
    </a-tag>
    <div>
      <a-space>
        <a-select v-model:value="orderQuery.officeOrgId" placeholder="办事处" allowClear style="width: 200px">
          <a-select-option v-for="item in $store.state.app.orgList" :key="item.id">{{item.name}}</a-select-option>
        </a-select>
        <a-input v-model:value="orderQuery.orderId" placeholder="合同号"></a-input>
        <a-input v-model:value="orderQuery.vinNo" placeholder="车架号"></a-input>
        <a-input v-model:value="orderQuery.settlementMode" placeholder="挂账类型"></a-input>
        <a-input v-model:value="orderQuery.salesman" placeholder="业务员"></a-input>
        <a-input v-model:value="orderQuery.customerName" placeholder="客户名"></a-input>
        <a-range-picker v-model:value="orderQuery.date" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" @change="orderSearchData" />
        <a-button type="primary" @click="orderSearchData">查询</a-button>
        <!--        <a-button type="primary" @click="orderExportExcel">导出Excel</a-button>-->
      </a-space>
    </div>
    <a-table ref="tableRef1" :row-selection="{ selectedRowKeys: selectedOrderRowKeys, onChange: selectOrderChange }" :pagination="false" :dataSource="orderList" :scroll="{y:500}" :rowKey="record => record.id" size="small" bordered>
      <a-table-column title="合同编号" data-index="orderId" />
      <a-table-column title="结算类型" data-index="settlementMode.label" />
      <a-table-column title="挂账类型" data-index="creditType.label" />
      <a-table-column title="客户名" data-index="customerName" />
      <a-table-column title="业务员" data-index="salesman" />
      <a-table-column title="起运地" data-index="startAddress" />
      <a-table-column title="目的地" data-index="endAddress" />
      <a-table-column title="下单时间" data-index="orderTime" />
      <a-table-column title="起运时间" data-index="startTime" />
      <a-table-column title="结算金额" data-index="settlementMode.nowSettledAm">
        <template #default="{record}">
          <div>
            <span v-show="record.editStatus"> {{record.settableAmt}}</span>
            <a-input v-show="!record.editStatus" v-model:value="record.settableAmt" style="width:85px;" /><br />
            <a @click="editBtn(record)" v-if="selectedOrderRowKeys.indexOf(record.id) > -1">{{record.editStatus ? '编辑' : '确认'}}</a>
          </div>
        </template>
      </a-table-column>
      <a-table-column title="小车信息" data-index="vehicleList" :width="250">
        <template #default="{ record }">
          <div v-for="(item,index) in record.vehicleList" :key="index">
            <a-tag color="#108ee9" style="cursor: pointer;">车架号：{{item.vinNo}} 金额：{{item.transportFee}}元</a-tag><br />
          </div>
        </template>
      </a-table-column>
      <a-table-column title="到收金额" data-index="paymentArrival" />
      <a-table-column title="结算方式" data-index="orderSettlement.label" />
      <a-table-column title="结算状态" data-index="settlementStatus.label" />
    </a-table>
  </a-card>
  <a-card title="运单明细" :style="{ marginTop: '24px' }" v-if="reconciliationAdd.type === 2" :bodyStyle="{padding:0}">
    <a-tag color="pink" class="select-table-info">
      <template #icon>
        <InfoCircleFilled />
      </template>
      已选择 <strong>{{selectedTransRowKeys.length}}</strong> 项
      订单金额总计 <strong>{{transAmt}}</strong> 元
      <a @click="selectedTransRowKeys = []">清空选择</a>
    </a-tag>
    <div>
      <a-space>
        <a-input v-model:value="transportreconciliationAdd.startAddress" placeholder="起点"></a-input>
        <a-input v-model:value="transportreconciliationAdd.endAddress" placeholder="终点"></a-input>
        <a-select v-model:value="transportreconciliationAdd.truckType" placeholder="板车类型" style="width: 100px">
          <a-select-option :value="0">全部</a-select-option>
          <a-select-option :value="1">大板车</a-select-option>
          <a-select-option :value="2">小板车</a-select-option>
          <a-select-option :value="4">地跑</a-select-option>
        </a-select>
        <a-input v-model:value="transportreconciliationAdd.orgName" placeholder="派车部门"></a-input>
        <a-input v-model:value="transportreconciliationAdd.operator" placeholder="派车人"></a-input>
        <a-input v-model:value="transportreconciliationAdd.vinNo" placeholder="小车车牌"></a-input>
        <a-input v-model:value="transportreconciliationAdd.carNo" placeholder="大车车牌"></a-input>
        <a-range-picker v-model:value="transportreconciliationAdd.date" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" @change="transportSearchData" />
        <a-button type="primary" @click="transportSearchData">查询</a-button>
        <!--        <a-button @click="transportExportExcel">导出Excel</a-button>-->
      </a-space>
    </div>
    <a-table ref="tableRef2" :row-selection="{ selectedRowKeys: selectedTransRowKeys, onChange: selectTransChange  }" :pagination="false" :dataSource="transportList" :scroll="{y:600}" :rowKey="record => record.id" size="small" bordered>
      <a-table-column title="运单编号" data-index="transId" />
      <a-table-column title="结算类型" data-index="settlementMode.label" width="5%" />
      <a-table-column title="车牌号/司机" data-index="transName" />
      <a-table-column title="承运商" data-index="carrierName" />
      <a-table-column title="线路" data-index="lineName" />
      <a-table-column title="发车时间" data-index="startTime" />
      <a-table-column title="类型" data-index="truckType.label" />
      <a-table-column title="派车人" data-index="loader" />
      <a-table-column title="派车部门" data-index="orgName" />
      <a-table-column title="到达时间" data-index="endTime" />
      <a-table-column title="总结算金额" data-index="settableAmt" />
      <a-table-column title="小车信息" data-index="vehicleList" :width="250">
        <template #default="{ record }">
          <div v-for="(item,index) in record.vehicleList" :key="index">
            <a-tag color="#108ee9" style="cursor: pointer;">车架号：{{item.vinNo}} 金额：{{item.transportFee}}元</a-tag><br />
          </div>
        </template>
      </a-table-column>
      <a-table-column :width="100" title="油卡结算金额" data-index="settableOilAmt" />
      <a-table-column :width="100" title="转账结算金额" data-index="settableCardAmt" />
      <a-table-column title="拆分" data-index="id">
        <template #default="{ record }">
          <a-popconfirm title="确定拆分?" @confirm="splitTrans(record.id)">
            <a>拆分</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a @click="editMoney(record)">修改金额</a>
        </template>
      </a-table-column>
    </a-table>
  </a-card>
  <a-modal :visible="visible" :footer="null" @cancel="handleCancel">
    <a-row type="flex">
      <a-col flex="100px">对账回执</a-col>
      <a-col flex="auto">
        <a-upload v-model::file-list="fileList" :remove="handleRemove" list-type="picture" :before-upload="beforeUpload">
          <a-button>
            上传照片
          </a-button>
        </a-upload>
      </a-col>
    </a-row>
    <a-row justify="end">
      <a-button type="primary" @click="choicePush">提交</a-button>
    </a-row>
  </a-modal>
  <a-modal :visible="editMoneyShow" title="修改金额" @ok="confirmEdit" :confirmLoading="confirmLoading" @cancel="editMoneyShow=false" destroyOnClose>
    <span>总金额：{{detailData.settableAmt}}<span style="color:#f00">（注：油卡与转账金额总和必须等于该数值）</span></span>
    <a-form-item label="转账金额"><a-input v-model:value="detailData.settableCardAmt" type="number" :min="0" /></a-form-item>
    <a-form-item label="油卡金额"><a-input v-model:value="detailData.settableOilAmt" type="number" :min="0" /></a-form-item>
    <a-form-item label="备注内容"><a-textarea v-model:value="remark" :rows="4" /></a-form-item>
  </a-modal>
</template>
<script>
import { fuzzyAll } from '@/api/crm/carrer/carrier'
import { billOrder, orderReportExport, transReportExport, billCarrierTrans, reconciliationAdd, splitTransBill } from '@/api/trans/finance/bill'
import { reactive, ref, toRefs, onMounted } from 'vue'
import { transEdit } from '@/api/trans/ops/edit'
import { message } from 'ant-design-vue'
import { fileUpload as upload } from '@/api/marketing/modList'
export default {
  setup (props, context) {
    const orderListRef = ref()
    const transportListRef = ref()
    const paymentRef = ref()
    const state = reactive({
      tableRef1: null,
      tableRef2: null,
      pageSize1: 50,
      pageSize2: 50,
      remark: '',
      detailData: {},
      fileList: [],
      confirmLoading: false,
      visible: false,
      editMoneyShow: false,
      orgs: [],
      selectedOrderRowKeys: [],
      selectedTransRowKeys: [],
      orderreconciliationAdd: { date: [] },
      transportreconciliationAdd: { date: [] },
      carriers: [],
      total: {},
      orderQuery: {
        date: []
      },
      orderList: [],
      orderListStorage: [],
      transportList: [],
      transportListStorage: [],
      timeOut: null,
      orderAmt: 0,
      transAmt: 0,
      reconciliationAdd: {
        customerId: '',
        customerName: '',
        type: 1,
        amt: 0,
        orderSettlementIds: [],
        transSettlementIds: [],
        fileId: '',
        fileUrl: '',
        isForeign: 0
      }
    })
    // 新增账单结算金额编辑
    const editBtn = (record) => {
      if (Number(record.settableAmt) > 0) {
        if (Number(record.settableAmt) > Number(record.applyAmt)) {
          message.error('数额高于最' + Number(record.applyAmt) + '大值')
          return
        }
        if (!record.editStatus) {
          state.reconciliationAdd.orderSettlementIds.forEach(item => {
            if (item.id === record.id) {
              state.orderAmt = state.orderAmt - Number(item.applyAmt) + Number(record.settableAmt)
              item.applyAmt = record.settableAmt
            }
          })
        }
        record.editStatus = !record.editStatus
      } else { message.error('数额不能小于0') }
    }
    const carrierAutoComplete = val => {
      if (val.length > 0) {
        if (state.timeOut !== null) {
          clearTimeout(state.timeOut)
        }
        state.timeOut = setTimeout(() => {
          fuzzyAll({ name: val, type: state.reconciliationAdd.type }).then(res => {
            if (res.code === 10000) { state.carriers = res.data } else state.carriers = []
          })
        }, 1000)
      } else {
        state.carriers = []
      }
    }

    const onSearch = () => {
      if (state.reconciliationAdd.customerId) {
        orderSearchData()
        if (state.reconciliationAdd.type === 2) {
          transportSearchData()
        }
      }
    }

    const loadOrderPage = () => {

      billOrder(state.reconciliationAdd.customerId, {
        ...state.orderQuery,
        billStatus: 0,
        type: state.reconciliationAdd.type,
        startTime: state.orderQuery.date[0],
        endTime: state.orderQuery.date[1]
      }).then(res => {
        if (res.code === 10000) {
          state.pageSize1 = 50
          state.orderListStorage = res.data
          state.orderList = res.data.slice(0, state.pageSize1)
          if (state.orderList.length > 0) {
            state.orderList.forEach(o => {
              o.editStatus = true
              o.applyAmt = o.settableAmt
            })
          }
        } else {
          state.orderList = []
        }
      })
    }

    const loadTransportPage = () => {
      billCarrierTrans(state.reconciliationAdd.customerId, {
        ...state.transportreconciliationAdd,
        billStatus: 0,
        type: state.reconciliationAdd.type,
        startTime: state.transportreconciliationAdd.date[0],
        endTime: state.transportreconciliationAdd.date[1]
      }).then(res => {
        if (res.code === 10000) {
          state.pageSize2 = 50
          state.transportListStorage = res.data
          state.transportList = res.data.slice(0, state.pageSize2)
        } else {
          state.transportList = []
        }
      })
    }

    const orderSearchData = () => {
      state.orderAmt = 0
      state.reconciliationAdd.orderSettlementIds = []
      state.selectedOrderRowKeys = []
      loadOrderPage()
    }

    const transportSearchData = () => {
      state.transAmt = 0
      state.reconciliationAdd.transSettlementIds = []
      state.selectedTransRowKeys = []
      loadTransportPage()
    }

    const orderExportExcel = () => {
      orderReportExport({
        startTime: state.orderreconciliationAdd.date[0],
        endTime: state.orderreconciliationAdd.date[1]
      }, '财务-订单对账明细').then(res => {
      })
    }

    const transportExportExcel = () => {
      transReportExport({
        startTime: state.transportreconciliationAdd.date[0],
        endTime: state.transportreconciliationAdd.date[1]
      }, '财务-运单对账明细').then(res => {
      })
    }

    const selectOrderChange = (selectedRowKeys, selectedRows) => {
      if (selectedRowKeys.length === state.orderList.length) {
        if (selectedRowKeys.length < state.orderListStorage.length) {
          message.warn(`已选择${selectedRowKeys.length}条数据，共${state.orderListStorage.length}条，如需选择更多，请把数据下拉到底加载更多`, 3)
        }
      }
      state.orderAmt = 0
      state.reconciliationAdd.orderSettlementIds = []
      state.selectedOrderRowKeys = selectedRowKeys
      selectedRows.forEach(item => {
        if (Number(item.settableAmt) > Number(item.applyAmt)) {
          item.settableAmt = item.applyAmt
        }
        state.orderAmt += Number(item.settableAmt)
      })
      state.reconciliationAdd.orderSettlementIds = selectedRows.map(item => { return { id: item.id, applyAmt: item.settableAmt } })
    }
    const selectTransChange = (selectedRowKeys, selectedRows) => {
      if (selectedRowKeys.length === state.transportList.length) {
        if (selectedRowKeys.length < state.transportListStorage.length) {
          message.warn(`已选择${selectedRowKeys.length}条数据，共${state.transportListStorage.length}条，如需选择更多，请把数据下拉到底加载更多`, 3)
        }
      }
      state.transAmt = 0
      state.reconciliationAdd.transSettlementIds = []
      state.selectedTransRowKeys = selectedRowKeys
      selectedRows.forEach(item => {
        state.transAmt += Number(item.settableAmt)
      })
      state.reconciliationAdd.transSettlementIds = selectedRows.map(item => { return { id: item.id } })
    }
    const addReconciliation = () => {
      if (state.orderList.length > 0 || state.transportList.length > 0) {
        // rCAdd()
        context.emit('onSearch')
        state.visible = true
      } else {
        message.error('请选择需要结算的明细')
      }
    }
    const carrierSelect = value => {
      state.carriers.forEach(item => {
        if (item.id === value) {
          state.reconciliationAdd.customerName = item.name
        }
      })
    }
    const rCAdd = () => {
      state.reconciliationAdd.amt = state.orderAmt - state.transAmt
      reconciliationAdd({
        ...state.reconciliationAdd
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          onSearch()
        } else {
          message.error(res.msg)
        }
      })
    }
    const typeSelect = value => {
      state.reconciliationAdd.customerId = ''
      state.reconciliationAdd.customerName = ''
      state.orderList = []
      state.transportList = []
      if (value === 2) {
        setTimeout(() => {
          const tableBody2 = state.tableRef2.$el.querySelector('.ant-table-body');
          addEvent(tableBody2, 'scroll', (e) => {
            const { scrollTop, clientHeight, scrollHeight } = e.target;
            if (scrollTop + clientHeight >= scrollHeight - 100) {
              if (state.transportList.length === 0) return
              if (state.pageSize2 >= state.transportListStorage.length) return
              state.pageSize2 = state.pageSize2 + 50
              state.transportList = state.transportListStorage.slice(0, state.pageSize2)
            }
          })
        }, 500)
      }
    }
    function addEvent (element, eventType, callback) {
      if (element.addEventListener) {
        element.addEventListener(eventType, callback, false);
      } else if (element.attachEvent) {  // 兼容IE8及以下
        element.attachEvent('on' + eventType, callback);
      } else {
        element['on' + eventType] = callback;  // 兼容IE使用旧式方法
      }
    }
    onMounted(() => {
      onSearch()
      setTimeout(() => {
        const tableBody1 = state.tableRef1.$el.querySelector('.ant-table-body');
        addEvent(tableBody1, 'scroll', (e) => {
          const { scrollTop, clientHeight, scrollHeight } = e.target;
          if (scrollTop + clientHeight >= scrollHeight - 100) {
            if (state.orderList.length === 0) return
            if (state.pageSize1 >= state.orderListStorage.length) return
            state.pageSize1 = state.pageSize1 + 50
            state.orderList = state.orderListStorage.slice(0, state.pageSize1)
          }
        })
      }, 500)
      // orgList({ orgType: 1 }).then(res => {
      //   if (res.code === 10000) {
      //     state.orgs = res.data
      //   }
      // })
    })
    const handleCancel = () => {
      state.visible = false
    }
    const handleRemove = file => {
      state.fileList.length = 0
      const index = state.fileList.indexOf(file)
      const newfileList = state.fileList.slice()
      newfileList.splice(index, 1)
      state.fileList = newfileList
    }
    const beforeUpload = file => {
      state.fileList.length = 0
      state.fileList = []
      state.fileList = [file]
      return false
    }
    const choicePush = () => {
      if (state.fileList.length > 0) {
        const formData = new FormData()
        formData.append('file', state.fileList[0])
        upload(formData).then(res => {
          if (res.code === 10000) {
            state.reconciliationAdd.fileId = res.data.id
            rCAdd()
            handleCancel()
          }
        })
      } else {
        rCAdd()
        handleCancel()
      }
    }

    const splitTrans = (ftsId) => {
      splitTransBill(ftsId).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          onSearch()
        } else {
          message.error(res.msg)
        }
      })
    }
    const editMoney = record => {
      state.detailData = record
      state.editMoneyShow = true
    }
    const confirmEdit = () => {
      state.confirmLoading = true
      transEdit({
        params: {
          id: state.detailData.id,
          oilAmt: state.detailData.settableOilAmt,
          cardAmt: state.detailData.settableCardAmt
        },
        type: {
          value: 1
        },
        reason: state.remark,
        subType: 103
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.editMoneyShow = false
          state.detailData = {}
          state.remark = ''
        }
      }).finally(() => { state.confirmLoading = false })
    }

    return {
      ...toRefs(state),
      rCAdd,
      editMoney,
      confirmEdit,
      handleRemove,
      beforeUpload,
      choicePush,
      orderListRef,
      transportListRef,
      handleCancel,
      paymentRef,
      carrierAutoComplete,
      onSearch,
      loadOrderPage,
      loadTransportPage,
      orderSearchData,
      transportSearchData,
      orderExportExcel,
      transportExportExcel,
      selectOrderChange,
      selectTransChange,
      addReconciliation,
      carrierSelect,
      typeSelect,
      editBtn,
      splitTrans
    }
  }
}
</script>
<style lang="less" scoped>
@import 'index.less';
</style>
