<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn">
    </SearchList>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle flex al-center">
        <div class="m-r1">本月开票额度(元)：<span>{{total.monthQuota}}</span></div>
        <div class="m-r1">本月已用额度(元)：<span>{{total.usedQuota}}</span></div>
        <div class="m-r1">申请中(元)：<span>{{total.processAmt}}</span></div>
        <div>红冲金额(元)：<span>{{total.invoiceRedAmt}}</span></div>
      </div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #taxRate="{ record }">
          {{ record.taxItem }}
          {{ record.taxRate }}%
        </template>
        <template #usedQuota="{ record }">
          <a  @click="lookDetails(record,2,'usedQuota')">{{ record.usedQuota }}</a>
        </template>
        <template #invoiceAmt="{ record }">
          <a  @click="lookDetails(record,1,'invoiceAmt')">{{ record.invoiceAmt }}</a>
        </template>
      </a-table>
    </div>
    <a-modal v-model:visible="visible" :title="title" destroyOnClose width="80%" :bodyStyle="{padding: '10px',background:'#EEEEEE'}" footer="">
      <Details  :info="info"></Details>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref, reactive } from 'vue'
import SearchList from '@/components/searchList'
import moment from 'moment'
import { transitionTimeYM, getTableBody, IfNull, compute } from '@/utils/util'
import { invoiceStatistic, invoiceStatisticTotal } from '@/api/financialCenter/financialCenter'
import Details from './details.vue'

const loading = ref(false)
const dataSource = ref([])
const data = ref([])
const pageSize = ref(50)
const tableRef = ref(null)
const visible = ref(false)
const title = ref('')
const info = ref({})
const total = ref({
  invoiceRedAmt:0,
  monthQuota:0,
  processAmt:0,
  usedQuota:0
})

const searchAdd = (e) => {
 if(e.invoiceCarrier){
  dataSource.value = data.value.filter(item => item.invoiceCarrier === e.invoiceCarrier)
 }else{
  dataSource.value = data.value
 }
 if(e.taxRateSubjectName){
  dataSource.value = dataSource.value.filter(item => item.taxRateSubjectName === e.taxRateSubjectName)
 }
 total.value = getTatotalAmt(dataSource.value)

}
const getTatotalAmt = (data) => {
 let msg = {}
 msg.invoiceRedAmt = compute('+', ...data.map(item => item.invoiceRedAmt)).result
 msg.monthQuota = compute('+', ...data.map(item => item.monthQuota)).result
 msg.processAmt = compute('+', ...data.map(item => item.processAmt)).result
 msg.usedQuota = compute('+', ...data.map(item => item.usedQuota)).result
 return msg
}
const resetBtn = () => {
  dataSource.value = data.value.slice(0, pageSize.value)
  total.value = getTatotalAmt(data.value)
}
const lookDetails = (record,type,key) => {
  if(record[key] == 0) return
  visible.value = true
  title.value = record.orgName +  '-已开票车辆'
  record.applyInvoice = type
  info.value = record
}
const getProfitList = () => {
  loading.value = true
  invoiceStatistic({
  }).then(res => {
    if (res.code !== 10000) return
    data.value = res.data
    total.value = getTatotalAmt(data.value)
    dataSource.value = data.value.slice(0, pageSize.value)
    let invoiceCarrierList = []
    res.data.forEach(item => {
      if (item.invoiceCarrier) {
        invoiceCarrierList.push({
          name: item.invoiceCarrier,
          id: item.invoiceCarrier
        })
      } else {
        item.invoiceCarrier = ''
      }
    })
    iptData.value[0].opt = invoiceCarrierList.filter((item, index) => invoiceCarrierList.findIndex(i => i.name === item.name) === index);
    let taxRateSubjectNameList = []
    res.data.forEach(item => {
      if (item.taxRateSubjectName) {
        taxRateSubjectNameList.push({
          name: item.taxRateSubjectName,
          id: item.taxRateSubjectName
        })
      } else {
        item.taxRateSubjectName = ''
      }
    })
    iptData.value[1].opt = taxRateSubjectNameList.filter((item, index) => taxRateSubjectNameList.findIndex(i => i.name === item.name) === index);
  }).finally(() => {
    loading.value = false
  })
}

const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
onMounted(() => {
  getProfitList()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})
const iptData = ref([
  {
    type: "select",
    placeholder: "选择主体",
    value: null,
    prop: "invoiceCarrier",
    width: 200,

  },
  {
    type: "select",
    placeholder: "选择税率",
    value: null,
    prop: "taxRateSubjectName",
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '开票主体',
    dataIndex: 'invoiceCarrier',
    align: 'left',
  },
  {
    title: '税率',
    dataIndex: 'taxRate',
    slots: {
      customRender: 'taxRate'
    },
    align: 'left',
  },
  {
    title: '部门',
    dataIndex: 'orgName',
    align: 'left',
  },
  {
    title: '本月总额度',
    dataIndex: 'monthQuota',
    align: 'right',
  },
  {
    title: '当前已用额度',
    dataIndex: 'usedQuota',
    slots: {
      customRender: 'usedQuota'
    },
    align: 'right',
  },
  {
    title: '本月已开金额',
    dataIndex: 'invoiceAmt',
    align: 'right',
    slots: {
      customRender: 'invoiceAmt'
    }
  },
  {
    title: '本月申请中',
    dataIndex: 'processAmt',
    align: 'right',
  },
  {
    title: '本月红冲',
    dataIndex: 'invoiceRedAmt',
    align: 'right',
  },
])
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
.sys-screenTitle{
  span{
    color: #0066CC;
    font-size: 16px;
    font-weight: 700;
  }
}
</style>