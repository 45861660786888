<template>
  <div>
    <a-space>
      <a-select style="width: 140px" v-model:value="searchType" placeholder="选择地理类型"
        @change="BIGpagination.current = 1, loadData()" allowClear>
        <a-select-option :value="1">大板车</a-select-option>
        <a-select-option :value="2">小板车</a-select-option>
        <a-select-option :value="3">货车</a-select-option>
        <a-select-option :value="4">地跑</a-select-option>
      </a-select>
      <a-input v-model:value="searchVinno" placeholder="车牌号" />
      <a-button type="primary" @click="search">搜索</a-button>
      <a-button @click="onAddVehicle">新增</a-button>
    </a-space>
    <a-table :data-source="dataSource" v-model:columns="mirrorImgColumns" :loading="loading" :pagination="BIGpagination"
      @change="handleChange" :rowKey="(record, index) => { return index }" size="small">
      <template #carNo="{ record }">
        <div>
          <a @click="lookBigCarData(record)">{{ record.carNo }}</a>
          <span v-show="record.gpsType">({{ record.gpsType?.label }})</span>
        </div>
      </template>
      <template #gpsQuery="{ record }">
        <div>
          <a @click="gpsSearchRef.onAutoQuery(true, record.carNo)">位置查询</a>
        </div>
      </template>
      <template #operation="{ record }">
        <div>
          <span v-show="searchType !== 4 && record.truckStatus && record.truckStatus.value !== 1"
            size="small">当前状态不能操作</span>
          <a-button v-show="searchType !== 4 && record.truckStatus && record.truckStatus.value === 1" size="small"
            type="text" style="background:#F2F2F2;color:#0066DD" @click="choose(record, 1)">{{ record.choose ? '已选择/切换' :
              '选该车' }}</a-button>
          <a-divider v-show="searchType !== 4 && record.truckStatus && record.truckStatus.value === 1" type="vertical" />
          <a-button v-show="searchType !== 4 && record.truckStatus && record.truckStatus.value === 1" size="small"
            type="text" style="background:#F2F2F2;color:#0066DD" @click="choose(record, 2)">选司机</a-button>
          <a-button v-show="searchType === 4" size="small" @click="selfDriving(record)">选该地跑司机</a-button>
        </div>
      </template>
    </a-table>
  </div>
  <!-- 选择司机 -->
  <a-modal width="50%" v-model:visible="deiverShow" title="选择司机" @ok="deiverShow = false" :destroyOnClose="true">
    <Driver ref="driverRef" :regionalType="1" :carrierId="carrierId" @onondriverName="chooseDriver" />
  </a-modal>
  <!-- 大车详情 -->
  <a-modal v-model:visible="bigCarShow" title="大车信息" @ok="bigCarShow = false">
    <a-form layout="horizontal" ref="formRef">
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="发车时间">
            {{ transportform ? transportform.departureTime : '' }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="派车人">
            {{ transportform ? transportform.dispatcher : '' }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="线路名称">
            {{ transportform ? transportform.lineName : '' }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="已占车位">
            {{ transportform ? transportform.usedParkPlace : '' }}
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
  <!-- 位置查询 -->
  <GPS ref="gpsSearchRef" />
  <!-- 承运商车辆添加 -->
  <Addvehicle @loadData="loadData" :regionalType="1" :carrierId="carrierId" ref="_addVehicle" />
</template>

<script>
import { onMounted, reactive, toRefs, ref, watch } from 'vue'
import { carrierTruckDomesticPage, truckByCarrierTruckId } from '@/api/transport/truck'
import { Freepage } from '@/api/crm/carrer/driver'
import Driver from '@/views/components/driver'
import GPS from '../comon/gps.vue'
import Addvehicle from '../../../carrier/toolDetail/vehicleList/add.vue'
export default {
  props: { carrierId: String },
  components: {
    Driver,
    GPS,
    Addvehicle
  },
  setup (props, context) {
    const _addVehicle = ref()
    const gpsSearchRef = ref(null)
    const driverRef = ref(null)
    const state = reactive({
      searchType: 1,
      searchVinno: '',
      carrierId: '',
      bigCarShow: false,
      loading: false,
      deiverShow: false,
      detail: {},
      transportform: {},
      dataSource: [],
      mirrorImgColumns: [],
      columns: [
        {
          title: '运输类型',
          dataIndex: 'truckType.label'
        },
        {
          title: '大车车牌',
          dataIndex: 'carNo',
          slots: {
            customRender: 'carNo'
          }
        }, {
          title: '位置查询',
          dataIndex: 'gpsQuery',
          slots: {
            customRender: 'gpsQuery'
          }
        },
        {
          title: '剩余空位',
          dataIndex: 'vacancy'
        },
        {
          title: '历史承运次数',
          dataIndex: 'history'
        },
        {
          title: '当前状态',
          dataIndex: 'truckStatus.label'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      BIGcolumnss: [
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          ellipsis: true
        },
        {
          title: '电话',
          dataIndex: 'mobile',
          key: 'mobile',
          ellipsis: true
        },
        {
          title: '承运商',
          dataIndex: 'carrierName',
          key: 'carrierName',
          ellipsis: true
        },
        {
          title: '选择',
          dataIndex: 'operation',
          width: 200,
          fixed: 'right',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      BIGpagination: {
        pageSize: 3,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['3', '6', '10'],
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const loadData = () => {
      state.loading = true
      if (state.searchType === 4) {
        state.mirrorImgColumns = state.BIGcolumnss
        Freepage({
          carrierId: state.carrierId,
          current: state.BIGpagination.current,
          size: state.BIGpagination.pageSize,
          name: state.searchVinno,
          truckType: state.searchType
        }).then(res => {
          if (res.code === 10000) {
            state.dataSource = res.data.records
            state.BIGpagination.total = res.data.total
            state.loading = false
          }
        })
      } else {
        state.mirrorImgColumns = state.columns
        carrierTruckDomesticPage({
          carrierId: state.carrierId,
          current: state.BIGpagination.current,
          size: state.BIGpagination.pageSize,
          name: state.searchVinno,
          truckType: state.searchType
        }).then(res => {
          if (res.code === 10000) {
            state.dataSource = res.data.records
            state.BIGpagination.total = res.data.total
            state.loading = false
          }
        })
      }
    }
    const handleChange = page => {
      state.BIGpagination.current = page.current
      loadData()
    }
    const search = () => {
      state.BIGpagination.current = 1
      loadData()
    }
    // 确认该车和司机
    const choose = (item, num) => {
      switch (num) {
        case 1: // 选择该车
          if (state.detail.choose) state.detail.choose = false
          if (state.detail.chooseDriver) state.detail.chooseDriver = false
          state.detail = item
          state.detail.choose = true
          state.detail.chooseDriver = true
          state.detail.carrierTruckId = item.id
          break
        case 2: // 选择司机
          state.deiverShow = true
          setTimeout(() => {
            driverRef.value.loadData()
          }, 100)
          break
      }
    }
    const chooseDriver = recode => {
      if (recode) {
        // state.detail.carrierDriverId = recode.id
        // state.detail.driver = recode.name
        // state.detail.carType = recode.driverType.label
        const objData = { ...state.detail, ...recode }
        state.detail = objData
        context.emit('carDriverData', state.detail)
        state.deiverShow = false
      }
    }
    // 选择地跑司机
    const selfDriving = record => {
      if (record) {
        state.detail = record
        context.emit('carDriverData', state.detail)
      }
    }
    // 查看大车信息
    const lookBigCarData = record => {
      state.bigCarShow = true
      state.transportform = {}
      truckByCarrierTruckId(record.id).then(res => {
        if (res.code === 10000) {
          state.transportform = res.data
        }
      })
    }

    watch(() => props.carrierId, (newVal, oldVal) => {
      if (newVal !== undefined) state.carrierId = newVal
      loadData()
    }, { deep: true })
    onMounted(() => {
      if (props.carrierId) {
        state.carrierId = props.carrierId
      }
      loadData()
    })
    // 新增承运商车辆
    const onAddVehicle = () => {
      _addVehicle.value.addShow = true
    }
    return {
      ...toRefs(state),
      lookBigCarData,
      chooseDriver,
      handleChange,
      selfDriving,
      loadData,
      choose,
      search,
      gpsSearchRef,
      driverRef,
      onAddVehicle,
      _addVehicle
    }
  }
}
</script>

<style lang="less" scoped></style>
