import { putRequest, getRequest, postBodyRequest } from '@/utils/axios'

export function page (params) {
  return postBodyRequest('/cis/org/page', params)
}
export function list (params) {
  return postBodyRequest('/cis/org/list', params)
}

export function cityOrg (params) {
  return getRequest('/cis/system/cityOrg', params)
}

export function edit (params) {
  return postBodyRequest('/cis/org/edit', params)
}

export function add (params) {
  return postBodyRequest('/cis/org/add', params)
}

export function enable (id, isnable) {
  return putRequest(`/cis/org/${id}/${isnable}`)
}

export function editLeader (params) {
  return postBodyRequest('/cis/org/charge', params)
}

// 下拉获取所有一级机构
export function levelOneList (params) {
  return postBodyRequest('/cis/org/level-one/list', params)
}