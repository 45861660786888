<template>
  <div style="padding-bottom: 10px;position:relative">
    <div style="width: 100%;">
      <!-- 基本信息 -->
      <div v-show="windowShow">
        <div class="content-title">
          <div style="width:100%;display: flex;justify-content: space-between">
            <div>基本信息</div>
            <div>
              <span class="m-r1" v-if="detailData.isDelivery?.value === 0">
                <a-button size="small" v-show="detailData.isFocus?.value === 0" type="primary" @click="follow(0)">关注</a-button>
                <a-button size="small" v-show="detailData.isFocus?.value === 1" type="primary" @click="follow(1)">取消关注</a-button>
              </span>
              <!-- <a-button v-if="!detailType" size="small" type="primary" v-show="detailData.isShare?.value === 0 || !detailData.isShare" @click="shareBtn(1)">共享</a-button>
              <a-button v-if="!detailType" size="small" type="primary" v-show="detailData.isShare?.value === 1" @click="shareBtn(0)">取消共享</a-button> -->
              <a-popconfirm title="确定中途完成?" @confirm="transportComplete(element)">
                <a-button v-if="!detailType" v-show="detailData.executeStatus?.value === 4" style="margin-left:10px" size="small" type="primary">中途完成</a-button>
              </a-popconfirm>
            </div>
          </div>
        </div>
        <div>
          <div class="detail-data">
            <div class="data-title">订单信息</div>
            <div class="data-content">{{ detailData.orderId || '' }}</div>
            <div class="data-content">
              <a-tag color="orange" v-show="detailData.orderStatus?.value === 1 || detailData.orderStatus?.value === 2">
                {{ detailData.orderStatus?.label }}</a-tag>
              <a-tag color="red" v-show="detailData.orderStatus?.value === 7"> {{ detailData.orderStatus?.label }}</a-tag>
              <a-tag color="blue" v-show="detailData.orderStatus?.value === 9"> {{ detailData.orderStatus?.label
              }}</a-tag>
              <span v-show="detailData.orderStatus?.value !== 1 && detailData.orderStatus?.value !== 2 && detailData.orderStatus?.value !== 7 && detailData.orderStatus?.value !== 9">{{
                  detailData.orderStatus?.label }}</span>
            </div>
            <div class="data-content">共<span style="margin: 0 5px;font-weight:bold">{{ detailData.vehicleNum || 0
            }}</span>辆</div>
            <div class="data-content" v-if="detailData.pickAppointTime && detailData.pickAppointTime.length > 0"><a-tag color="red">预约时间：{{ detailData.pickAppointTime }}</a-tag></div>
          </div>
          <div class="detail-data">
            <div class="data-title">客户信息</div>
            <div class="data-content">{{ detailData.customerName || '' }}</div>
            <div class="data-content">{{ detailData.isInside?.label || '' }}</div>
          </div>
          <div class="detail-data">
            <div class="data-title">起点-终点</div>
            <div class="data-content" style="font-weight:bold">{{ detailData.startAddress || '' }}-{{
              detailData.endAddress || '' }}</div>
            <div class="data-content">车辆图片 <a-tag> {{ detailData.picNum }}</a-tag> 张</div>
          </div>
          <div class="detail-data">
            <div class="data-title">取车信息</div>
            <div class="data-content" v-show="detailData.isPlaceIn?.value === 0">否</div>
            <div class="data-content" v-show="detailData.pickType && detailData.isPlaceIn?.value === 1">{{
              detailData.pickType?.label || '' }}</div>
            <div class="data-content" style="font-weight:bold" v-show="detailData.isPlaceIn?.value === 1">{{
              detailData.placeInAddress }}</div>
          </div>
          <div class="detail-data">
            <div class="data-title">送车信息</div>
            <div class="data-content" v-show="detailData.isTakeOut?.value === 0">否</div>
            <div class="data-content" v-show="detailData.takeType && detailData.isTakeOut?.value === 1">{{
              detailData.takeType?.label || '' }}</div>
            <div class="data-content" style="font-weight:bold" v-show="detailData.isTakeOut?.value === 1">{{
              detailData.takeOutAddress }}</div>
          </div>
          <div class="detail-data">
            <div class="data-title">特殊需求</div>
            <div class="data-content" v-show="detailData.isInspectionPay?.value === 1"><a-tag color="red">验车打款</a-tag>
            </div>
            <div class="data-content" v-show="detailData.isNoticeReleaseCar?.value === 1"><a-tag color="red">等通知放车</a-tag>
            </div>
            <div class="data-content" v-show="detailData.isDeliveryCard?.value === 1"><a-tag color="red">交车条</a-tag></div>
            <div class="data-content" v-show="detailData.isNoticeSalesman?.value === 1"><a-tag color="red">通知业务员</a-tag></div>
          </div>
          <div class="detail-data">
            <div class="data-title">财务信息</div>
            <div class="data-content">成本：{{ detailData.amt || '' }}</div>
            <div class="data-content">优惠券：{{ detailData.coupon || '' }}</div>
            <div class="data-content">产品：{{ detailData.productName || '' }}</div>
            <div class="data-content">
              合同总金额：<a-tag v-show="detailData.contractAmt" color="red">￥{{ detailData.contractAmt || '' }}</a-tag>
              <span v-show="detailData.paymentArrival > 0"> 订单到付：<a-tag color="red">{{ detailData.paymentArrival
              }}</a-tag></span>
              <span> 返款：<a-tag color="red">{{ detailData.refundFee }}</a-tag></span>
              <a @click="openPayQRC" v-show="detailData.orderStatus?.value === 1 || detailData.orderStatus?.value === 2 || detailData.orderStatus?.value === 9">生成支付码</a>
            </div>
          </div>
          <div class="detail-data">
            <div class="data-title">结算信息</div>
            <div class="data-content"><a-tag color="blue">{{ detailData.orderSettlement?.label || '' }}</a-tag></div>
            <div class="data-content"><a-tag v-show="detailData.settlementStatus !== 0" color="green">{{
              detailData.settlementStatus?.label || '' }}</a-tag></div>
            <div class="data-content"><a-tag v-show="detailData.settlementStatus === 0" color="red">{{
              detailData.settlementStatus?.label || '' }}</a-tag></div>
          </div>
          <div class="detail-data" v-show="detailData.orderRemark && detailData.orderRemark !== ''">
            <div class="data-title">订单备注</div>
            <div class="data-content" style="color:#f00">{{ detailData.orderRemark }}</div>
          </div>
          <div class="detail-data" v-show="detailData.remark && detailData.remark !== ''">
            <div class="data-title">小车备注</div>
            <div class="data-content" style="color:#f00">{{ detailData.remark }}</div>
          </div>
        </div>
      </div>
      <!-- 原始方案 -->
      <div class="content-title">原始方案</div>
      <div class="content-data">
        <div style="text-align:center" v-show="originalPlanList.length <= 0">暂无原始方案</div>
        <div class="content-div" v-show="originalPlanList.length > 0">
          <div class="plant-data" v-for="(item, index) in originalPlanList" :key="index">
            <div class="city-data" v-show="item.startCityName">{{ item.startCityName }}</div>
            <div class="detail-data-plant">
              <div class="top-data">{{ '￥' + item.cost }}<span v-show="item.prescription && item.prescription !== null">&nbsp;{{
                    item.prescription + '天' }}</span></div>
              <div style="white-space:normal;">{{ item.carrierName || '\u3000' }}</div>
            </div>
            <div class="city-data" v-show="item.endCityName">{{ item.endCityName }}</div>
          </div>
        </div>
      </div>
      <!-- 实际方案 -->
      <div class="content-title">实际方案</div>
      <div class="content-data">
        <div style="text-align:center" v-show="practicalList.length <= 0">暂无实际方案</div>
        <div class="content-div" style="color:#0C8226" v-show="practicalList.length > 0">
          <div class="plant-data" v-for="(item, index) in practicalList" :key="index">
            <div class="city-data" v-show="item.startAddress">{{ item.startAddress }}</div>
            <div class="detail-data-plant">
              <div class="top-data" style="border-color:#0C8226">{{ '￥' + item.freightCost }}<span v-show="item.usedTime && item.usedTime !== null">&nbsp;{{ item.usedTime }}</span></div>
              <div style="white-space:normal;">{{ item.carrierName || '\u3000' }}</div>
            </div>
            <div class="city-data" v-show="item.endAddress">{{ item.endAddress }}</div>
          </div>
        </div>
      </div>
      <!-- 派单任务 -->
      <div v-if="detailType">
        <div class="content-title">
          <span style="margin-right: 15px">派单任务</span>
        </div>
        <a-tabs v-model:activeKey="activeKey" @change="tabsChange" v-if="dispatchDetail.details?.length > 0">
          <a-tab-pane :key="1" tab="发运模式">
            <a-table :columns="transColumns" :data-source="dispatchDetail?.details" :pagination="false" bordered :row-key="record => record.totvId" :scroll="{ x: 2500 }" size="small">
              <template #carrierName="{ record }">
                <div><a @click="carrierEdit(record)">{{ record.carrierName }}</a></div>
              </template>
              <template #transportFee="{ record }">
                <div>
                  <a @click="editMoney(record)">{{ record.transportFee }}</a>
                </div>
              </template>
              <template #settlementStatus="{ record }">
                对账：<a @click="settlementData(record.ftsId, record.billStatus)">{{ record.billStatus ?
                  record.billStatus.label : '' }}</a>
                <a-divider type="vertical" />
                <span>{{ record.settlementStatus ? record.settlementStatus.label : '' }}</span>
              </template>
              <template #orderSettlement="{ record }">
                <div>
                  {{ record.settlementMode?.label }}
                </div>
              </template>
              <template #repeal="{ record }">
                <span v-if="record.show && record.transportType?.value !== 0 && record.truckStatus?.value === 4">
                  <a-popconfirm ok-text="是" cancel-text="否" @confirm="repealClick(record)">
                    <template #title>
                      <div>
                        <p>确认撤销？</p>
                        <a-textarea v-model:value="repealRemark" placeholder="备注信息" :rows="4" />
                      </div>
                    </template>
                    <a>撤销</a>
                  </a-popconfirm>
                </span>
                <a style="margin-left:10px" v-show="record.transportType?.value === 0" @click="handleCope(record, 1)">验车码</a>
                <a style="margin-left:10px" @click="recordData = record, addReportShow = true">上报</a>
              </template>
            </a-table>
          </a-tab-pane>
          <!-- <a-tab-pane :key="2" tab="任务模式">
            <div class="content-data" style="margin-bottom:10px" v-for="(item, index) in dispatchDetail.tasks"
              :key="index">
              <div>
                <a style="font-weight:normal;" @click="updateImg">
                  <span>车辆照片</span>
                  <span style="margin-left:10px">{{ item.picNum || '0' }}张</span>
                </a>
                <div>
                  <div style="display: flex;align-items:center;margin-bottom:15px;">
                    <div class="title-name-style">{{ item.taskType?.label }}</div>
                    <a-divider type="vertical" />
                    <span style="font-size:14px">
                      <span>{{ item.taskName }}</span>
                    </span>
                    <a-divider type="vertical" />
                    <span class="operation">
                      <img class="img" src="@/assets/images/carrier/QRC.png" @click="handleCope(item)">
                    </span>
                  </div>

                </div>
                <div style="display: flex;justify-content: space-between;font-size: 12px;">
                  <div>
                    <div class="title-color">承运商</div>
                    <div class="data-color">{{ item.carrierName }}</div>
                  </div>
                  <div>
                    <div class="title-color">派单价</div>
                    <div class="data-color">￥{{ item.totalAmt }}</div>
                  </div>
                  <div>
                    <div class="title-color">当前状态</div>
                    <div class="data-color">{{ item.taskStatus?.label }}</div>
                  </div>
                  <div style="position: relative;">
                    <div class="operate-btn">
                      <a-popconfirm ok-text="确认" cancel-text="取消" @confirm="confirmCancel(item)">
                        <template #title>
                          <div>
                            <div>确认取消该任务？</div>
                            <div><a-input v-model:value="cancelPlantRemark" placeholder="取消原因" /></div>
                          </div>
                        </template>
                        <div v-show="!item.btnShow"><a-button
                            style="margin-bottom:5px;background:#0066CC;color:#fff">撤销</a-button></div>
                      </a-popconfirm>
                      <div><a-button style="background:#0066CC;color:#fff" @click="monitorDetail(item)">监控</a-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-tab-pane> -->
        </a-tabs>

        <div v-if="dispatchDetail.tasks?.length > 0" class="m-t2 m-b2">
          <TransportTasksModal :dispatchDetail="dispatchDetail"></TransportTasksModal>
        </div>
      </div>
    </div>
    <div class="loading-content" v-show="loadingData"><a-spin size="large" /></div>
  </div>
  <!-- 小程序码 -->
  <a-modal v-model:visible="QRCshow" title="小程序码" footer="">
    <div class="QRC-img">
      <div>
        <div>车架号：<a-tag color="green">{{ QRCdata.vinNo }}</a-tag></div>
        <div>线路：<span style="color:#f00">{{ QRCdata.lineName }}</span></div>
        <img v-show="!QRCloading" class="img" :src="QRCurl" style="width:100%;height:100%">
        <div style="text-align: center"><a-spin v-show="QRCloading" size="large" /></div>
      </div>
    </div>
  </a-modal>
  <!-- 支付码 -->
  <a-modal v-model:visible="payQRCshow" title="支付码" footer="">
    <div v-show="QRCcontext === ''" style="display:flex;justify-content:center;flex-direction: column;align-items: center;">
      <div v-show="!QRCloading" style="text-align:left;margin-bottom:10px">
        <div>起：{{ detailData.startAddress }}</div>
        <div>终：{{ detailData.endAddress }}</div>
        车架号：<a-tag color="green">{{ detailData.vinNo }} </a-tag>{{ detailData.brand }} {{ detailData.model }}
      </div>
      <QrcodeVue id="QRCpayImg" v-show="!QRCloading" :value="payUrl" :size="200" level="H" />
      <div style="text-align: center"><a-spin v-show="QRCloading" size="large" /></div>
    </div>
    <div v-show="QRCcontext !== ''" style="text-align: center;">{{ QRCcontext }}</div>
  </a-modal>
  <!--修改承运商-->
  <a-modal v-model:visible="carrierShow" title="修改承运商" width="50%" :destroyOnClose="true">
    <div>
      <CarrierList @carrier="carrierChange" />
    </div>
  </a-modal>
  <!-- 修改金额 -->
  <a-modal v-model:visible="editShow" title="修改内容" :destroyOnClose="true" :confirmLoading="editLoading" @ok="confirmEdit" @cancel="closeEditShow">
    金额：
    <a-input v-model:value="money" type="number" />
    <a-divider />
    备注：
    <a-textarea :maxlength="200" v-model:value="reason" placeholder="请说明您要修改的原因" />
  </a-modal>
  <!-- 结算信息 -->
  <a-modal v-model:visible="settlementShow" :width="400" footer="" :destroyOnClose="true">

    <div class="m-b1">
      <span class="order-data-title">账单号：</span>
      <span class="font-color2" style="display: inline-block;width: 50%">
        <a-typography-paragraph :copyable="{ text: settlementDetail.id }">
          {{ settlementDetail.id }}
        </a-typography-paragraph>
      </span>
    </div>
    <p :span="12">
      <span class="order-data-title">客户名称：</span>
      <span class="font-color2">{{ settlementDetail.customerName }}</span>
    </p>
    <p span="12">
      <span class="order-data-title">对账人：</span>
      <span class="font-color2">{{ settlementDetail.sponsor }}</span>
    </p>
    <p :span="12">
      <span class="order-data-title">对账时间：</span>
      <span class="font-color2">{{ settlementDetail.createTime }}</span>
    </p>
  </a-modal>
  <!-- 验车图片 -->
  <InspectionImg ref="imgListRef" :orderVehicleId="detailData.orderVehicleId" :loadInspectList="[]" />
  <!-- 发运详情 -->
  <TransportDetail :windowShow="monitorDetailShow" :taskDetail="taskDetail" @statusShow="monitorDetail" />
  <!-- 异常上报 -->
  <AddReport ref="addReportRef" :windowShow="addReportShow" :vehicleDetail="recordData" @reportData="addReportData" />
  <!-- 支付二维码 -->
  <PayQRC ref="payQRCref" />
</template>

<script>
import { reactive, toRefs, ref, onMounted, createVNode, watch } from 'vue'
import { getDispatchList, getReclineData, getActualRouteLine, cancelPlant, addReport, shareVehicle, changeOrderVehicleStatus, focusAdd, focusCancel } from '@/api/transport/transport'
import { transEdit } from '@/api/trans/ops/edit'
import { vehicleSettlement, vehicleDeliveryInfo } from '@/api/transport/truck'
import { QRCurl, QRCpayurl } from '@/api/transport/inspection'
import { message, Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import QrcodeVue from 'qrcode.vue'
import AddReport from '@/views/components/transport/comon/addReport.vue'
import InspectionImg from '@/views/components/vehicleUpload/inspectionImg'
import CarrierList from '@/views/transportCapacity/transportUse/comon/carrierList'
import TransportDetail from './transportDetail.vue'
import PayQRC from '@/views/components/payQRC'
import TransportTasksModal from './transportTasksModal.vue'

export default {
  props: {
    windowShow: Boolean,
    data: Boolean,
    tovId: String,
    detailType: Boolean, // true 用于区分小车列表展示派单任务组件
  },
  components: {
    PayQRC,
    AddReport,
    QrcodeVue,
    InspectionImg,
    CarrierList,
    TransportDetail,
    ExclamationCircleOutlined,
    TransportTasksModal
  },
  setup (props, context) {
    const payQRCref = ref(null)
    const imgListRef = ref(null)
    const state = reactive({
      windowShow: false,
      monitorDetailShow: false,
      priceDataShow: false,
      editShow: false,
      deliveryLoading: false,
      addReportShow: false,
      editLoading: false,
      QRCloading: false,
      carrierShow: false,
      loadingData: false,
      settlementShow: false,
      detailType: false,
      QRCshow: false,
      payQRCshow: false,
      activeKey: 1,
      money: null,
      QRCdata: {},
      detailData: {},
      recordData: {},
      taskList: [],
      taskDetail: {},
      dispatchDetail: {},
      settlementDetail: {},
      originalPlanList: [],
      practicalList: [],
      QRCcontext: '',
      cancelPlantRemark: '',
      orderVehicleId: '',
      repealRemark: '',
      payUrl: '',
      QRCurl: '',
      reason: '',
      transColumns: [
        {
          title: '大车车牌',
          dataIndex: 'carNo',
          width: '5%'
        },
        {
          title: '运输类型',
          width: '4%',
          dataIndex: 'transportType.label'
        },
        {
          title: '承运商',
          width: '10%',
          slots: {
            customRender: 'carrierName'
          }
        },
        {
          title: '运费',
          dataIndex: 'transportFee',
          width: '4%',
          slots: {
            customRender: 'transportFee'
          }
        },
        {
          title: '运输状态',
          dataIndex: 'truckStatus.label',
          width: '4%'
        },
        {
          title: '运输路线',
          dataIndex: 'lineName',
          width: '12%'
        },
        {
          title: '对账结算',
          dataIndex: 'settlementStatus',
          width: '10%',
          slots: {
            customRender: 'settlementStatus'
          }
        },
        {
          title: '结算类型',
          width: '4%',
          slots: {
            customRender: 'orderSettlement'
          }
        },
        {
          title: '安排时间',
          dataIndex: 'loadingTime',
          width: '8%'
        },
        {
          title: '安排人',
          dataIndex: 'loadingOperator',
          width: '3%'
        },
        {
          title: '发车时间',
          dataIndex: 'departureTime',
          width: '8%'
        },
        {
          title: '发车人',
          dataIndex: 'departure',
          width: '3%'
        },
        {
          title: '卸车时间',
          dataIndex: 'unloadingTime',
          width: '7%'
        },
        {
          title: '卸车人',
          dataIndex: 'unloadingOperator',
          width: '3%'
        },
        {
          title: '卸车地',
          dataIndex: 'endAddress',
          width: '10%'
        },
        {
          title: '操作',
          width: 150,
          align: 'center',
          fixed: 'right',
          slots: {
            customRender: 'repeal'
          }
        }
      ],
    })

    watch(() => props.windowShow, (newVal, oldVal) => {
      state.windowShow = newVal
      state.detailData = props.data
      state.detailType = props.detailType
      state.orderVehicleId = state.detailData.orderVehicleId
      if (state.windowShow === true) {
        // getTaskListData()
      }
    })
    // 关注
    const follow = (type) => {
      if (type === 0) {
        focusAdd({
          orderVehicleId: state.detailData.orderVehicleId
        }).then(res => {
          if (res.code === 10000) {
            getTaskListData()
            message.success('关注成功，如需取消关注请到我的关注列表或车辆详情操作')
          }
        })
      }
      if(type === 1){
        focusCancel({
          orderVehicleId: state.detailData.orderVehicleId
        }).then(res => {
          if (res.code === 10000) {
            getTaskListData()
            message.success('取消成功')
            context.emit('focusCancel',state.detailData.orderVehicleId)
          }
        })
      }
    }
    const shareBtn = (num) => {
      Modal.confirm({
        title: () => num === 0 ? '确认取消共享吗?' : '确认将选择的车辆共享吗?',
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () =>
          createVNode(
            'div',
            {
              style: 'color:red;'
            },
            '',
          ),
        onOk () {
          shareVehicle({
            isShare: num,
            orderVehicleIds: [state.orderVehicleId]
          }).then(res => {
            if (res.code === 10000) {
              message.success(num === 0 ? '该车已取消共享' : '共享成功',)
              getTaskListData()
            }
          })
        },
        onCancel () {
        },
        class: '',
      })
    }
    // 运车完成
    const transportComplete = () => {
      changeOrderVehicleStatus({ transOrderVehicleIds: [props.tovId] })
        .then(res => {
          if (res.code === 10000) {
            message.success(`${state.detailData.vinNo}已中途完成`)
            getTaskListData()
          }
        })
    }
    const clearData = () => {
      console.log('小车操作详情数据已清空');
      state.orderVehicleId = ''
      state.detailType = false
      state.dispatchDetail = {}
      state.detailData = {}
      state.taskDetail = {}
      state.QRCdata = {}
      state.recordData = {}
    }
    onMounted(() => {
      state.detailData = props.data
    })
    // 详情数据
    const getTaskListData = (num) => {
      // num为判断是否需要额外刷新实际/原始方案数据
      state.loadingData = true
      if (state.detailData.orderVehicleId) state.orderVehicleId = state.detailData.orderVehicleId
      getDispatchList(state.orderVehicleId || state.detailData.orderVehicleId).then(res => {
        if (res.code === 10000) {
          if (res.data.tasks && res.data.tasks.length > 0) {
            res.data.tasks.forEach(v => {
              v.QRCshow = false
              v.btnShow = false
              if (v.taskStatus.value === 9) v.btnShow = true
              else v.btnShow = false
            })
          }
          setTimeout(() => {
            res.data.orderVehicleId = state.orderVehicleId
            state.recordShow = true
            state.detailData = res.data
            state.dispatchDetail = res.data
            if (state.dispatchDetail.details.length > 0) state.dispatchDetail.details[state.dispatchDetail.details.length - 1].show = true
            state.taskList = state.detailData.tasks
            state.loadingData = false
            context.emit('statusShow', res.data)
            if (num !== 1) {
              getTaskLineData()
              getActualRouteLineData()
            }
          }, 50)
        }
      })
    }
    // 原始方案数据获取
    const getTaskLineData = () => {
      state.originalPlanList = []
      getReclineData({ orderId: state.detailData.orderId })
        .then(res => {
          if (res.code === 10000) {
            state.originalPlanList = res.data
            if (state.originalPlanList.length > 0) {
              state.originalPlanList.forEach((v, i) => {
                if (i + 1 < state.originalPlanList.length) {
                  if (v.endCityName == state.originalPlanList[i + 1].startCityName) v.endCityName = null
                }
              })
            }
          }
        })
    }
    // 实际方案获取
    const getActualRouteLineData = () => {
      state.practicalList = []
      getActualRouteLine({ orderVehicleId: state.orderVehicleId || state.detailData.orderVehicleId }).then(res => {
        if (res.code === 10000) {
          state.practicalList = res.data
          state.practicalList.forEach((v, i) => {
            if (i + 1 < state.practicalList.length) {
              if (v.endAddress == state.practicalList[i + 1].startAddress) v.endAddress = null
            }
          })
        }
      })
    }
    // 生成小程序码
    const handleCope = (item, num) => {
      if (num === 1) {
        state.QRCshow = true
        state.status = 0
        state.QRCdata = item
        if (state.QRCshow === true) {
          state.QRCloading = true
          const wXprogramQRcode = { scene: state.orderVehicleId || state.detailData.orderVehicleId, type: 1 }
          QRCurl(wXprogramQRcode)
            .then(res => {
              if (res.code === 10000) {
                state.QRCurl = 'data:image/png;base64,' + res.data
                state.QRCloading = false
              }
            })
            .catch(err => console.log(err))
            .finally(() => {
              state.QRCloading = false
            })
        }
      } else {
        if (item.taskId !== '' && item.taskId !== undefined && item.taskId !== null) {
          state.QRCdata = item
          state.QRCshow = true
          if (state.QRCshow === true) {
            state.QRCloading = true
            const wXprogramQRcode = { scene: item.id, type: 1 }
            QRCurl(wXprogramQRcode)
              .then(res => {
                if (res.code === 10000) {
                  state.QRCurl = 'data:image/png;base64,' + res.data
                }
              })
              .catch(err => console.log(err))
              .finally(() => {
                state.QRCloading = false
              })
          }
        }
      }
    }
    const QRCpayImg = () => {
      state.payQRCshow = true
      state.QRCloading = true
      state.status = 1
      QRCpayurl({
        orderId: state.detailData.orderId,
        payType: '1'
      }).then(res => {
        if (res.code === 10000) {
          state.payUrl = res.data.qrCode
          state.QRCcontext = ''
        }
        if (res.code === 10001) {
          state.QRCcontext = res.msg
        }
      }).finally(() => {
        state.QRCloading = false
      })
    }
    // 运输信息-修改金额
    const editMoney = record => {
      state.recordData = record
      // state.money = record.transportFee
      state.editShow = true
    }
    // 运输信息-对账
    const settlementData = (id, status) => {
      if (status && status.value === 1) {
        state.settlementShow = true
        state.settlementDetail = {}
        vehicleSettlement(id)
          .then(res => {
            if (res.code === 10000) {
              state.settlementDetail = res.data
            }
          })
      } else {
        message.warning('还未对账')
      }
    }
    // 运输信息-修改承运商
    const carrierEdit = record => {
      state.recordData = record
      state.carrierShow = true
    }
    // 运输信息-修改承运商接口
    const carrierChange = (e) => {
      if (e) {
        transEdit({
          params: {
            transportTruckId: state.recordData.transTruckId,
            totvId: state.recordData.totvId,
            carrierId: e.id,
            carrierName: e.carrierName
          },
          type: {
            value: 1
          },
          reason: e.reason,
          subType: 102
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.carrierShow = false
            getTaskListData()
          }
        })
      }
    }
    // 运输信息-撤销到达
    const repealClick = record => {
      transEdit({
        params: {
          transportTruckId: record.transTruckId,
          totvId: record.totvId
        },
        type: {
          value: 1
        },
        reason: state.repealRemark,
        subType: 104
      }).then(res => {
        if (res.code === 10000) {
          message.success('撤销到达成功')
          context.emit('statusShow', true)
          state.repealRemark = ''
          getTaskListData()
        }
      })
    }
    const monitorDetail = e => {
      if (e.orderVehicleId) state.taskDetail = e, context.emit('taskDetail', state.taskDetail)
      if (e !== undefined && !e.orderVehicleId) state.monitorDetailShow = e
      else state.monitorDetailShow = true
    }
    // 验车图片
    const updateImg = () => {
      imgListRef.value.inspectionImg = true
      imgListRef.value.loadData(state.detailData.orderVehicleId)
    }
    // 确认修改金额
    const confirmEdit = () => {
      if (state.money) {
        state.editLoading = true
        transEdit({
          params: {
            transportTruckId: state.recordData.transTruckId,
            totvId: state.recordData.totvId,
            changeFee: state.money
          },
          type: {
            value: 1
          },
          reason: state.reason,
          subType: 101
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.editShow = false
            getTaskListData()
          }
        }).finally(() => { state.editLoading = false })
      } else {
        message.error('请填写金额')
      }
    }
    // 取消任务
    const confirmCancel = item => {
      if (state.cancelPlantRemark != '') {
        cancelPlant({
          remark: state.cancelPlantRemark,
          taskId: item.taskId
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            getTaskListData()
          }
        })
      } else message.info('请填写原因')
    }
    const tabsChange = num => {
      context.emit('statusShow', num)
    }
    // 新增异常上报
    const addReportData = e => {
      if (e.reportType !== null && e.reportType !== undefined && e.reportType !== 0) {
        delete e.belong
        let submit = {
          ...e,
          totvId: state.detailData.totvId || state.recordData.totvId,
          orderVehicleId: state.detailData.orderVehicleId,
          taskId: state.abnormalTask,
        }
        addReport(submit).then(res => {
          if (res.code === 10000) {
            state.addReportShow = false
            message.success('上报成功')
          }
        })
      } else state.addReportShow = false
    }
    const openPayQRC = () => {
      let obj = {
        startAddress: state.detailData.startAddress,
        endAddress: state.detailData.endAddress,
        orderId: state.detailData.orderId,
        ovId: state.detailData.orderVehicleId,
        vehicleList: [{ vinNo: state.detailData.vinNo }]
      }
      setTimeout(() => {
        payQRCref.value.openWindow(obj)
      }, 10)
    }
    const closeEditShow = () => {
      state.editShow = false
      state.money = null
      state.reason = ''
    }
    return {
      ...toRefs(state),
      payQRCref,
      clearData,
      updateImg,
      handleCope,
      confirmEdit,
      tabsChange,
      shareBtn,
      QRCpayImg,
      openPayQRC,
      closeEditShow,
      transportComplete,
      addReportData,
      getTaskListData,
      confirmCancel,
      monitorDetail,
      editMoney,
      repealClick,
      carrierEdit,
      carrierChange,
      settlementData,
      follow
    }

  }
}
</script>

<style lang="less" scoped>
@import '../index.less';

.detail-data {
  display: flex;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  font-size: 12px;
  flex-wrap: nowrap;
  border: 1px solid #eee;
  background: rgba(242, 242, 242, 0.3);

  .data-title {
    width: 100px;
    color: #6b778c;
  }

  .data-content {
    margin-left: 30px;
    color: #344563;
  }

  .special-div {
    color: #fff;
    padding: 1px 2px;
    background: #0066cc;
  }
}

.loading-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.content-data {
  width: 100%;
  .content-div {
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
  }
}

.operation {
  position: relative;

  .QRC-img {
    width: 200px;
    // top: -100%;
    // left: 100%;
    // position: absolute;
    // padding: 5px;
    // background-color: #fff;
    // z-index: 9;
  }
}

.offer-data {
  width: 100%;
  position: absolute;
  left: 0;
  top: 100%;
  border: 1px solid #eee;
  background-color: #fff;
  z-index: 99;

  .altogether {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #1890ff;
    font-weight: bold;
  }

  .title-num {
    color: #ffffff;
    width: 22px;
    line-height: 22px;
    background-color: #0584fa;
    border-radius: 90px;
  }

  .reference {
    display: flex;
    justify-content: space-between;
    color: rgb(175, 172, 172);
  }
}
</style>
