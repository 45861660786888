<template>
  <div>
    <a-modal :visible="visible" title="交车" width="30%" @ok="submit" @cancel="cancel" :confirmLoading="btnLoading">
      <a-form ref="formRef" :model="fromOpt" :rules="rulesOpt">
        <a-form-item name="deliveryType" label="收款对象">
          <div class="flex ">
            <div v-for="item in recipientOfPay" @click="celItem(item)" :key="item.value" class="itemBox m-r2 cur-p" :class="{ 'active': item.value === fromOpt.deliveryType }">
              {{ item.label }}
            </div>
          </div>
        </a-form-item>
        <a-form-item name="fileId" label="交车条">
          <ly-upload ref="uploadRef" :maxMun="1" @fileStatus="fileStatus"></ly-upload>
        </a-form-item>
        <a-form-item label="交车说明">
          <a-textarea v-model:value="fromOpt.remark" placeholder="请填写" :rows="2" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import lyFormList from '@/components/formList'
import { recipientOfPay } from "@/utils/enum.js";
import lyUpload from '@/components/ly-upload'
import { message } from 'ant-design-vue';
import { vehicleDelivery } from '@/api/transport/truck'
const emit = defineEmits(['update:visible', 'getVal','success'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  vehicleId:{
    type: String,
    default: ''
  }
})
const formRef = ref(null)
const uploadRef = ref(null)
const btnLoading = ref(false)
const fromOpt = ref({
  deliveryType: null,
  deliveryOfficeOrg: 0,
  fileId: 1,
  remark: ''
})
const rulesOpt = ref({
  deliveryType: [{ required: true, message: '请选择收款对象', type: 'number', trigger: 'change', }],
  fileId: [{ required: true, message: '请上传', type: 'number', trigger: 'change' }]
})
const fileStatus = (e) => {
  btnLoading.value = e
}
const cancel = () => {
  emit('update:visible', false)
}
const celItem = (item) => {
  fromOpt.value.deliveryType = item.value
  formRef.value.clearValidate('deliveryType')
}

const submit = () => {
  formRef.value.validate().then(() => {
    if (uploadRef.value.state.fileIds.length === 0) {
      message.error('请上传交车条')
      return
    }
    emit('getVal', fromOpt.value, uploadRef.value.state.fileIds)
    btnLoading.value = true
    vehicleDelivery(props.vehicleId,{
      ...fromOpt.value,
      fileId: uploadRef.value.state.fileIds[0].id,
    }
    ).then((res) => {
      if (res.code === 10000) {
        message.success(res.msg)
        emit('success')
      }
    }).finally(() => {
      btnLoading.value = false
    })
  })
}
defineExpose({
  btnLoading
})
</script>

<style lang="less" scoped>
.itemBox {
  padding: 6px 18px;
  border: 1px solid #c1c7d0;
  color: #6b778c;
  border-radius: 6px;
}
.active {
  background: #1890ff;
  border-color: #1890ff;
  color: #fff;
}
:deep(.ant-form-item-label) {
  width: 100%;
  text-align: left;
}
:deep(.ant-form-item) {
  margin-bottom: 5px;
}
:deep(.ant-radio-wrapper) {
  margin-right: 38px;
}
</style>