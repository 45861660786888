<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle">共有 <a>{{data.length}}</a> 条数据</div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #vinNo="{ record }">
          <div>{{ record.vinNo }}</div>
          {{ record.brand }}&nbsp;{{ record.model }}
        </template>
      </a-table>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import { carrierVehicleDetails } from '@/api/financialCenter/financialCenter'
import { getTableBody, IfNull } from '@/utils/util'
import { message } from 'ant-design-vue'
const props = defineProps({
  empId: {
    type: String,
    default: ''
  },
  info: {
    type: Object,
    default: {}
  }
})
const tableRef = ref(null)
const dataSource = ref([])
const data = ref([])
const loading = ref(false)
const pageSize = ref(50)

const searchAdd = (e) => {
  if (e.vinNo) {
    dataSource.value = data.value.filter(item => {
      return item.vinNo.includes(e.vinNo) 
    })
  }
}
const resetBtn = () => {
  pageSize.value = 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
const getOrderRefundDetails = () => {
  loading.value = true
  carrierVehicleDetails({
    creditId:props.info.creditId,
    dispatcherId:props.info.dispatcherId,
    type:props.info.type,
    orderId:props.info.orderId
  }).then(res => {
    if (res.code !== 10000 || !res.data) return
    data.value = res.data
    dataSource.value = res.data.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
onMounted(() => {
  getOrderRefundDetails()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})

const iptData = ref([
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'left',
  },
  {
    title: '车牌号',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'left',
    width: 200
  },
  {
    title: '交车人',
    dataIndex: 'dispatcher',
    align: 'left',
  },
  {
    title: '交车时间',
    dataIndex: 'deliveryTime',
    align: 'left',
  },
 
])

</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>