<template>
  <div>
    <div style="display:flex;justify-content:space-between;margin:10px 0">
      <a-form layout="inline">
          <a-form-item label="">
              <a-select style="width: 160px" allowClear v-model:value="searchForm.regionalType"  :options="carType" placeholder="地理分类" />
            </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.carNo" placeholder="车牌号"/>
        </a-form-item>
        <a-form-item>
          <a-button :loading="loading" type="primary" @click="search">搜索</a-button>
        </a-form-item>
      </a-form>
      <a-button @click="onAdd">+新增车辆</a-button>
    </div>
    <a-table size="small" :columns="columns" :row-key="record => record.vinNo" :data-source="listData" bordered :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #operation="{record}">
        <div style="display:flex;justify-content: space-around;">
          <a @click="detail(record)">查看车辆资料图片</a>
          <a @click="onDetail(record)">编辑</a>
          <a @click="onDetailImg(record)">编辑图片</a>
          <!--          <a-popconfirm title="确认删除?" ok-text="确认" cancel-text="取消" @confirm="deleteData(record)">-->
          <!--            <a style="color:#A30014">删除</a>-->
          <!--          </a-popconfirm>-->
        </div>
      </template>
    </a-table>
    <a-modal v-model:visible="detailShow" footer="" :height="500" destroyOnClose>
      <template #title>
        <span style="font-size:20px">车辆资料图片</span>
      </template>
      <ImgDetail :detailImg="detailImg" v-if="detailShow" ref="_imgDetail" />
    </a-modal>
    <Addvehicle @loadData="loadData" :carrierId="carrierId" ref="_addVehicle" />
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, ref } from 'vue'
import { message } from 'ant-design-vue'
import { getPage, carrierDetail, deleteVeh } from '@/api/carrier/vehiclesList'
import ImgDetail from '../imgDetail.vue'
import Addvehicle from './add.vue'

export default {
  components: {
    ImgDetail,
    Addvehicle
  },
  props: { carrierId: String },
  setup (props) {
    const _imgDetail = ref()
    const _addVehicle = ref()
    const state = reactive({
      carrierId: props.carrierId,
      loading: false,
      btnLoad: false,
      detailShow: false,
      addShow: false,
      editShow: 0,
      drivingLoading: false,
      businessLoading: false,
      insuranceLoading: false,
      truckHeadLoading: false,
      carRearLoading: false,
      flag: 0,
      empty: {},
      detailImg: [],
      detailImgEdit: [],
      listData: [],
      drivingLicenseFile: [],
      operationCertificateFile: [],
      insuranceFile: [],
      truckHeadFile: [],
      truckTailFile: [],
      drivingLicenseImg: '',
      operationCertificateImg: '',
      insuranceImg: '',
      truckHeadImg: '',
      truckTailImg: '',
      searchForm: {
        carNo: '',
        regionalType:null
      },
      addForm: {
        truckType: {},
        gpsType: {},
        carNo: '',
        place: '0',
        drivingLicense: '',
        operationCertificate: '',
        insurance: '',
        truckHead: '',
        truckTail: ''
      },
      carType:[
        {
          label: '国内',
          value:1
        },
        {
          label: '国外',
          value:2
        },
      ],
      columns: [
        {
          title: '大车车牌',
          dataIndex: 'carNo',
          align: 'center'
        },
        {
          title: '地理分类',
          dataIndex: 'regionalType.label',
          align: 'center'
        },
        {
          title: '大车类型',
          dataIndex: 'truckType.label',
          align: 'center'
        },
        {
          title: 'GPS类型',
          dataIndex: 'gpsType.label',
          align: 'center'
        },
        {
          title: '车辆型号',
          dataIndex: 'model',
          align: 'center'
        },
        {
          title: '车位',
          dataIndex: 'place',
          align: 'center'
        },
        {
          title: '',
          dataIndex: 'operation',
          align: 'center',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const loadData = () => {
      state.loading = true
      getPage({
        carNo: state.searchForm.carNo,
        regionalType: state.searchForm.regionalType,
        carrierId: state.carrierId,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).catch(err => { console.log(err) })
        .finally(() => { state.loading = false })
    }
    // 搜搜
    const search = () => {
      state.pagination.current = 1
      loadData()
    }
    onMounted(() => {
      // loadData()
      state.empty = JSON.parse(JSON.stringify(state.addForm))
    })
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const detail = record => {
      carrierDetail(record.id)
        .then(res => {
          if (res.code === 10000) {
            if (res.data.drivingLicenseUrl === null) {
              res.data.drivingLicenseUrl = {}
            }
            if (res.data.operationCertificateUrl === null) {
              res.data.operationCertificateUrl = {}
            }
            if (res.data.insuranceUrl === null) {
              res.data.insuranceUrl = {}
            }
            if (res.data.truckHeadUrl === null) {
              res.data.truckHeadUrl = {}
            }
            if (res.data.truckTailUrl === null) {
              res.data.truckTailUrl = {}
            }

            state.detailImg = [{
              title: '行驶证',
              url: res.data.drivingLicenseUrl.previewUrl,
              previewUrl: res.data.drivingLicenseUrl.url
            }, {
              title: '营运证',
              url: res.data.operationCertificateUrl.previewUrl,
              previewUrl: res.data.operationCertificateUrl.url
            }, {
              title: '车辆保单',
              url: res.data.insuranceUrl.previewUrl,
              previewUrl: res.data.insuranceUrl.url
            }, {
              title: '车牌照片(车头)',
              url: res.data.truckHeadUrl.previewUrl,
              previewUrl: res.data.truckHeadUrl.url
            }, {
              title: '车牌照片(挂车)',
              url: res.data.truckTailUrl.previewUrl,
              previewUrl: res.data.truckTailUrl.url
            }]
            setTimeout(() => {
              _imgDetail.value.loadData()
            }, 100)
            state.detailShow = true
          }
        }).catch(err => { console.log(err) })
    }
    const deleteData = record => {
      deleteVeh(record.id)
        .then(res => {
          if (res.code === 10000) {
            message.success('删除' + res.msg)
            loadData()
          }
        }).catch(err => { console.log(err) })
    }
    const onAdd = () => {
      _addVehicle.value.addShow = true
    }
    const onDetail = (e) => {
      _addVehicle.value.onDetail(e)
    }
    const onDetailImg = (e) => {
      _addVehicle.value.onDetailImg(e)
    }

    return {
      ...toRefs(state),
      detail,
      loadData,
      _imgDetail,
      _addVehicle,
      deleteData,
      handleTableChange,
      onDetail,
      onAdd,
      onDetailImg,
      search
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../common.less';
</style>
