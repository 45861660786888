import { getRequest, postBodyRequest } from '@/utils/axios'

export function carBrandList (params) {
  return getRequest('/cis/system/car', params)
}

export function carBrandTree (params) {
  return getRequest('/cis/system/car/tree', params)
}

export function enterprise (params) {
  return postBodyRequest('/cis/system/enterprise', params)
}

// 新增国外的城市
export function addGuowaiCity (params) {
  return postBodyRequest('/cis/system/add-guowai-city', params)
}