<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle">共有 <a>{{data.length}}</a> 条数据</div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #vinNo="{ record }">
          <div>{{ record.vinNo }}</div>
          {{ record.brand }}&nbsp;{{ record.model }}
        </template>
        <template #add="{ record }">
              {{ record.lossReason }}
        </template>
      </a-table>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import {financeCenterOrgDetail } from '@/api/financialCenter/financialCenter'
import { getTableBody, IfNull } from '@/utils/util'
import { message } from 'ant-design-vue'
const props = defineProps({
  empId: {
    type: String,
    default: ''
  },
  info: {
    type: Object,
    default: {}
  }
})
const tableRef = ref(null)
const dataSource = ref([])
const data = ref([])
const loading = ref(false)
const pageSize = ref(50)



const searchAdd = (e) => {
  if (e.customerName) {
      dataSource.value = data.value.filter(item => {
        return item.customerName.includes(e.customerName)  
      })
  }else{
    dataSource.value  = data.value
  }
  if(e.salesman){
    dataSource.value = dataSource.value.filter(item => {
        return item.salesman.includes(e.salesman) 
      })
  }
  if(e.vinNo){
    dataSource.value = dataSource.value.filter(item => {
        return item.vinNo.includes(e.vinNo) 
      })
  }
}
const resetBtn = () => {
  pageSize.value = 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
const getOrderRefundDetails = () => {
  loading.value = true
  financeCenterOrgDetail( {
    year: props.info.year,
    month: props.info.month,
    orgId:props.info.orgId
  }).then(res => {
    if (res.code !== 10000 || !res.data) return
    data.value = res.data
    dataSource.value = res.data.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
onMounted(() => {
  getOrderRefundDetails()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})

const iptData = ref([
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'left',
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'left',
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'left',
  },
  {
    title: '推送时间',
    dataIndex: 'pushTime',
    align: 'left',
  },
  {
    title: '保险成本',
    dataIndex: 'insuranceCost',
    align: 'right',
  },
  {
    title: '累计发车价',
    dataIndex: 'transportCost',
    align: 'right',
  },
  {
    title: '合同总金额',
    dataIndex: 'contractAmt',
    align: 'right',
  },
  {
    title: '税费',
    dataIndex: 'invoiceCost',
    align: 'right',
  },
  {
    title: '返款金额',
    dataIndex: 'refundFee',
    align: 'right',
  },
  {
    title: '其他费用',
    dataIndex: 'additionalFeeTotal',
    align: 'right',
  },
  {
    title: '毛利',
    dataIndex: 'grossProfit',
    align: 'right',
  },
  {
    title: '亏损原因',
    dataIndex: 'add',
    align: 'left',
    slots: {
      customRender: 'add'
    },
    width: 200
  },
])

</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>