<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle m-b1"><span class="fz-16">待返款金额(元)：&nbsp;</span><a class="fz-16 fz-cu">{{ totalAmt }}</a></div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="item => item.orderVehicleId" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #refunder="{ record }">
          {{ record.refunder }}&nbsp;{{ record.refunderMobile }}
        </template>
        <template #settableAmt="{ record }">
         <a @click="lookDetails(record)"> {{ record.settableAmt }}</a>
        </template>
      </a-table>
    </div>
    <a-modal v-model:visible="visible" :title="title" destroyOnClose width="80%" :bodyStyle="{padding: '10px',background:'#EEEEEE'}" footer="">
       <Details :customerId="customerId" :remakItem="remakItem" :refunder="refunder"></Details>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import { orderRefundTotal } from '@/api/businessCenter/businessCenter'
import moment from 'moment'
import { transitionTimeYM, getTableBody, IfNull, compute } from '@/utils/util'
import Details from './details.vue'

const tableRef = ref(null)
const loading = ref(false)
const dataSource = ref([])
const data = ref([])
const pageSize = ref(50)
const totalAmt = ref('')
const title = ref('')
const visible = ref(false)
const customerId = ref('')
const refunder = ref('')
const remakItem = ref({})

const iptData = ref([
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '返款人信息',
    dataIndex: 'refunder',
    slots: {
      customRender: 'refunder'
    },
    align: 'center',
  },
  {
    title: '返款金额',
    dataIndex: 'settableAmt',
    slots: {
      customRender: 'settableAmt'
    },
    align: 'center',
    width: 150
  },

])

const lookDetails = (record) => {
  remakItem.value = record
  customerId.value = record.customerId
  refunder.value = record.refunder
  title.value = record.customerName + '-待返款订单'
  visible.value = true
}
const searchAdd = (e) => {
  if(!IfNull(e.customerName)) return
  dataSource.value = data.value.filter(item =>{
    return item.customerName.includes(IfNull(e.customerName))
  })
  let list = dataSource.value.map(item => item.settableAmt)
  totalAmt.value  =  list.length === 0 ? 0 :  compute('+',...list).result
}
const resetBtn = () => {
  pageSize.value = 50
  dataSource.value = data.value.slice(0,pageSize.value)
  let list = dataSource.value.map(item => item.settableAmt)
  totalAmt.value  =  list.length === 0 ? 0 :  compute('+',...list).result
}
const getOrderRefundTotal = () => {
 
  loading.value= true
  orderRefundTotal({}).then( res => {
    if(res.code !== 10000) return
       data.value = res.data.list
       dataSource.value = res.data.list.slice(0,pageSize.value)
       totalAmt.value = res.data.totalAmt
  }).finally(() => {
    loading.value = false
  })
}
const theBottom = () => {
  if(dataSource.value.length === 0) return
  if(pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0,pageSize.value)

}
onMounted(() => {
  getOrderRefundTotal()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'),theBottom )
})
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>