<template>
  <div>
   <div class="m-t1 tableBox">
    <div class="sys-screenTitle m-b1"><span>共&nbsp;</span><a >{{ data.length }}</a>个</div>
    <a-table ref="tableRef" :columns="columns" :row-key="(record,index) =>index" bordered size="small" :data-source="dataSource" :pagination="false" :loading="loading" :scroll="{y:500}">
      <template #sort="{ index }">
        {{ index + 1 }}
      </template>
      <template #customerName="{ record }">
        <a v-if="record.isSelf?.value ===1" @click="lookDetails(record)">
          <span v-if="record.customerName">{{ record.customerName }}</span>
          <span v-else>无</span>
        </a>
        <span v-else>
          <span v-if="record.customerName">{{ record.customerName }}</span>
          <span v-else>无</span>
        </span>
      </template>
      <template #mobile="{ record }">
        {{ record.mobile }}
        <span v-if="record.mobile && record.wechat">/</span>
        {{ record.wechat }}
      </template>
    </a-table>
   </div>
       <!-- 客户详情 -->
       <a-drawer title="详情" width="80%" placement="right"  v-model:visible="detailShow" destroyOnClose>
      <CustomerDetail v-model:customerId="customerId" :type="detailType" />
    </a-drawer>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { customerSearch } from '@/api/businessCenter/businessCenter'
import {  getTableBody } from '@/utils/util'
import CustomerDetail from '@/views/crmManagement/tool/customerDetail/index.vue'
const props = defineProps({
  typeNum:{
    type: Number,
    default: 0
  },
  keyword:{
    type: String,
    default: ''
  }
})
const loading = ref(false)
const dataSource = ref([])
const data = ref([])
const pageSize = ref(50)
const tableRef = ref(null)
const customerId = ref('')
const detailShow = ref(false)
const detailType = ref('')


const lookDetails = (record) => {
  if(record.customerClass){
    detailType.value = String(record.customerClass.value - 1) 
  }else{
    detailType.value = '2'
  }
  detailShow.value = true
  customerId.value = record.customerId
}
const loadData = () => {
  loading.value = true
  customerSearch({
    mobile: props.typeNum === 1 ? props.keyword : '',
    wechat: props.typeNum === 2 ? props.keyword : '',
    customerName:  props.typeNum === 3 ? props.keyword : '',
   
  }).then(res => {
    // console.log(res)  // 主要动态数据
    if (res.code === 10000) {
      data.value = res.data
      dataSource.value =  data.value.slice(0,pageSize.value)
    }
  }).finally(() => {
    loading.value = false
  })
}
const columns = ref([
  { title: '序号', dataIndex: 'sort', slots: { customRender: 'sort' },align: 'center' ,width:80},
  { title: '客户名称', dataIndex: 'customerName',align: 'center',slots: { customRender: 'customerName'} },
  { title: '电话/微信', dataIndex: 'mobile',align: 'center',slots: { customRender: 'mobile'} },
  { title: '客户类型', dataIndex: 'customerType.label' ,align: 'center'},
  { title: '线索分类', dataIndex: 'clueType.label',align: 'center' },
  { title: '所在位置', dataIndex: 'customerClass.label' ,align: 'center'},
  { title: '业务员', dataIndex: 'saleName' ,align: 'center'},
  { title: '创建时间', dataIndex: 'createTime' ,align: 'center'},
  { title: '领取时间', dataIndex: 'enterPrivateTime' ,align: 'center'},
  { title: '合作时间', dataIndex: 'enterCoopTime',align: 'center' },
  { title: '结算方式', dataIndex: 'settlementMode.label',align: 'center' },
  { title: '首次负责人', dataIndex: 'firstSaleName',align: 'center' },
])
const theBottom = () => {
  if(dataSource.value.length === 0) return
  if(pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0,pageSize.value)

}
onMounted(() => {
  loadData()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'),theBottom )
})
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>