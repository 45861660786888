<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle">共有 <a>{{data.length}}</a> 条数据</div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #vinNo="{ record }">
          <div>{{ record.vinNo }}</div>
          {{ record.brand }}&nbsp;{{ record.model }}
        </template>
        <template #add="{ record }">
          <div class="flex al-center">
            <div style="width: 75%;">
              {{ record.lossReason }}
            </div>
            <a v-if="record.grossProfit <= 0" @click="openModal(record)"> 填写</a>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 原因 -->
    <a-modal v-model:visible="reviewVisible" title="原因" :confirmLoading="btnLoading" @ok="FormListRef.getValRef()" destroyOnClose>
      <FormList @getVal="getVal" ref="FormListRef" :fromList="fromList"></FormList>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import { empEmpId, lossAdd } from '@/api/businessCenter/businessCenter'
import { getTableBody, IfNull } from '@/utils/util'
import FormList from '@/components/formList'
import { message } from 'ant-design-vue'
const props = defineProps({
  empId: {
    type: String,
    default: ''
  },
  info: {
    type: Object,
    default: {}
  }
})
const tableRef = ref(null)
const dataSource = ref([])
const data = ref([])
const loading = ref(false)
const pageSize = ref(50)
const reviewVisible = ref(false)
const btnLoading = ref(false)
const FormListRef = ref()
const record = ref(null)

//原因
const getVal = (e) => {
  btnLoading.value = true
  lossAdd({
    orderVehicleId: record.value.orderVehicleId,
    ...e
  }).then(res => {
    if (res.code !== 10000) return
    message.success('添加成功')
    reviewVisible.value = false
    getOrderRefundDetails()
  }).finally(() => {
    btnLoading.value = false
  })
}
const openModal = (item) => {
  reviewVisible.value = true
  record.value = item
}
const searchAdd = (e) => {
  if (IfNull(e.customerName) && e.profitZero) {
    if (e.profitZero === 1) {
      dataSource.value = data.value.filter(item => {
        return item.customerName.includes(IfNull(e.customerName)) && item.grossProfit <= 0
      })
    } else {
      dataSource.value = data.value.filter(item => {
        return item.customerName.includes(IfNull(e.customerName)) && item.grossProfit > 0
      })
    }
    return
  }

  if (IfNull(e.customerName)) {
    dataSource.value = data.value.filter(item => {
      return item.customerName.includes(IfNull(e.customerName))
    })
  }
  if (e.profitZero) {
    if (e.profitZero === 1) {
      dataSource.value = data.value.filter(item => {
        return item.grossProfit <= 0
      })
    } else {
      dataSource.value = data.value.filter(item => {
        return item.grossProfit > 0
      })
    }
  }


}
const resetBtn = () => {
  pageSize.value = 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
const getOrderRefundDetails = () => {
  loading.value = true
  empEmpId(props.empId, {
    year: props.info.year,
    month: props.info.month
  }).then(res => {
    if (res.code !== 10000 || !res.data) return
    data.value = res.data
    dataSource.value = res.data.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
onMounted(() => {
  getOrderRefundDetails()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})
const fromList = ref([
  {
    label: "原因",
    name: "lossReason",
    type: "textarea",
    placeholder: "填写原因",
    value: null,
    prop: "lossReason",
    width: "100%",
    rows: 5,
    rules: [{ required: true, message: "请填写", trigger: "blur", type: "string" }],
  },
])
const iptData = ref([
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "select",
    placeholder: "利润是否小于等于0",
    value: null,
    prop: "profitZero",
    opt: [
      { name: '是', id: 1 },
      { name: '否', id: 2 },
    ],
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
  },
  {
    title: '推送时间',
    dataIndex: 'pushTime',
    align: 'center',
  },
  {
    title: '合同总金额',
    dataIndex: 'contractAmt',
    align: 'center',
  },
  {
    title: '累计发车价',
    dataIndex: 'transportCost',
    align: 'center',
  },
  {
    title: '保险成本',
    dataIndex: 'insuranceCost',
    align: 'center',
    width: 150
  },
  {
    title: '税费',
    dataIndex: 'invoiceCost',
    align: 'center',
  },
  {
    title: '返款金额',
    dataIndex: 'refundFee',
    align: 'center',
  },
  {
    title: '附加费',
    dataIndex: 'additionalFeeTotal',
    align: 'center',
  },
  {
    title: '毛利',
    dataIndex: 'grossProfit',
    align: 'center',
  },
  {
    title: '亏损原因',
    dataIndex: 'add',
    align: 'center',
    slots: {
      customRender: 'add'
    },
    width: 200
  },
])

</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>