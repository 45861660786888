
export default {
  'menu.login': '登录',
  'menu.home': '主页',

  'menu.system': '系统管理',
  'menu.system.position': '职务管理',
  'menu.system.role': '角色管理',
  'menu.system.organzition': '机构管理',
  'menu.system.employee': '员工管理',
  'menu.system.officeArea': '办公区域',
  'menu.system.vehiclemodel': '车型管理',
  'menu.system.cityModel':'城市管理',

  'menu.flow': '工作流程',
  'menu.flow.myFlow': '我的申请',
  'menu.flow.myPending': '我的待办',
  'menu.flow.myProcessed': '我的已办',
  'menu.flow.copyList': '抄送列表',
  'menu.crm': '客户中心',
  // 'menu.crm.customer': '客户列表',
  // 'menu.crm.customer.addOrder': '批量订单',
  // 'menu.crm.carrier': '承运商列表',

  'menu.order': '订单中心',
  'menu.order.internal': '内部下单',
  'menu.order.abroad': '国外订单',
  'menu.order.businessCenter': '业务中台',
  'menu.order.expenseList': '附加费',
  'menu.order.internal.add': '新增散单',
  'menu.order.internal.edit': '修改订单',
  'menu.order.internal.detail': '订单详情',
  'menu.order.evaluationList': '评论列表',
  'menu.order.positionInfo.detail': '车辆详情',
  'menu.order.insurance': '保险列表',
  'menu.order.insuranceOutOne':'保险列表(外1)',
  'menu.order.insuranceOutTwo':'保险列表(外2)',
  'menu.order.Coffer': 'C端报价',
  'menu.order.Boffer': 'B端报价',
  'menu.order.queryVehicle': '一键查车',
  'menu.order.documentary': '跟单列表',
  'menu.order.followOrderAbnormalList': '异常列表',
  'menu.order.virtualNumber': '虚拟号',
  'menu.order.overdueReview': '分析审核',


  'menu.transport': '运输中心',
  'menu.transport.car': '运单列表',
  'menu.transport.bigcar': '运车列表',
  'menu.transport.complement': '中途补位',
  'menu.transport.transportList': '大车列表',
  'menu.transport.gps': '车辆跟踪',
  'menu.transport.offer': '报价系统',
  'menu.transport.offerXls': '批量报价',
  'menu.transport.lineAdd': '报价添加',
  'menu.transport.line': '线路列表',
  'menu.transport.vehicle': '小车列表',
  'menu.transport.abnormalReport': '异常上报',
  'menu.transport.qualityLoss': '车辆质损',
  'menu.transport.inspectionList': '验车列表',

  'menu.finance': '财务中心',
  'menu.finance.financeCenter': '财务中台',
  'menu.finance.bodyConfiguration': '发票配置',
  'menu.finance.InitiateBilling':'发票管理',
  'menu.finance.invoiceStatistics':'发票统计',
  'menu.finance.income': '财务账单',
  'menu.finance.income.list': '财务利润表-分页',
  'menu.finance.compositeReport': '综合报表',
  'menu.finance.orderReport': '订单报表',
  'menu.finance.transReport': '运单报表',
  'menu.finance.incomeExpenditureSituation': '收支情况',
  'menu.finance.settlement.carrier': '承运商结算',
  'menu.finance.settlement.customer': '客户结算',
  'menu.finance.settlement.vipCustomer': '大客户结算',
  'menu.finance.settlement.tip': '返款结算',
  'menu.finance.settlement.other': '附加费结算',
  'menu.finance.bill.carrier': '应收应付',
  'menu.finance.archive.transProfit': '办事处利润',
  'menu.finance.archive.businessProfit': '业务部业绩',
  'menu.finance.archive.accountAge': '账龄分析',
  'menu.finance.archive.payList': '支付列表',

  'menu.dataCenter': '数据中心',
  'menu.dataCenter.monitoring': '公司准时率',
  'menu.dataCenter.punctual': '运力准时率',
  'menu.dataCenter.prescription': '时效看板',
  'menu.dataCenter.regionalStatistics': '区域统计',
  'menu.dataCenter.vehicleQuota': '货量指标',
  'menu.dataCenter.stock': '库存统计',
  'menu.dataCenter.estimatedProfit': '利润数据',
  'menu.dataCenter.stockKanBan': '库存看板',
  'menu.dataCenter.transKanbBan': '发运看板',
  'menu.dataCenter.carrier': '承运商看板',
  'menu.dataCenter.carrierList': '承运商列表',
  'menu.dataCenter.regionalCarrier': '线路列表',
  'menu.dataCenter.customer': '客户看板',
  'menu.dataCenter.vipCustomer': 'VIP客户',
  'menu.dataCenter.profitNorm': '利润指标',
  'menu.dataCenter.OfficeProfit': '办事处盈利分析',
  'menu.dataCenter.taskStatistics': '台量信息',
  'menu.dataCenter.abnormalStatistics': '异常统计',
  'menu.dataCenter.customerKanBan': '客户看板',
  'menu.dataCenter.carrierStatistics': '承运商统计',
  'menu.dataCenter.massLossStatistics': '承运商质损',
  'menu.dataCenter.lineStatistics': '线路统计',
  'menu.dataCenter.privateSeaKanBan': '私海看板',
  'menu.dataCenter.cooperativeKanBan': '合作看板',
  'menu.dataCenter.customerStatistics': '客户统计',

  'menu.inquiry': '平台中心',
  'menu.inquiry.inquiryList': '询价列表',
  'menu.inquiry.clueList': '线索列表',
  'menu.inquiry.orderList': '订单列表',

  'menu.account': '个人页',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.logout': '退出登录',

  'menu.opsCenter': '运维中心',
  'menu.opsCenter.opsApplication': '运维申请',
  'menu.opsCenter.opsRevise': '运维修改',
  'menu.opsCenter.dailyPoster': '每日海报',

  'menu.repository':'知识库',
  'menu.repository.categoryConfiguration': '类目配置',
  'menu.repository.articleManagement': '知识管理',
  'menu.repository.repository': '关联列表',
  'menu.repository.feedbackList': '反馈意见',

  'menu.marketing': '营销中心',
  'menu.marketing.modList': '模板列表',
  // 'menu.marketing.useMod': '投放中模板',
  'menu.marketing.channelManagement': '渠道管理',
  'menu.marketing.statistics': '营销统计',
  'menu.crmManagement.drainageClue': '引流线索',

  'menu.crmManagement': 'CRM管理',
  'menu.crmManagement.setRules': '规则设置',
  'menu.crmManagement.comSea': '公海设置',
  'menu.crmManagement.privateSea': '私海设置',
  'menu.crmManagement.approveContract': '审批合同',
  'menu.crmManagement.cooperativeCustomers': '合作客户',
  'menu.crmManagement.weChatUser': 'C端小程序用户',
  'menu.crmManagement.crmOrderList': 'C端订单列表',
  'menu.crmManagement.crmInquiryList': 'C端询价单列表',
  'menu.crmManagement.relevance': '关联客户',
  'menu.crmManagement.salespersonIndicatorSetting':'业务指标设置',

  'menu.storeCarrier': '承运商管理',
  'menu.storeCarrier.storeCarrierList': '承运商管理',
  'menu.storeCarrier.storeCarrierApprove': '承运商审核',
  'menu.storeCarrier.lineList': '干线省内线路',
  'menu.storeCarrier.strongholdLineList': '同城线路',

  'menu.store': '办事处管理',
  'menu.store.storeUse': '办事处操作',

  'menu.transportCapacity': '运力管理',
  'menu.transportCapacity.transportUse': '运力操作',
  'menu.transportCapacity.dispatchDeploy': '调度配置',
  'menu.transportCapacity.punctual': '运力准时率',
  'menu.insurance': '保险管理',
  'menu.insurance.user': '用户列表',
  'menu.insurance.order': '保单列表',

  'menu.achievement': '绩效管理',
  'menu.achievement.achievementAudit': '绩效审核',
  'menu.achievement.mineCustom': '绩效定制',
  'menu.achievement.standardList': '指标列表',

  'menu.lineList': '线路管理',
  'menu.lineList.optimalLine': '运力报价',
  'menu.lineList.optimalLineBEnd': 'B端报价',
  'menu.lineList.optimalLineCEnd': 'C端报价',
  'menu.lineList.optimalLineExternal': '所有报价',
  'menu.lineList.optimalLineSame': '集散地',
  'menu.lineList.grade': '运力城市等级',
  'menu.lineList.gradeBEnd': 'B端城市等级',
  'menu.lineList.gradeCEnd': 'C端城市等级',

  'menu.BEnd': '车小象管理',
  'menu.BEnd.applet': '小程序用户',
  'menu.BEnd.comSea': '小程序公海',
  'menu.BEnd.order': '订单列表',
  'menu.BEnd.inquiry': '询价单列表',
  'menu.BEnd.coupon': '优惠券',
  'menu.BEnd.couponHistory': '优惠券历史',
  'menu.BEnd.extension': '推广人',

  'menu.yst': '通联管理',
  'menu.yst.memberPageYst': '通联会员',
  'menu.yst.paymentList': '付款流程',
  'menu.yst.payListYst': '付款列表',
  'menu.yst.collectMoneyListYst': '收款列表',
  'menu.yst.payPlatform': '支付平台',
  'menu.yst.settlementAccount': '结算账号'
}
