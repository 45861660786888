<template>
  <div>
    <div class=" m-b1">总金额&nbsp;&nbsp;<a>{{monthData[monthData.key]}}</a>&nbsp;&nbsp;元</div>
    <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" >
      <template #sort="{index}">
        {{ index+ 1 }}
      </template>
      <template #totalAmt="{ record}">
        <a @click="openDetails(record)">{{record.totalAmt }}</a>
      </template>
    </a-table>
    <a-modal :title="title" v-model:visible="visible" footer="" width="60%" destroyOnClose :bodyStyle="{padding: '10px',background:'#EEEEEE'}">
      <DetailsModal :detalisInfo="detalisInfo"></DetailsModal>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { receivableMonthTotal } from '@/api/businessCenter/businessCenter'
import DetailsModal from './detailsModal.vue'

const props = defineProps({
  monthData: {
    type: Object,
    default: {}
  },
})
const visible = ref(false)
const title = ref('')
const dataSource = ref([])
const  columns = ref([
  {
    title:'序号',
    dataIndex: 'sort',
    align: 'center',
    slots:{
    customRender: 'sort'
    }
  },
  {
    title:'分单员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title:'逾期月份',
    dataIndex: 'month',
    align: 'center',
  },
  {
    title:'金额',
    dataIndex: 'totalAmt',
    align: 'center',
    slots:{
    customRender: 'totalAmt'
    }
  }
])
const loading = ref(false)
const detalisInfo = ref({})

const openDetails = (record) => {
  visible.value = true
  title.value = props.monthData.title +'-'+ record.month
  detalisInfo.value = props.monthData
  detalisInfo.value.month = record.month
}
onMounted(() => {
  loading.value = true
  receivableMonthTotal({
    customerId: props.monthData.customerId,
    salesmanId: props.monthData.salesmanId,
    type: props.monthData.type
  }).then(res => {
    if (res.code !== 10000) return
    dataSource.value = res.data

  }).finally(() => {
    loading.value = false
  })
})

</script>

<style lang="less" scoped>
</style>