<template>
  <div class="follow-list">
    <div class="follow-div" v-show="followList.length > 0" v-for="item in followList" :key="item.orderVehicleId">
      <div class="follow-data" v-if="item.documentaryType?.value !== 1 ">
        <div class="detail">
          <div style="display:flex">
            <div class="label">跟单类型</div>
            <div class="data">{{ item.documentaryType?.label }}</div>
          </div>
          <div style="display:flex">
            <div class="label">创建时间</div>
            <div class="data"><span v-if="item.createTime">{{ item.createTime }}</span></div>
          </div>
        </div>
        <div class="detail">
          <div style="display:flex">
            <div class="label">{{ item.documentaryType?.value === 3 ? '质损' : '异常' }}时间</div>
            <div class="data" style="width:6vw">{{ item.documentaryTime }}</div>
          </div>
          <div style="display:flex">
            <div class="label">跟单人</div>
            <div class="data" style="width:6vw">{{ item.sponsor }}</div>
          </div>
        </div>
        <div class="detail">
          <div style="display:flex">
            <div class="label">情况描述</div>
            <div class="data" style="width:12vw" :title="item.content">{{ item.content }}</div>
          </div>
          <div style="display:flex">
            <div class="label">状态</div>
            <div class="data" style="width:12vw">{{ item.status?.label }}</div>
          </div>
        </div>
        <div class="detail">
          <div style="display:flex">
            <div class="label">图片</div>
            <div class="data" style="width:5vw">
              <a v-show="item.fileIds.length > 0" @click="lookImg(item)">查看</a>
              <span v-show="item.fileIds.length <= 0">暂无图片</span>
            </div>
          </div>
        </div>
        <div style="margin-left:-22px">
          <a-button v-show="!type" type="primary" @click="resultFollow(item)">结果跟进</a-button>
        </div>
      </div>
      <div class="follow-data" v-if="item.documentaryType?.value === 1">
        <div class="ordinary">
          <div class="detail">
            <div style="display:flex">
              <div class="label">跟单类型</div>
              <div class="data">{{ item.documentaryType?.label }}</div>
            </div>
            <div style="display:flex">
              <div class="label">创建时间</div>
              <div class="data"><span v-if="item.createTime">{{ item.createTime }}</span></div>
            </div>
          </div>
          <div class="detail">
            <div style="display:flex;">
              <div class="label">跟单内容</div>
              <div style="width:22.5vw;flex:1;word-break: break-all;" class="data">{{ item.content }}</div>
            </div>
            <div style="display:flex">
              <div class="label">跟单人</div>
              <div class="data" style="width:22.5vw">{{ item.sponsor }}</div>
            </div>
          </div>
          <div class="detail">
            <div style="display:flex">
              <div class="label">状态</div>
              <div class="data" style="width:5vw">{{ item.status?.label }}</div>
            </div>
          </div>
        </div>
        <div v-show="!type">
          <a-button type="primary" @click="resultFollow(item)">结果跟进</a-button>
        </div>
      </div>
      <div class="processing-results" v-show="item.processType">
        <div>
          <div style="margin-right:20px">结果跟进：{{ item.processType?.label }}</div>
          <div v-show="item.carrierName">质损承运商：{{ item.carrierName }}</div>
          <div v-show="item.carrierAmt">承运商承担金额：{{ item.carrierAmt }}元</div>
          <div v-show="item.companyAmt">公司承担金额：{{ item.companyAmt }}元</div>
          <div>备注：{{ item.processContent }}</div>
          <div>{{ item.handler }}&emsp;{{ item.processTime }}</div>
        </div>
      </div>
      <div v-show="item.processType" style="background-color: #f2f2f2;padding:10px"><a @click="lookDetail(item)">查看更多＞＞</a></div>
    </div>
    <a-pagination v-if="followList.length > 0" size="small" :total="followPage.total" @change="followPageChange" />
    <h2 v-show="followList.length <= 0" style="text-align:center">暂无跟单数据</h2>
  </div>
  <a-modal v-model:visible="followDetailShow" width="45%" title="结果跟进" footer="">
    <div class="follow-div">
      <div class="follow-data" v-if="followDetail.documentaryType?.value === 3 || followDetail.documentaryType?.value === 2">
        <div class="detail">
          <div style="display:flex">
            <div class="label">跟单类型</div>
            <div class="data">{{ followDetail.documentaryType?.label }}</div>
          </div>
          <div style="display:flex">
            <div class="label">创建时间</div>
            <div class="data"><span v-if="followDetail.createTime">{{ followDetail.createTime }}</span></div>
          </div>
        </div>
        <div class="detail">
          <div style="display:flex">
            <div class="label">{{ followDetail.documentaryType?.value === 3 ? '质损' : '异常' }}时间</div>
            <div class="data" style="width:5vw">{{ followDetail.documentaryTime }}</div>
          </div>
          <div style="display:flex">
            <div class="label">跟单人</div>
            <div class="data" style="width:5vw">{{ followDetail.sponsor }}</div>
          </div>
        </div>
        <div class="detail">
          <div style="display:flex">
            <div class="label">情况描述</div>
            <div class="data" style="width:6vw" :title="followDetail.content">{{ followDetail.content }}</div>
          </div>
          <div style="display:flex">
            <div class="label">状态</div>
            <div class="data" style="width:6vw">{{ followDetail.status?.label }}</div>
          </div>
        </div>
        <div class="detail">
          <div style="display:flex">
            <div class="label">图片</div>
            <div class="data" style="width:5vw">
              <a v-show="followDetail.fileIds.length > 0" @click="lookImg(followDetail)">查看</a>
              <span v-show="followDetail.fileIds.length <= 0">暂无图片</span>
            </div>
          </div>
        </div>
      </div>
      <div class="follow-data" v-if="followDetail.documentaryType?.value === 1">
        <div class="ordinary">
          <div class="detail">
            <div style="display:flex">
              <div class="label">跟单类型</div>
              <div class="data">{{ followDetail.documentaryType?.label }}</div>
            </div>
            <div style="display:flex">
              <div class="label">创建时间</div>
              <div class="data"><span v-if="followDetail.createTime">{{ followDetail.createTime || '' }}</span></div>
            </div>
          </div>
          <div class="detail">
            <div style="display:flex;">
              <div class="label">跟单内容</div>
              <div style="width:6vw;flex:1;word-break: break-all;" class="data">{{ followDetail.content }}</div>
            </div>
            <div style="display:flex">
              <div class="label">跟单人</div>
              <div class="data" style="width:6vw">{{ followDetail.sponsor }}</div>
            </div>
          </div>
          <div class="detail">
            <div style="display:flex">
              <div class="label">状态</div>
              <div class="data" style="width:5vw">{{ followDetail.status?.label }}</div>
            </div>
          </div>
        </div>
      </div>
      <div style="max-height: 40vh;overflow-y:scroll;overflow-x:hidden;">
        <div v-for="(item, index) in followDetailList" :key="index" class="data-list">
          <div style="margin-right:20px">结果跟进：{{ item.processType?.label }}</div>
          <div v-show="item.carrierName">质损承运商：{{ item.carrierName }}</div>
          <div v-show="item.carrierAmt">承运商承担金额：{{ item.carrierAmt }}元</div>
          <div v-show="item.companyAmt">公司承担金额：{{ item.companyAmt }}元</div>
          <div>备注：{{ item.processContent }}</div>
          <div>{{ item.handler }}&emsp;{{ item.processTime }}</div>
        </div>
      </div>
    </div>
  </a-modal>
  <!-- 确认结束跟进 -->
  <a-modal v-model:visible="endFollowShow" title="结果跟进" @ok="confirmEndFollow" @cancel="cancel">
    <div>
      <div class="glabel">处理结果</div>
      <a-form-item label="">
        <a-select v-model:value="resultFollowForm.processType" placeholder="请选择">
          <a-select-option :value="1" v-if="processType !== 9">未处理</a-select-option>
          <a-select-option :value="2" v-if="processType !== 9">处理中</a-select-option>
          <a-select-option :value="3" v-if="processType !== 9">已处理</a-select-option>
          <a-select-option :value="9" v-if="processType !== 9">完结</a-select-option>
          <a-select-option :value="4">评论</a-select-option>
        </a-select>
      </a-form-item>
      <template v-if="permission&&documentaryType === 3&&resultFollowForm.processType === 2 || permission&&documentaryType === 3&&resultFollowForm.processType === 3">
        <div class="glabel">质损承运商</div>
        <a-form-item label="">
          <a-select v-model:value="resultFollowForm.carrierId" :filter-option="false" :show-arrow="false" @change="celCarrier"  @search="carrierAutoComplete" show-search placeholder="请搜索">
            <template v-if="loading" #notFoundContent>
              <div class=" flex ju-center"><a-spin size="small" /></div>
            </template>
            <a-select-option v-for="item in carriers" :key="item.id" :lable="item.name">
              <span>{{ item.name }}-{{item.mobile}}</span>
              <span style="float:right;">{{ item.taxpayerIdentificationNum }}</span>
            </a-select-option>
          </a-select>
        </a-form-item>
        <div class="glabel">承运商承担金额</div>
        <a-form-item label="">
          <a-input-number style="width:100%" v-model:value="resultFollowForm.carrierAmt" placeholder="填写">
          </a-input-number>
        </a-form-item>
        <div class="glabel">公司承担金额</div>
        <a-form-item label="">
          <a-input-number style="width:100%" v-model:value="resultFollowForm.companyAmt" placeholder="填写">
          </a-input-number>
        </a-form-item>
      </template>
      <div class="glabel">备注</div>
      <a-form-item label="">
        <a-textarea v-model:value="resultFollowForm.content" placeholder="填写" :rows="4" />
      </a-form-item>
    </div>
  </a-modal>
  <!-- 图片查看 -->
  <a-modal v-model:visible="imgsShow" width="40%" title="图片查看" footer="" :zIndex="99999999999999999999999" @cancel="imgsShow = false, imgsList = []">
    <div>
      <a-image-preview-group>
        <a-image :width="200" v-for="item in imgsList" :key="item.id" :src="item.url" />
      </a-image-preview-group>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'
import { message } from 'ant-design-vue'
import { documentaryPage, follow, addFollow, followList, resultFollow as resultFollowAPI, followDetailList, abnormalImgs } from '@/api/transport/documentary'
import { debounce } from "@/utils/util.js";
import { fuzzyAll } from '@/api/crm/carrer/carrier'
export default {
  props: { type: Number ,permission:{
    type:Number,
    default: 0
  }},
  setup (props,{emit}) {
    const state = reactive({
      loading:false,
      content: null,
      timer: null,
      documentaryType: null,
      endFollowShow: false,
      imgsShow: false,
      followDetailShow: false,
      type: undefined,
      orderVehicleId: '',
      followList: [],
      followDetail: {},
      imgsList: [],
      followDetailList: [],
      processType: null,
      resultFollowForm: {
        content: '',
        documentaryId: '',
        carrierId:null,
        processType: null,
        carrierName: null,
        carrierAmt: null,
        companyAmt: null
      },
      followPage: {
        current: 1,
        total: 0,
        pageSize: 5
      },
    })
    const carriers = ref([])
    const celCarrier = (val,e) => {
      state.resultFollowForm.carrierName = e.lable
    }
    const carrierAutoComplete = val => {
      state.content = val
      state.loading = true
      debounce(state, reqFuzzyAll, 1000)
    }
    const reqFuzzyAll = () => {
      fuzzyAll({ name: state.content, type: 2 }).then(res => {
        if (res.code === 10000) {
          carriers.value = res.data
        } else {
          carriers.value = []
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const cancel = () => {
      for (let key in state.resultFollowForm) {
        state.resultFollowForm[key] = null
      }
      carriers.value = []
      state.endFollowShow = false
    }
    const loadData = (orderVehicleId) => {
      state.orderVehicleId = orderVehicleId
      followList(orderVehicleId, {
        current: state.followPage.current,
        size: 10
      }).then(res => {
        if (res.code === 10000) {
          state.followList = res.data.records
          state.followPage.total = res.data.total
        }
      })
    }
    const lookImg = item => {
      if (item.fileIds.length > 0) {
        state.imgsShow = true
        abnormalImgs({
          files: item.fileIds
        }).then(res => {
          if (res.code === 10000) {
            state.imgsList = res.data
          }
        })
      } else message.info('该数据没有图片')
    }
    const resetData = () => {
      state.followPage.current = 1
    }
    const resultFollow = (item) => {
      state.resultFollowForm.documentaryId = item.documentaryId
      if (item.processType) state.processType = item.processType.value
      state.documentaryType = item.documentaryType.value
      state.endFollowShow = true
    }
    const followPageChange = e => {
      state.followPage.current = e
      loadData(state.orderVehicleId)
    }
    const confirmEndFollow = () => {
      if (state.resultFollowForm.processType !== null) {
        resultFollowAPI({
          ...state.resultFollowForm
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            loadData(state.orderVehicleId)
            emit('success')
            cancel()
          }
        })
      } else message.error('请选择处理结果')
    }
    const lookDetail = item => {
      state.followDetailShow = true
      state.followDetail = item
      followDetailList(item.documentaryId).then(res => {
        if (res.code === 10000) {
          state.followDetailList = res.data
        }
      })
    }
    return {
      ...toRefs(state),
      lookImg,
      loadData,
      resetData,
      lookDetail,
      resultFollow,
      followPageChange,
      confirmEndFollow,
      cancel,
      carrierAutoComplete,
      carriers,
      celCarrier
    }
  }
}
</script>

<style lang="less" scoped>
.follow-div {
  margin-bottom: 10px;

  .follow-data {
    .detail {
      flex: 1;

      .label {
        width: 4vw;
        color: #6b778c;
      }

      .data {
        width: 10vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #344563;
      }
    }

    .ordinary {
      flex: 1;
      display: flex;
      justify-content: space-between;
    }

    padding: 10px;
    display: flex;
    justify-content: space-between;
    background-color: #f2f2f2;
  }

  .processing-results {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: 12px;
    color: #344563;
    background-color: #d9e5f1;
  }
}

.data-list {
  margin-bottom: 10px;
  padding: 10px;
  color: #344563;
  background-color: #d9e5f1;
}
.glabel {
  margin-bottom: 4px;
}
</style>