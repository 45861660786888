// 收款对象
export const recipientOfPay = [
  {
    label: '承运商收款',
    value: 1
  },
  {
    label: '业务员收款',
    value: 3
  },
]
//费用类型
export const expenseOptions = [
  {
    label: '放空费',
    value: 8
  },
  {
    label: '油费',
    value: 9
  },
  {
    label: '等时费',
    value: 10
  },
  {
    label: '维修费',
    value: 11
  },
  {
    label: '叉车费',
    value: 12
  },
  {
    label: '停车费',
    value: 13
  },
  {
    label: '保管费',
    value: 14
  },
  {
    label: '异常处理费',
    value: 15
  },
  {
    label: '洗车费',
    value: 16
  }
]
export const colorType = {
  0:'#15ad31',
  1:'#7948ea',
  2:'#f47920',
  3:'#d20509'
}