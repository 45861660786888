<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle">共&nbsp;<a>{{ data.length }}</a>&nbsp;条数据</div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #paidNormalAmt="{ record }">
          <a @click="openDetails(record,1,'paidNormalAmt')">{{ record.paidNormalAmt }}</a>
        </template>
        <template #paidOverdueAmt="{ record }">
          <a @click="openDetails(record,2,'paidOverdueAmt')">{{ record.paidOverdueAmt }}</a>
        </template>
        <template #monthNormalAmt="{ record }">
          <a @click="openDetails(record,3,'monthNormalAmt')">{{ record.monthNormalAmt }}</a>
        </template>
        <template #monthOverdueAmt="{ record }">
          <a @click="openDetails(record,4,'monthOverdueAmt')">{{ record.monthOverdueAmt }}</a>
        </template>
        <template #totalAmt="{ record }">
          <a @click="openDetails(record,0,'totalAmt')">{{ record.totalAmt }}</a>
        </template>
        <template #receiveContent="{ record }">
          <div class="flex al-center">
            <div style="width: 90%;">
              <span class="m-r1" :style="{color:record.receiveLevel?.value === 1 ? '#15AD31':'#DB2107'}">{{ record.receiveLevel?.label }}</span>
              {{ record.receiveContent }}
            </div>
            <div>
              <div> <a @click="newAdd(record)">添加</a></div>
              <a v-if="record.receiveId" @click="recordLook(record)">记录</a>
            </div>
          </div>
        </template>
      </a-table>
    </div>
    <a-modal v-model:visible="visible" title="回款描述" @cancel="value = null" @ok="submit">
      <div class="m-b1">回款情况<span style="color:red">*</span></div>
      <a-select style="width: 100%" v-model:value="receiveLevel" placeholder="请选择">
        <a-select-option :value="1">正常</a-select-option>
        <a-select-option :value="2">困难</a-select-option>
      </a-select>
      <div class="m-t2 m-b1">原因<span style="color:red">*</span></div>
      <a-textarea v-model:value="value" placeholder="请输入"></a-textarea>
    </a-modal>
    <a-modal title="回款描述记录" v-model:visible="recordLookVisible" footer="" @cancel="receiveData = []">
      <div class="recordLookBox">
        <div v-for="item in receiveData" :key="item.id" class="m-b1">
          <a-card>
            <div>回款情况：<span>{{item.receiveLevel?.label}}</span></div>
            <div>添加人：<span>{{item.empName}}</span></div>
            <div>添加时间：<span>{{item.createTime}}</span></div>
            <div>添加内容：<span>{{ item.content }}</span></div>
          </a-card>
        </div>
      </div>
    </a-modal>
    <!-- 月份列表 -->
    <a-modal v-model:visible="monthVisible" :title="title" footer="" destroyOnClose>
      <MonthModal :monthData="monthData"></MonthModal>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import moment from 'moment'
import { receivableTotal, planAdd, receivePlanId } from '@/api/businessCenter/businessCenter'
import { transitionTimeYM, getTableBody, IfNull } from '@/utils/util'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import MonthModal from './monthModal'
const store = useStore()

const monthVisible = ref(false)
const loading = ref(false)
const dataSource = ref([])
const data = ref([])
const pageSize = ref(50)
const tableRef = ref(null)
const visible = ref(false)
const value = ref(null)
const receiveLevel = ref(null)
const msg = ref({})
const searchMsg = ref({})
const recordLookVisible = ref(false)
const receiveData = ref([])
const monthData = ref({})
const title = ref('')

const arr = {
  0: '合计',
  1: '到收-正常',
  2: '到收-逾期',
  3: '记账/月结-正常',
  4: '记账/月结-逾期'
}
const openDetails = (record, type, key) => {
  if(record[key] == 0) return
  title.value = `应收看板-${arr[type]}-` + record.salesman
  monthVisible.value = true
  record.type = type
  record.key = key
  record.title = title.value
  monthData.value = record
}
const recordLook = (record) => {
  recordLookVisible.value = true
  receivePlanId(record.receiveId).then(res => {
    if (res.code !== 10000) return
    receiveData.value = res.data
  })
}
const submit = () => {
  if(!receiveLevel.value){
    message.error('请选择回款情况')
    return
  }
  if(!value.value){
    message.error('请输入原因')
    return
  }
  planAdd({
    content: value.value,
    receiveLevel:receiveLevel.value,
    customerId: msg.value.customerId,
    empId: msg.value.salesmanId
  }).then(res => {
    if (res.code !== 10000) return
    message.success('添加成功')
    let info = receiveLevel.value === 1 ? {value:1,label:'正常'} : {value:2,label:'困难'}
    visible.value = false
    dataSource.value.forEach(item => {
      if (item.customerId === msg.value.customerId && item.salesmanId === msg.value.salesmanId) {
        item.receiveContent = value.value
        item.receiveLevel = info
      }
    })
    value.value = null
    receiveLevel.value = null
  })
}
const newAdd = (record) => {
  msg.value = record
  visible.value = true
}
const searchAdd = (e) => {
  pageSize.value = 50
  searchMsg.value = e
  getReceivableTotal()

}
const resetBtn = () => {
  pageSize.value = 50
  searchMsg.value = {}
  getReceivableTotal()
}
const getReceivableTotal = () => {
  loading.value = true
  receivableTotal({
    ...searchMsg.value
  }).then(res => {
    if (res.code !== 10000) return
    data.value = res.data
    dataSource.value = res.data.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)

}
onMounted(() => {
  getReceivableTotal()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})
const iptData = ref([
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "分单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "select",
    placeholder: "结算方式",
    value: null,
    prop: "orderSettlement",
    key: 'value',
    keyVal: 'label',
    width: 200,
    opt: store.state.enumAll.orderSettlementList
  },
  {
    type: "select",
    placeholder: "是否添加回款记录",
    value: null,
    prop: "isAdd",
    key: 'value',
    keyVal: 'label',
    width: 200,
    opt: [
      { label: '是', value: 1 },
      { label: '否', value: 0 }
    ]
  },
  {
    type: "select",
    placeholder: "回款情况",
    value: null,
    prop: "receiveLevel",
    key: 'value',
    keyVal: 'label',
    width: 200,
    opt: [
      { label: '正常', value: 1 },
      { label: '困难', value: 2 }
    ]
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
  },
  {
    title: '分单员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '到收',
    align: 'center',
    children: [
      {
        title: '正常',
        dataIndex: 'paidNormalAmt',
        slots: {
          customRender: 'paidNormalAmt'
        },
        align: 'center',
      },
      {
        title: '逾期',
        dataIndex: 'paidOverdueAmt',
        slots: {
          customRender: 'paidOverdueAmt'
        },
        align: 'center',
      }
    ]
  },
  {
    title: '记账/月结',
    align: 'center',
    children: [
      {
        title: '正常',
        dataIndex: 'monthNormalAmt',
        slots: {
          customRender: 'monthNormalAmt'
        },
        align: 'center',
      },
      {
        title: '逾期',
        dataIndex: 'monthOverdueAmt',
        slots: {
          customRender: 'monthOverdueAmt'
        },
        align: 'center',
      }
    ]
  },
  {
    title: '合计',
    dataIndex: 'totalAmt',
    slots: {
      customRender: 'totalAmt'
    },
    align: 'center',
  },
  {
    title: '回款描述',
    dataIndex: 'receiveContent',
    align: 'center',
    slots: {
      customRender: 'receiveContent'
    },
    width: 400
  },
])
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
.recordLookBox {
  max-height: 600px;
  overflow: auto;
  span {
    color: #666;
  }
}
</style>