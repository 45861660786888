<template>
  <div>
    <div style="display:flex;justify-content:space-between;margin:10px 0">
      <a-form layout="inline">
        <a-form-item label="">
          <a-input v-model:value="searchForm.lineName" placeholder="线路名称" />
        </a-form-item>
        <a-form-item label="">
          <a-select allowClear v-model:value="searchForm.businessType" style="width: 180px"
            :options="$store.state.enumAll.businessType" placeholder="业务类型" />
        </a-form-item>
        <!--        <a-form-item label="运输类型">-->
        <!--          <a-select allowClear v-model:value="searchForm.transportType"-->
        <!--                    style="width: 180px"-->
        <!--                    :options="$store.state.enumAll.transportType" placeholder="请选择运输类型"/>-->
        <!--        </a-form-item>-->
        <!--        <a-form-item label="取送类型">-->
        <!--          <a-select allowClear v-model:value="searchForm.isDelivery"-->
        <!--                    style="width: 180px"-->
        <!--                    :options="DeliveryEnum" placeholder="请选择取送类型"/>-->
        <!--        </a-form-item>-->
        <a-form-item label="">
          <a-cascader v-model:value="searchForm.startAddress" :options="$store.state.app.cityDict"
            :show-search="{ filter }" :changeOnSelect="true" @change="setSearchFormStartAddress" placeholder="起点" />

        </a-form-item>
        <a-form-item label="">
          <a-cascader v-model:value="searchForm.endAddress" :options="$store.state.app.cityDict"
            :show-search="{ filter }" :changeOnSelect="true" @change="setSearchFormEndAddress" placeholder="终点" />
        </a-form-item>

        <a-form-item>
          <a-button :loading="loading" type="primary" @click="search">搜索</a-button>
        </a-form-item>
      </a-form>
      <a-button @click="onLineClick(1, '')">+新增线路</a-button>
    </div>
    <a-table size="small" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered
      :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #operation="{ record }">
        <a @click="onLineClick(2, record.id)">查看</a>
        <a-divider type="vertical" />
        <a @click="onLineClick(3, record.id)">编辑</a>
        <a-divider type="vertical" />
        <!--        <a-popconfirm title="确认禁用?" ok-text="确认" cancel-text="取消" @confirm="deleteData(record.id)">-->
        <!--          <a style="color:#A30014">禁用</a>-->
        <!--        </a-popconfirm>-->

      </template>

      <template #passPoint="{ record }">
        <span v-for="(item, index) in record.passPoint" :key="index">
          {{ item.label }} <span v-if="index < record.passPoint.length - 1">， </span>
        </span>
      </template>

      <template #cost="{ record }">
        <span v-if="record.lineOffer && record.lineOffer.value === 2">起步:{{ record.startingPrice }}元 超出{{
            record.exceedingKm
          }}公里,每公里:{{ record.perKmPrice }}元</span>
        <span v-else>{{ record.cost }}</span>
      </template>

      <template #optimal="{ record }">
        <a-popconfirm :title="`你确定选择${record.isOptimal ? '否' : '是'}最优线路？`" @confirm="adjustment(record)">
          <a-switch v-model:checked="record.isOptimal" checkedChildren="是" unCheckedChildren="否"
            @click="record.isOptimal = !record.isOptimal" />
        </a-popconfirm>
      </template>
    </a-table>

    <a-modal v-model:visible="addShow" footer="" width="600px" :destroyOnClose="true">

      <template #title>
        <span style="font-size:20px">{{ lineNameType }}线路</span>
      </template>
      <a-form layout="vertical" ref="formRef" :model="addForm" :rules="rules">
        <a-form-item label="业务类型" :name="['businessType', 'value']">
          <a-select @change="onClearAddress" :disabled="lineTypeState" v-model:value="addForm.businessType.value"
            style="width:552px" :options="$store.state.enumAll.businessType" placeholder="请选择业务类型" />
        </a-form-item>
        <a-form-item label="线路类型" v-if="addForm.businessType.value !== 3" :name="['lineType', 'value']">
          <a-select @change="onLineTypeAddress" :disabled="disabled" v-model:value="addForm.lineType.value"
            :options="$store.state.enumAll.routeType" placeholder="请选择线路类型" />
        </a-form-item>
        <!--        <template v-if="addForm.businessType.value===1">-->
        <!--          <a-form-item label="途经点" v-if="!disabled">-->
        <!--            <a-tag closable v-for="(item,index) in routeList" @close="deleteRoute(item,index)" :key="index">-->
        <!--              <span v-if="item!={}"> {{ item.label }}</span>-->
        <!--              <template #closeIcon>-->
        <!--                <DeleteOutlined style="font-size:14px"/>-->
        <!--              </template>-->
        <!--            </a-tag>-->
        <!--            <a-cascader v-if="inputShow&&lineClickType!=2 " :style="{ width: '78px',height:'22px' }" size="small"-->
        <!--                        v-model:value="addTag" :options="$store.state.app.OnlyCityList" :show-search="{ filter }"-->
        <!--                        :changeOnSelect="true" @change="addVia" placeholder="请选择途经点" style="width:48%"/>-->
        <!--            &lt;!&ndash; <a-input  ref="inputRef" type="text" :style="{ width: '78px',height:'22px' }"  v-model:value="addTag" @blur="addVia" @keyup.enter="addVia" /> &ndash;&gt;-->
        <!--            <a-tag v-show="!inputShow" v-if="lineClickType!=2 " @click="showInput"-->
        <!--                   style="background: #fff; border-style: dashed">-->
        <!--              <plus-outlined/>-->
        <!--              添加-->
        <!--            </a-tag>-->
        <!--          </a-form-item>-->
        <!--          <a-form-item label="途经点" v-else>-->
        <!--            <a-tag v-for="(item,index) in routeList" @close="deleteRoute(item,index)" :key="index">-->
        <!--              <span v-if="item!={}"> {{ item.label }}</span>-->
        <!--            </a-tag>-->
        <!--          </a-form-item>-->
        <!--        </template>-->

        <a-form-item label="运输类型" v-if="addForm.businessType.value === 3" :name="['transportType', 'value']">
          <a-select :disabled="disabled" v-model:value="addForm.transportType.value"
            :options="$store.state.enumAll.transportType" placeholder="请选择运输类型" />
        </a-form-item>
        <a-form-item label="取送类型" v-if="addForm.businessType.value === 3" :name="['isDelivery', 'value']">
          <a-select :disabled="disabled" v-model:value="addForm.isDelivery.value" :options="DeliveryEnum"
            placeholder="请选择取送类型" />
        </a-form-item>
        <a-form-item label="价格类型" v-if="addForm.businessType.value === 3">
          <a-select :disabled="disabled" v-model:value="addForm.lineOffer.value" @change="onLineOffer"
            :options="$store.state.enumAll.lineOfferType" placeholder="请选择价格类型" />
        </a-form-item>
        <a-form-item label="" v-if="addForm.businessType.value === 3 && addForm.lineOffer.value === 1" name="cost">
          <a-input :disabled="disabled" v-if="addForm.lineOffer.value === 1" v-model:value="addForm.cost" type="number"
            :min="1" placeholder="请填写一口价" />
        </a-form-item>
        <a-form-item label="" v-if="addForm.businessType.value === 3 && addForm.lineOffer.value === 2">
          <div class="startingPrice">
            <a-input :disabled="disabled" class="input-startingPrice" v-model:value="addForm.startingPrice"
              type="number" :min="1" placeholder="" />
            起步，超出
            <a-input :disabled="disabled" class="input-startingPrice" v-model:value="addForm.exceedingKm" type="number"
              :min="1" placeholder="" />
            公里，每公里
            <a-input :disabled="disabled" class="input-startingPrice" v-model:value="addForm.perKmPrice" type="number"
              :min="1" placeholder="" />
            元
          </div>
        </a-form-item>
        <a-form-item>

          <template #label><span style="color:#f00">*&nbsp;</span>线路名称</template>
          <div style="display:flex;justify-content: space-between;">
            <a-cascader v-if="addForm.businessType.value != 3" :disabled="lineTypeState" v-model:value="startAddress"
              :options="$store.state.app.cityTypeDict" :show-search="{ filter }" :changeOnSelect="true"
              @change="setStartAddress" placeholder="请选择起点" style="width:48%" />
            <a-cascader v-else :disabled="lineTypeState" v-model:value="startAddress"
              :options="$store.state.app.cityTypeDict" :show-search="{ filter }" :changeOnSelect="true"
              @change="setStartAddress" placeholder="请选择起点" style="width:552px" />
            <a-cascader :disabled="lineTypeState" v-if="addForm.businessType.value != 3" v-model:value="endAddress"
              :options="$store.state.app.cityTypeDict" :show-search="{ filter }" :changeOnSelect="true"
              @change="setEndAddress" placeholder="请选择终点" style="width:48%" />
          </div>
        </a-form-item>
        <a-form-item label="" v-if="addForm.businessType.value === 3 && addForm.lineOffer.value > 0">
          <a-checkbox-group v-model:value="value">
            <a-row :gutter="[24, 6]">
              <a-col :span="8" v-for="(item, index) in startingAddress" :key="index">
                <view style="display: flex;">
                  <a-checkbox :disabled="disabled" :value="index">{{ item.label }}</a-checkbox>
                  <a-input :disabled="disabled" v-if="value.indexOf(index) != -1 && addForm.lineOffer.value === 1"
                    style=" width: 40%;height: 26px" size="small" compact v-model:value="item.cost" type="number"
                    :min="1" placeholder="请填写一口价" />
                </view>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="是否设为最优线路">
          <a-select :disabled="disabled" v-model:value="addForm.isOptimal.value"
            :options="$store.state.enumAll.BooleanFlagEnum" placeholder="请选择是否设为最优线路" />
        </a-form-item>
        <a-form-item label="是否设为固化线路">
          <a-select :disabled="disabled" v-model:value="addForm.isFixedLine.value"
            :options="$store.state.enumAll.BooleanFlagEnum" placeholder="请选择是否设为最优线路" />
        </a-form-item>
        <a-form-item label="起点城市区"
          v-if="addForm.startArea === null && (addForm.businessType.value === 1 || addForm.businessType.value === 2) && (addForm.lineType.value === 3 || addForm.lineType.value === 4)">
          <!--          <a-checkbox-group v-model:value="startingStoreValue">-->
          <a-row :gutter="[24, 6]">
            <a-col :span="8" v-for="(item, index) in startingStoreAddress" :key="index">
              <view style="display: flex;">
                <!--                    <a-checkbox :disabled="disabled" :value="index">{{ item.label }}</a-checkbox>-->
                {{ item.label }}
                <a-input :disabled="disabled" style=" width: 40%;" size="small" compact v-model:value="item.cost"
                  type="number" :min="1" placeholder="请填写价格" />
              </view>
              <!--                <a-checkbox :disabled="disabled" :value="index">{{ item.label }}</a-checkbox>-->
            </a-col>

          </a-row>
          <!--          </a-checkbox-group>-->
        </a-form-item>
        <a-form-item label="地跑价格" v-show="addForm.businessType?.value === 1 || addForm.businessType?.value === 2">
          <a-input :disabled="disabled" style=" width: 100%;" size="small" compact v-model:value="addForm.groundCost"
            type="number" :min="1" placeholder="请填写价格" />
        </a-form-item>
        <a-form-item label="终点城市区"
          v-if="addForm.endArea === null && (addForm.businessType.value === 1 || addForm.businessType.value === 2) && (addForm.lineType.value === 2 || addForm.lineType.value === 4)">
          <a-checkbox-group v-model:value="endStoreValue">
            <a-row :gutter="[24, 6]">
              <a-col :span="8" v-for="(item, index) in endStoreAddress" :key="index">
                <!--                <a-checkbox :disabled="disabled" :value="index">{{ item.label }}</a-checkbox>-->
                {{ item.label }}
                <a-input :disabled="disabled" style=" width: 40%;" size="small" compact v-model:value="item.cost"
                  type="number" :min="1" placeholder="请填写价格" />
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="报价" name="cost" v-if="addForm.businessType.value != 3">
          <a-input :disabled="disabled" v-model:value="addForm.cost" type="number" :min="1" placeholder="请填写报价" />
        </a-form-item>
        <a-form-item label="公里数" name="km" v-if="addForm.businessType.value != 3">
          <a-input :disabled="disabled" v-model:value="addForm.km" type="number" :min="1" placeholder="请填写公里" />
        </a-form-item>
        <a-form-item label="时效" name="prescription">
          <a-input :disabled="disabled" v-model:value="addForm.prescription" type="number" :min="1"
            placeholder="请填写时效" />
        </a-form-item>
        <a-form-item label="线路标签">
          <a-select :disabled="disabled" v-model:value="addForm.lineLabelName" @change="onLineLabel"
            :options="$store.state.enumAll.Label" placeholder="请选择线路标签" />
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea :disabled="disabled" v-model:value="addForm.remark" />
        </a-form-item>
      </a-form>
      <div style="display:flex;justify-content: flex-end;margin-top:15px">
        <a-button size="large" style="margin-right:15px" @click="addShow = false">取消</a-button>
        <a-button v-if="lineClickType != 2" size="large" class="searchLoading-button" @click="addData">
          {{ lineNameButtonType }}
        </a-button>
      </div>
    </a-modal>

    <a-modal v-model:visible="seeShow" footer="" width="600px" :destroyOnClose="true">

      <template #title>
        <span style="font-size:20px">{{ lineNameType }}线路</span>
      </template>
      <a-row :gutter="[24, 12]">
        <a-col :span="12">
          <span class="seeTitle">业务类型：</span>
          <span class="seeContent" v-if="addForm.businessType !== null"> {{ addForm.businessType.label }}</span>
        </a-col>
        <a-col :span="12">
          <span class="seeTitle">线路类型：</span>
          <span class="seeContent" v-if="addForm.lineType !== null"> {{ addForm.lineType.label }}</span>
        </a-col>
        <a-col :span="12">
          <span class="seeTitle">运输类型：</span>
          <span class="seeContent" v-if="addForm.transportType !== null"> {{ addForm.transportType.label }}</span>
        </a-col>
        <a-col :span="12">
          <span class="seeTitle"> 取送类型： </span>
          <span class="seeContent" v-if="addForm.isDelivery !== null"> {{ addForm.isDelivery.label }}</span>
        </a-col>
        <a-col :span="24">
          <span class="seeTitle"> 价格类型：</span>
          <span class="seeContent" v-if="addForm.lineOffer !== null"> {{ addForm.lineOffer.label }}
            <span class="seeContent" v-if="addForm.lineOffer.value === 1">{{ addForm.cost }} 元</span>
            <span class=" seeContent" v-if="addForm.lineOffer.value === 2">
              {{ addForm.startingPrice }}
              元起步，超出
              {{ addForm.exceedingKm }}
              公里，每公里
              {{ addForm.perKmPrice }}
              元
            </span>
          </span>
        </a-col>
        <a-col :span="24">
          <span class="seeTitle">线路名称：</span>
          <span class="seeContent"> {{ addForm.startProvinceName }}{{ addForm.startCityName }}{{ addForm.startAreaName
            }}
            —
            {{ addForm.endProvinceName }}{{ addForm.endCityName }}{{ addForm.endAreaName }}
          </span>
        </a-col>
        <a-col :span="24" v-if="addForm.businessType.value === 3 && addForm.lineOffer.value > 0">
          <a-row :gutter="[24, 12]" class="seeContent seeContentB">
            <a-col v-show="value.indexOf(index) !== -1" :span="8" v-for="(item, index) in startingAddress" :key="index">
              <view style="display: flex;">
                {{ item.label }}
                <span v-if="addForm.lineOffer.value === 1"> {{ item.cost }}</span>
              </view>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="12">
          <span class="seeTitle">最优线路：</span>
          <span class="seeContent" v-if="addForm.isOptimal !== null">{{ addForm.isOptimal.label }}</span>
        </a-col>
        <a-col :span="12">
          <span class="seeTitle">固化线路：</span>
          <span class="seeContent" v-if="addForm.isFixedLine !== null">{{ addForm.isFixedLine.label }}</span>
        </a-col>
        <a-col
          v-if="addForm.startArea === null && (addForm.businessType.value === 1 || addForm.businessType.value === 2) && (addForm.lineType.value === 3 || addForm.lineType.value === 4)"
          :span="12">
          起点城市区：
          <a-row :gutter="[24, 6]" class="seeContent seeContentB">
            <a-col :span="8" v-for="(item, index) in startingStoreAddress" :key="index">
              <view style="display: flex;">
                {{ item.label }}
                {{ item.cost }}
              </view>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="12"
          v-if="addForm.endArea === null && (addForm.businessType.value === 1 || addForm.businessType.value === 2) && (addForm.lineType.value === 2 || addForm.lineType.value === 4)">
          终点城市区：
          <a-row :gutter="[24, 6]" class="seeContent seeContentB">
            <a-col :span="8" v-for="(item, index) in endStoreAddress" :key="index">
              {{ item.label }}
              {{ item.cost }}
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="12">

          <span class="seeTitle"> 报价： </span>
          <span class="seeContent">{{ addForm.cost }}元</span>
        </a-col>
        <a-col :span="12">
          <span class="seeTitle">公里数： </span>
          <span class="seeContent">{{ addForm.km }} 公里</span>
        </a-col>
        <a-col :span="12">
          <span class="seeTitle">时效：</span>
          <span class="seeContent"> {{ addForm.prescription }}天</span>
        </a-col>
        <a-col :span="12">
          <span class="seeTitle">线路标签：</span>
          <span class="seeContent"> {{ addForm.lineLabelName }}</span>
        </a-col>
        <a-col :span="12">
          <span class="seeTitle">备注：</span>
          <span class="seeContent">{{ addForm.remark }}</span>
        </a-col>
      </a-row>
      <!--      <div style="display:flex;justify-content: flex-end;margin-top:15px">-->
      <!--        <a-button size="large" style="margin-right:15px" @click="seeShow=false">取消</a-button>-->
      <!--      </div>-->
    </a-modal>

  </div>
</template>

<script>
import { onMounted, reactive, toRefs, ref, nextTick } from 'vue'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'
import { NumberContent } from '@/utils/validate'
import { page, add, delet, detail, edit, optimal } from '@/api/carrier/line'


export default {
  props: {
    carrierId: String
  },
  components: {
    DeleteOutlined,
    PlusOutlined
  },

  setup: function (props, context) {
    const formRef = ref()
    const inputRef = ref()
    const store = useStore()
    const state = reactive({
      lineTypeState: true,
      seeShow: false,
      searchForm: {},
      lineNameType: '新增',
      lineNameButtonType: '新增',
      lineClickType: 1,
      value: [],
      startingStoreValue: [],
      endStoreValue: [],
      startingAddress: [],
      startingStoreAddress: [],
      endStoreAddress: [],
      cityList: store.state.app.cityTypeDict,
      addTag: '',
      loading: false,
      disabled: false,
      addShow: false,
      inputShow: false,
      listData: [],
      routeList: [],
      startAddress: [],
      endAddress: [],
      addForm: {
        isOptimal: {
          value: 0
        },
        isFixedLine: {
          value: 0
        },

        cost: '',
        remark: '',
        groundCost: '',
        prescription: '',
        startAddress: {},
        endAddress: {},
        businessType: { value: 0 },
        lineOffer: {},
        transportType: {},
        isDelivery: {},
        lineType: {}
      },
      rules: {
        businessType: {
          value: {
            required: true,
            message: '请选择业务类型',
            trigger: 'blur',
            type: 'number'
          }
        },
        lineType: {
          value: {
            required: true,
            message: '请选择线路类型',
            trigger: 'blur',
            type: 'number'
          }
        },
        isDelivery: {
          value: {
            required: true,
            message: '请选择取送类型',
            trigger: 'blur',
            type: 'number'
          }
        },
        transportType: {
          value: {
            required: true,
            message: '请选择运输类型',
            trigger: 'blur',
            type: 'number'
          }
        },
        cost: {
          required: true,
          message: '请填写一口价',
          trigger: 'blur'
        },
        km: [{ required: true, message: '请填写公里数', trigger: 'blur' },
        { pattern: NumberContent, message: '公里数不能为0或小数', trigger: 'blur' }
        ],
        startingPrice: {
          required: true,
          message: '请填写公式价',
          trigger: 'blur'
        },
        prescription: {
          required: true,
          message: '请填写时效',
          trigger: 'blur'
        }
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      columns: [
        {
          title: '线路名称',
          dataIndex: 'lineName',
          align: 'center'
        },
        {
          title: '业务类型',
          dataIndex: 'businessType.label',
          align: 'center'
        },
        {
          title: '线路类型',
          dataIndex: 'lineType.label',
          align: 'center'
        },
        // {
        //   title: '途经点',
        //   dataIndex: 'passPoint',
        //   align: 'center',
        //   slots: {
        //     customRender: 'passPoint'
        //   }
        // },
        {
          title: '报价',
          dataIndex: 'cost',
          slots: {
            customRender: 'cost'
          }
        },
        {
          title: '时效',
          dataIndex: 'prescription',
          align: 'center'
        },
        {
          title: '是否最优线路',
          dataIndex: 'optimal',
          align: 'center',
          slots: {
            customRender: 'optimal'
          }
        },
        {
          title: '运输类型',
          dataIndex: 'transportType.label',
          align: 'center'
        },
        {
          title: '取送类型',
          dataIndex: 'isDelivery.label',
          align: 'center'
        },
        {
          title: '区域',
          dataIndex: 'areaNames',
          align: 'right',
          width: '15%'
        },
        {
          title: '线路标签',
          dataIndex: 'lineLabelName',
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          align: 'center'
        },
        {
          title: '',
          dataIndex: 'operation',
          align: 'center',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      DeliveryEnum: [
        {
          label: '取车',
          value: 2
        },
        {
          label: '送车',
          value: 1
        }]
    })
    // 表格初始化
    const loadData = () => {
      state.loading = true
      page(
        props.carrierId,
        {
          current: state.pagination.current,
          size: state.pagination.pageSize,
          ...state.searchForm
        }).then(res => {
          if (res.code === 10000) {
            state.listData = res.data.records
            state.pagination.current = res.data.current
            state.pagination.pageSize = res.data.size
            state.pagination.total = res.data.total
          }
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          state.loading = false
        })
    }
    onMounted(() => {
      loadData()
    })
    // 翻页
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // 线路删除
    const deleteData = record => {
      delet(record).then(res => {
        if (res.code === 10000) {
          message.info(res.data)
          loadData()
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
      })
      console.log('删除', record)
    }

    // 新增事件
    const setStartAddress = (value, selectedOptions) => {
      state.addForm.startProvince = value[0]
      state.addForm.startProvinceName = selectedOptions[0].label
      state.value = []
      if (selectedOptions.length > 1) {
        state.addForm.startCity = value[1]
        state.addForm.startCityName = selectedOptions[1].label
        if (state.addForm.businessType.value === 3) {
          state.endAddress = value
          state.addForm.endCity = value[1]
          state.addForm.endCityName = selectedOptions[1].label
          state.addForm.endProvince = value[0]
          state.addForm.endProvinceName = selectedOptions[0].label
          onCityList(value[0], value[1])
        }
        if (selectedOptions.length === 2 && (state.addForm.businessType.value === 1 || state.addForm.businessType.value === 2) && (state.addForm.lineType.value === 3 || state.addForm.lineType.value === 4)) {
          onStartStoreCityList(value[0], value[1])
        }
      } else {
        if (state.addForm.businessType.value === 3) {
          state.addForm.endCity = null
          state.addForm.endCityName = null
        }
        state.addForm.startCity = null
        state.addForm.startCityName = null
        state.startingStoreAddress = []
      }
      if (selectedOptions.length > 2) {
        if (state.addForm.businessType.value === 3) {
          state.startingAddress = null
          state.value = null
        }
        state.addForm.startArea = value[2]
        state.addForm.startAreaName = selectedOptions[2].label
        if (state.addForm.businessType.value === 3) {
          state.endAddress = value
          state.addForm.endArea = value[2]
          state.addForm.endAreaName = selectedOptions[2].label
          state.addForm.endCity = value[1]
          state.addForm.endCityName = selectedOptions[1].label
          state.addForm.endProvince = value[0]
          state.addForm.endProvinceName = selectedOptions[0].label
        }
      } else {
        if (state.addForm.businessType.value === 3) {
          state.addForm.endAreaName = null
          state.addForm.endArea = null
        }
        state.addForm.startArea = null
        state.addForm.startAreaName = null
      }
    }
    // 遍历城市获取区
    const onCityList = (p, c) => {
      if (state.cityList.length < 1) {
        state.cityList = store.state.app.cityTypeDict
      }
      console.log('    state.cityList', state.cityList)
      state.startingAddress = []
      state.cityList.forEach(e => {
        if (e.value === p) {
          e.children.forEach(d => {
            if (d.value === c) {
              state.startingAddress = state.startingAddress.concat(d.children)
              e.cost = ''
            }
          })
        }
      })
      state.value.length = []
      if (state.addForm.subLines != null && state.addForm.subLines.length > 0) {
        state.startingAddress.forEach((e, index) => {
          for (let i = 0; i < state.addForm.subLines.length; i++) {
            if (e.value === state.addForm.subLines[i].endArea + '') {
              e.cost = state.addForm.subLines[i].cost
              state.value.push(index)
            }
          }
        })
      }
    }
    // 选择目的地
    const setEndAddress = (value, selectedOptions) => {
      state.addForm.endProvince = value[0]
      state.addForm.endProvinceName = selectedOptions[0].label
      if (selectedOptions.length > 1) {
        state.addForm.endCity = value[1]
        state.addForm.endCityName = selectedOptions[1].label
        state.value = []
        if (selectedOptions.length === 2 && (state.addForm.businessType.value === 1 || state.addForm.businessType.value === 2) && (state.addForm.lineType.value === 2 || state.addForm.lineType.value === 4)) {
          onEndStoreCityList(value[0], value[1])
        }
      } else {
        state.addForm.endCity = null
        state.addForm.endCityName = null
        state.endStoreAddress = []
      }
      if (selectedOptions.length > 2) {
        state.addForm.endArea = value[2]
        state.addForm.endAreaName = selectedOptions[2].label
      } else {
        state.addForm.endArea = null
        state.addForm.endAreaName = null
      }
    }
    // 展示途经点输入框
    const showInput = () => {
      state.inputShow = true
      // nextTick(() => {
      //   inputRef.value.focus()
      // })
    }
    // 添加途经点
    const addVia = (value, selectedOptions) => {
      console.log(value, selectedOptions[0].label)
      state.addTag = selectedOptions[0].label
      const aTag = {
        label: selectedOptions[0].label,
        value: value[0]
      }
      if (!state.routeList.includes(aTag)) {
        if (state.addTag === '') {
          state.inputShow = false
        } else {
          state.routeList.push(aTag)
          state.addTag = ''
          state.inputShow = false
        }
      } else {
        message.error('添加失败：已经存在该点')
      }
    }
    // // 删除途经点
    // const handleClose = removedTag => {
    //   const tags = state.routeList.filter(tag => tag !== removedTag)
    //   state.routeList = tags
    // }
    // 删除途经点
    const deleteRoute = (item, index) => {
      state.routeList.splice(index, 1)
    }
    // 线路添加
    const onAdd = () => {
      add(props.carrierId, { ...state.addForm }).then(res => {
        if (res.code === 10000) {
          console.log('res', res)
          message.info(res.data)
          loadData()
          onFormEmpty()
        }
      }).catch(err => {
        console.log('添加错误' + err)
      })
    }
    // 线路详情
    const onDetail = (id) => {
      detail(id).then(res => {
        if (res.code === 10000) {
          console.log('res', res)
          state.addForm = res.data
          state.startAddress = [state.addForm.startProvince + '', state.addForm.startCity + '', state.addForm.startArea + '']
          state.endAddress = [state.addForm.endProvince + '', state.addForm.endCity + '', state.addForm.endArea + '']
          console.log('   state.addForm', state.addForm.businessType)
          state.routeList = res.data.passPoint
          if (state.addForm.businessType.value === 3) {
            onCityList(state.addForm.startProvince + '', state.addForm.startCity + '')
          }
          if (state.addForm.businessType.value === 1 || state.addForm.businessType.value === 2) {
            if (state.addForm.lineType.value === 3 || state.addForm.lineType.value === 4) {
              // onStartStoreCityList(state.addForm.startProvince + '', state.addForm.startCity + '')
              state.startingStoreAddress = []
              state.addForm.startingStoreSubLines.forEach(d => {
                state.startingStoreAddress.push({
                  code: d.endArea,
                  cost: d.cost,
                  label: d.endAreaName,
                  value: d.endArea
                })
              })
            }
            if (state.addForm.lineType.value === 2 || state.addForm.lineType.value === 4) {
              // onEndStoreCityList(state.addForm.endProvince + '', state.addForm.endCity + '')
              state.endStoreAddress = []
              state.addForm.endStoreSubLines.forEach(d => {
                state.endStoreAddress.push({
                  code: d.endArea,
                  cost: d.cost,
                  label: d.endAreaName,
                  value: d.endArea
                })
              })
          
            }
            state.startingAddress = []
            state.value = []
          }
          if (res.data.lineOffer === null) {
            res.data.lineOffer = {
              value: ''
            }
          }
          if (res.data.transportType === null) {
            res.data.transportType = {
              value: ''
            }
          }
          if (res.data.isDelivery === null) {
            res.data.isDelivery = {
              value: ''
            }
          }
          if (res.data.isOptimal === null) {
            res.data.isOptimal = {
              value: 0
            }
          }
          if (res.data.isFixedLine === null) {
            res.data.isFixedLine = {
              value: 0
            }
          }
          // state.addForm = res.data
        }
      }).catch(err => {
        console.log('查看详情错误' + err)
      })
    }
    // 表单确认
    const addData = () => {
      formRef.value.validate().then(() => {
        if (state.addForm.lineOffer && state.addForm.lineOffer.value === 2) {
          if (state.addForm.startingPrice === undefined || state.addForm.startingPrice === '' || state.addForm.exceedingKm === undefined || state.addForm.exceedingKm === '' || state.addForm.perKmPrice === undefined || state.addForm.perKmPrice === '') {
            message.error('请填写公式价的内容')
            return
          }
        }
        if (Number(state.addForm.startCity) === Number(state.addForm.endCity)) {
          if (state.addForm.startCityName.indexOf('市') > 0 && state.addForm.businessType.value !== 3 && state.addForm.businessType.value !== 2) {
            message.error('业务类型该为同城')
            return
          }
        }
        if (state.addForm.startCity === undefined || state.addForm.startCity === null || state.addForm.startCity === 0) {
          message.error('必须选择市')
          return
        }
        if ((state.addForm.endCity === undefined || state.addForm.endCity === null || state.addForm.endCity === 0) && state.addForm.businessType.value !== 3) {
          message.error('必须选择市')
          return
        }
        if (Number(state.addForm.startProvince) === Number(state.addForm.endProvince)) {
          if (state.addForm.businessType.value === 1) {
            message.error('干线必须不同省')
            return
          }
        } else {
          if (state.addForm.businessType.value === 2) {
            message.error('同省必须省份一致')
            return
          }
        }

        if (state.addForm.endCity !== '' && state.addForm.endCity !== undefined && state.addForm.startCity !== '' && state.addForm.startCity !== undefined) {
          state.addForm.passPoint = state.routeList
          state.addForm.carrierId = props.carrierId
          const subLines = []
          const startingStoreSubLines = []
          const endStoreSubLines = []
          state.value.forEach(e => {
            if (e != null) {
              const d = state.startingAddress[e]
              const data = {
                cost: d.cost,
                endArea: d.value,
                endAreaName: d.label,
                endCity: state.addForm.startCity,
                endCityName: state.addForm.startCityName,
                endProvince: state.addForm.startProvince,
                endProvinceName: state.addForm.startProvinceName,
                remark: ''
              }
              subLines.push(data)
            }
          })
          state.startingStoreAddress.forEach(e => {
            if (e != null) {
              // const d = state.startingStoreAddress[e]
              const data = {
                cost: e.cost,
                endArea: e.value,
                endAreaName: e.label,
                endCity: state.addForm.startCity,
                endCityName: state.addForm.startCityName,
                endProvince: state.addForm.startProvince,
                endProvinceName: state.addForm.startProvinceName,
                remark: ''
              }
              startingStoreSubLines.push(data)
            }
          })
          state.endStoreAddress.forEach(e => {
            if (e != null) {
              // const d = state.endStoreAddress[e]
              const data = {
                cost: e.cost,
                endArea: e.value,
                endAreaName: e.label,
                endCity: state.addForm.endCity,
                endCityName: state.addForm.endCityName,
                endProvince: state.addForm.endProvince,
                endProvinceName: state.addForm.endProvinceName,
                remark: ''
              }
              endStoreSubLines.push(data)
            }
          })
          if (state.addForm.businessType.value === 3) {
            if (subLines.length < 1) {
              message.error('同城至少选择一个区')
              return
            }
            state.addForm.subLines = subLines
            state.addForm.startingStoreSubLines = []
            state.addForm.endStoreSubLines = []
            state.addForm.lineType = {}
          }
          // if (state.addForm.businessType.value === 1) {
          //   state.addForm.subLines = []
          //   state.addForm.startingStoreSubLines = []
          //   state.addForm.endStoreSubLines = []
          // }
          if (state.addForm.businessType.value === 1 || state.addForm.businessType.value === 2) {
            state.addForm.subLines = []
            if (state.addForm.lineType.value === 3 || state.addForm.lineType.value === 4) {
              state.addForm.startingStoreSubLines = startingStoreSubLines
            } else {
              state.addForm.startingStoreSubLines = []
            }
            if (state.addForm.lineType.value === 2 || state.addForm.lineType.value === 4) {
              state.addForm.endStoreSubLines = endStoreSubLines
            } else {
              state.addForm.endStoreSubLines = []
            }
          }
          if (state.addForm.startArea !== null) {
            state.addForm.startingStoreSubLines = null
          }
          if (state.addForm.endArea !== null) {
            state.addForm.endStoreSubLines = null
          }
          if (state.addForm.lineOffer !== null && state.addForm.lineOffer.value === 2) {
            state.addForm.subLines.forEach(e => {
              e.cost = null
            })
          }
          switch (state.lineClickType) {
            case 1:
              onAdd()
              break
            case 3:
              onEdit()
              break
            default:
              console.log('没有选择')
              break
          }
        } else {
          message.error('起始地选择不正确或未选择,请检查后再次提交')
        }
      })
    }
    // 线路编辑
    const onEdit = () => {
      edit(props.carrierId, { ...state.addForm }).then(res => {
        if (res.code === 10000) {
          message.success(res.data)
          loadData()
          onFormEmpty()
        }
      }).catch(err => {
        console.log('编辑错误' + err)
      })
    }
    // 线路切换的时候清空所选择的区
    const onLineOffer = () => {
      state.value = []
    }
    // 线路标签赋值
    const onLineLabel = (e, v) => {
      state.addForm.lineLabelName = v.label
      state.addForm.lineLabel = v.value
    }
    // 判断选择是的那个操作
    const onLineClick = (e, id) => {
      state.routeList = []
      switch (e) {
        case 1:
          state.lineNameType = '新增'
          state.lineNameButtonType = '新增'
          state.disabled = false
          state.lineTypeState = false
          state.startAddress = []
          state.endAddress = []
          state.addForm = {
            cost: '',
            remark: '',
            prescription: '',
            startAddress: {},
            endAddress: {},
            businessType: {},
            lineOffer: {},
            transportType: {},
            isDelivery: {},
            isOptimal: {
              value: 0
            },
            isFixedLine: {
              value: 0
            },
            lineType: {}
          }
          state.addShow = true
          break
        case 2:
          state.lineNameType = '查看'
          state.lineNameButtonType = '查看'
          state.disabled = true
          state.lineTypeState = true
          onDetail(id)
          state.seeShow = true
          break
        case 3:
          state.lineNameType = '编辑'
          state.lineNameButtonType = '保存'
          state.disabled = false
          state.lineTypeState = true
          onDetail(id)
          state.addShow = true
          break
        default:
          break
      }

      state.lineClickType = e
    }
    const search = () => {
      state.pagination.current = 1
      loadData()
    }
    // 类型切换
    const onClearAddress = () => {
      state.endAddress = []
      state.startAddress = []
      state.startingStoreValue = []
      state.endStoreValue = []
      state.routeList = []
      if (state.addForm.lineType === null) {
        state.addForm.lineType = {
          value: ''
        }
      }
    }
    // 干线类型切换
    const onLineTypeAddress = () => {
      if (state.addForm.endCity !== null) {
        onEndStoreCityList(state.addForm.endProvince + '', state.addForm.endCity + '')
      }
      if (state.addForm.startCity !== null) {
        onStartStoreCityList(state.addForm.startProvince + '', state.addForm.startCity + '')
      }
    }
    const adjustment = (e) => {
      e.isOptimal = !e.isOptimal
      if (e.isOptimal) {
        e.isOptimal = 1
      } else {
        e.isOptimal = 0
      }
      optimal(props.carrierId, e).then(res => {
        if (res.code === 10000) {
          loadData()
        }
      }).catch(err => {
        console.log('编辑错误' + err)
      })
    }
    // 遍历城市获取区
    const onStartStoreCityList = (p, c) => {
      if (state.cityList.length < 1) {
        state.cityList = store.state.app.cityTypeDict
      }
      if (state.addForm.lineType.value === 3 || state.addForm.lineType.value === 4) {
        state.startingStoreAddress = []
        state.cityList.forEach(e => {
          if (e.value === p) {
            e.children.forEach(d => {
              if (d.value === c) {
                d.children.cost = ''
                state.startingStoreAddress = state.startingStoreAddress.concat(d.children)
              }
            })
          }
        })
      }
      // state.startingStoreValue = []
      // if (state.addForm.startingStoreSubLines != null && state.addForm.startingStoreSubLines.length > 0) {
      //   state.startingStoreAddress.forEach((e, index) => {
      //     for (let i = 0; i < state.addForm.startingStoreSubLines.length; i++) {
      //       if (e.value === state.addForm.startingStoreSubLines[i].endArea + '') {
      //         state.startingStoreValue.push(index)
      //       }
      //     }
      //   })
      // }
    }
    // 遍历城市获取区
    const onEndStoreCityList = (p, c) => {
      if (state.cityList.length < 1) {
        state.cityList = store.state.app.cityTypeDict
      }
      if (state.addForm.lineType.value === 2 || state.addForm.lineType.value === 4) {
        state.endStoreAddress = []
        state.cityList.forEach(e => {
          if (e.value === p) {
            e.children.forEach(d => {
              if (d.value === c) {
                d.children.cost = ''
                state.endStoreAddress = state.endStoreAddress.concat(d.children)
              }
            })
          }
        })
      }
      // state.endStoreValue = []
      // if (state.addForm.endStoreSubLines != null && state.addForm.endStoreSubLines.length > 0) {
      //   state.endStoreAddress.forEach((e, index) => {
      //     for (let i = 0; i < state.addForm.endStoreSubLines.length; i++) {
      //       if (e.value === state.addForm.endStoreSubLines[i].endArea + '') {
      //         state.endStoreValue.push(index)
      //       }
      //     }
      //   })
      // }
    }
    const onFormEmpty = () => {
      state.addForm = {
        cost: '',
        remark: '',
        prescription: '',
        startAddress: {},
        endAddress: {},
        businessType: {},
        lineOffer: {},
        transportType: {},
        isDelivery: {},
        isOptimal: {
          value: 0
        },
        isFixedLine: {
          value: 0
        },
        lineType: {}
      }
      state.startAddress = []
      state.endAddress = []
      state.startingAddress = []
      state.value = []
      state.routeList = []
      state.addShow = false
      this.cityList = store.state.app.cityTypeDict
      // this.cityList.forEach(e => {
      //   e.cost = ''
      // })
    }
    // 搜索终起点城市
    const setSearchFormStartAddress = (value, selectedOptions) => {
      state.searchForm.startProvince = value[0]
      // state.searchForm.startProvinceName = selectedOptions[0].label
      if (selectedOptions.length > 1) {
        state.searchForm.startCity = value[1]
        // state.searchForm.startCityName = selectedOptions[1].label
      } else {
        state.searchForm.startCity = null
        // state.searchForm.startCityName = null
      }
      if (selectedOptions.length > 2) {
        state.searchForm.startArea = value[2]
        // state.searchForm.startAreaName = selectedOptions[2].label
      } else {
        state.searchForm.startArea = null
        // state.searchForm.startAreaName = null
      }
    }
    // 搜索终点城市
    const setSearchFormEndAddress = (value, selectedOptions) => {
      state.searchForm.endProvince = value[0]
      // state.searchForm.endProvinceName = selectedOptions[0].label
      if (selectedOptions.length > 1) {
        state.searchForm.endCity = value[1]
        // state.searchForm.endCityName = selectedOptions[1].label
      } else {
        state.searchForm.endCity = null
        // state.searchForm.endCityName = null
      }
      if (selectedOptions.length > 2) {
        state.searchForm.endArea = value[2]
        state.searchForm.endAreaName = selectedOptions[2].label
      } else {
        state.searchForm.endArea = null
        // state.searchForm.endAreaName = null
      }
    }
    return {
      ...toRefs(state),
      addVia,
      addData,
      formRef,
      inputRef,
      loadData,
      showInput,
      deleteData,
      setEndAddress,
      setStartAddress,
      handleTableChange,
      onLineOffer,
      onLineLabel,
      onLineClick,
      onDetail,
      onCityList,
      deleteRoute,
      search,
      onClearAddress,
      adjustment,
      onFormEmpty,
      onLineTypeAddress,
      setSearchFormStartAddress,
      setSearchFormEndAddress
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../common.less';
</style>
