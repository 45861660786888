<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn">
    </SearchList>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle">共有&nbsp;<a>{{ data.length }}</a>&nbsp;条数据</div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #paidNormalAmt="{ record }">
          <a @click="openDetails(record,1,'paidNormalAmt')">{{ record.paidNormalAmt }}</a>
        </template>
        <template #paidOverdueAmt="{ record }">
          <a @click="openDetails(record,2,'paidOverdueAmt')">{{ record.paidOverdueAmt }}</a>
        </template>
        <template #monthNormalAmt="{ record }">
          <a @click="openDetails(record,3,'monthNormalAmt')">{{ record.monthNormalAmt }}</a>
        </template>
        <template #monthOverdueAmt="{ record }">
          <a @click="openDetails(record,4,'monthOverdueAmt')">{{ record.monthOverdueAmt }}</a>
        </template>
        <template #totalAmt="{ record }">
          <a @click="openDetails(record,0,'totalAmt')">{{ record.totalAmt }}</a>
        </template>
        <template #receiveContent="{ record }">
          <div class="flex al-center">
            <div style="width: 90%;">
              <span class="m-r1" :style="{color:record.receiveLevel?.value === 1 ? '#15AD31':'#DB2107'}">{{ record.receiveLevel?.label }}</span>
              {{ record.receiveContent }}
            </div>
            <div>
              <!-- <div> <a @click="newAdd(record)">添加</a></div> -->
              <a v-if="record.receiveId" @click="recordLook(record)">记录</a>
            </div>
          </div>
        </template>
      </a-table>
    </div>
   <!-- 月份列表 -->
   <a-modal v-model:visible="monthVisible" :title="monTitle" footer="" width="80%" destroyOnClose :bodyStyle="{padding:'10px',background:'#eee'}">
      <DetailsModal :monthData="monthData"></DetailsModal>
    </a-modal>
    <a-modal title="回款描述记录" v-model:visible="recordLookVisible" footer="" @cancel="receiveData = []">
      <div class="recordLookBox">
        <div v-for="item in receiveData" :key="item.id" class="m-b1">
          <a-card>
            <div>回款情况：<span>{{item.receiveLevel?.label}}</span></div>
            <div>添加人：<span>{{item.empName}}</span></div>
            <div>添加时间：<span>{{item.createTime}}</span></div>
            <div>添加内容：<span>{{ item.content }}</span></div>
          </a-card>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import moment from 'moment'
import { transitionTimeYM, getTableBody, IfNull } from '@/utils/util'
import { receivableTotal } from '@/api/financialCenter/financialCenter'
import DetailsModal from '../../table/keyIssues/detailsModal'
import {  receivePlanId } from '@/api/businessCenter/businessCenter'
import { useStore } from 'vuex'
const store = useStore()

const loading = ref(false)
const dataSource = ref([])
const data = ref([])
const pageSize = ref(50)
const tableRef = ref(null)
const monthVisible = ref(false)
const searchMsg = ref({})
const monTitle = ref({})
const monthData = ref({})
const recordLookVisible = ref(false)
const receiveData = ref([])

const recordLook = (record) => {
  recordLookVisible.value = true
  receivePlanId(record.receiveId).then(res => {
    if (res.code !== 10000) return
    receiveData.value = res.data
  })
}
const searchAdd = (e) => {
  searchMsg.value = e
  getProfitList()
}
const resetBtn = () => {
  searchMsg.value = {}
  getProfitList()
}
const arr = {
  0: '合计',
  1: '到收-正常',
  2: '到收-逾期',
  3: '记账/月结-正常',
  4: '记账/月结-逾期'
}
const openDetails = (record, type, key) => {
  if(record[key] == 0) return
  monTitle.value = `业务应收-${arr[type]}-` + record.salesman
  monthVisible.value = true
  record.type = type
  record.key = key
  record.title = monTitle.value
  monthData.value = record
}
const getProfitList = () => {
  pageSize.value = 50
  loading.value = true
  receivableTotal({
    ...searchMsg.value,
  }).then(res => {
    if (res.code !== 10000) return
    data.value = res.data
    dataSource.value = data.value.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}

const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
onMounted(() => {
  getProfitList()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})
const iptData = ref([
  {
    type: "select",
    placeholder: "部门",
    value: null,
    prop: "orgId",
    width: 200,
    opt:store.state.app.orgList
  },
  {
    type: "input",
    placeholder: "分单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "select",
    placeholder: "结算方式",
    value: null,
    prop: "orderSettlement",
    width: 200,
    key: "value",
    keyVal: "label",
    opt:store.state.enumAll.orderSettlementList
  },
  {
    type: "select",
    placeholder: "是否添加回款记录",
    value: null,
    prop: "isAdd",
    width: 200,
    opt: [
      { name: "是", id: 1 },
      { name: "否", id: 0 },
    ],
  },
  {
    type: "select",
    placeholder: "回款情况",
    value: null,
    prop: "receiveLevel",
    width: 200,
    opt: [
      { name: "正常", id: 1 },
      { name: "困难", id: 2 },
    ],
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '部门',
    dataIndex: 'orgName',
    align: 'left',
    opt:store.state.app.orgList
  },
  {
    title: '分单员',
    dataIndex: 'salesman',
    align: 'left',
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'left',
  },
  {
    title: '到收',
    align: 'center',
    children: [
      {
        title: '正常',
        dataIndex: 'paidNormalAmt',
        slots: {
          customRender: 'paidNormalAmt'
        },
        align: 'right',
      },
      {
        title: '逾期',
        dataIndex: 'paidOverdueAmt',
        slots: {
          customRender: 'paidOverdueAmt'
        },
        align: 'right',
      }
    ]
  },
  {
    title: '记账/月结',
    align: 'center',
    children: [
      {
        title: '正常',
        dataIndex: 'monthNormalAmt',
        slots: {
          customRender: 'monthNormalAmt'
        },
        align: 'right',
      },
      {
        title: '逾期',
        dataIndex: 'monthOverdueAmt',
        slots: {
          customRender: 'monthOverdueAmt'
        },
        align: 'right',
      }
    ]
  },
  {
    title: '合计',
    dataIndex: 'totalAmt',
    slots: {
      customRender: 'totalAmt'
    },
    align: 'right',
  },
  {
    title: '上报报备时间',
    dataIndex: 'receiveReportTime',
    align: 'left',
  },
  {
    title: '报备次数',
    dataIndex: 'receivePlanNum',
    align: 'right',
  },
  {
    title: '回款描述',
    dataIndex: 'receiveContent',
    align: 'left',
    slots: {
      customRender: 'receiveContent'
    },
    width: 350
  },
])
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>