<template>
  <div>
    <div>
      <a-input v-model:value="deviceTag" placeholder="请输入设备号" style="width: 200px"  />
      <a-button @click="searchAdd" class="m-l1" type="primary">搜索</a-button>
    </div>
    <div class="m-t1"></div>
    <a-table :data-source="dataSource" :columns="columns" :loading="loading" :pagination="BIGpagination" @change="handleChange" bordered :rowKey="(record, index) => { return index }" size="small">
     <template #add="{  record }">
      <a @click="bindAdd(record)">选择</a>
    </template>
    </a-table>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { IfNull, debounce } from '@/utils/util'
import { gpsShipBind, shipmentsActive } from '@/api/transport/abroadTrans'
import { message } from 'ant-design-vue';
const emit = defineEmits(['bindAdd'])

const loading = ref(false)
const deviceTag = ref(null)
const dataSource = ref([])
const BIGpagination = ref({
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  pageSizeOptions: ['10', '20', '30'],
  showTotal: (total, range) => `共 ${total} 条`
})
const searchAdd = () => {
  if(!IfNull(deviceTag.value)) {
    message.warning('请输入设备号')
    return
  }
  BIGpagination.value.current = 1
  getEmployeeList()
}
const bindAdd = (record) => {
 emit('bindAdd', record)
}

const handleChange = (val) => {
  BIGpagination.value = val
  getEmployeeList()
};
const getEmployeeList = (e) => {
  loading.value = true
  shipmentsActive({
    current: BIGpagination.value.current,
    size: BIGpagination.value.pageSize,
    deviceTag:deviceTag.value
  }).then(res => {
    if (res.code !== 10000) return
    res.data.records.forEach(item => {
      item.starAddress = item.stops[0].name ? item.stops[0].name : ''
      item.endAddress = item.stops[1].name ? item.stops[1].name : ''
    })
    dataSource.value = res.data.records
    BIGpagination.value.total = res.data.total
  }).finally(() => {
    loading.value = false
  })
}

const columns = ref([
  {
    title: '设备编号',
    dataIndex: 'deviceTag',
    key: 'deviceTag',
    align: 'center',
  },
  {
    title: '运单名称',
    dataIndex: 'reference',
    key: 'deviceTag',
    align: 'center',
    width: 200,
  },
  {
    title: '起点围栏',
    dataIndex: 'starAddress',
    key: 'starAddress',
    align: 'center',

  },
  {
    title: '终点围栏',
    dataIndex: 'endAddress',
    key: 'endAddress',
    align: 'center',

  },
  {
    title: '创建时间',
    dataIndex: 'createdOn',
    key: 'createdOn',
    align: 'center',

  },
  {
    title: '操作',
    dataIndex: 'add',
    key: 'add',
    align: 'center',
    width: 80,
    slots: {
      customRender: 'add'
    }
  },
])
</script>

<style lang="less" scoped>
.title {
  color: #344563;
}
</style>