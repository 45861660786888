<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn">
    </SearchList>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle">共有&nbsp;<a>{{ data.length }}</a>&nbsp;条数据</div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
      
      </a-table>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import moment from 'moment'
import { transitionTimeYM, getTableBody, IfNull } from '@/utils/util'
import { profitList } from '@/api/businessCenter/businessCenter'

const loading = ref(false)
const dataSource = ref([])
const data = ref([])
const pageSize = ref(50)
const tableRef = ref(null)
const searchMsg = ref({})




const searchAdd = (e) => {
  searchMsg.value = e
  getProfitList()
}
const resetBtn = () => {
  searchMsg.value = {
    YM: transitionTimeYM(new Date().toLocaleDateString()),
  }
  getProfitList()
}

const getProfitList = () => {
  loading.value = true
  if (searchMsg.value.YM) {
    searchMsg.value.year = transitionTimeYandM(searchMsg.value.YM).year
    searchMsg.value.month = transitionTimeYandM(searchMsg.value.YM).month
  }
  profitList({
    ...searchMsg.value,
  }).then(res => {
    if (res.code !== 10000) return
    data.value = res.data
    dataSource.value = data.value.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}
const transitionTimeYandM = (time) => {
  if (!time) return ''
  const date = new Date(time)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  return {
    year: year,
    month: month
  }
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
onMounted(() => {
  searchMsg.value = {
    YM: transitionTimeYM(new Date().toLocaleDateString()),
  }
  getProfitList()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})
const iptData = ref([
  {
    type: "selectTimeYM",
    placeholder: "年月",
    value: moment(new Date()),
    prop: "YM",
    width: 200,
    allowClear:false
  },
  {
    type: "input",
    placeholder: "挂账对象",
    value: null,
    prop: "YM",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度员",
    value: null,
    prop: "YM",
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '挂账对象',
    dataIndex: 'empName',
    align: 'center',
  },
  {
    title: '调度员',
    dataIndex: 'orgName',
    align: 'center',
  },
  {
    title: '调度部门',
    dataIndex: 'carNum',
    align: 'center',
  },
  {
    title: '月份',
    dataIndex: 'orderNum',
    align: 'center',
  },
  {
    title: '应付金额',
    dataIndex: 'profitTotal',
    align: 'center',
  },
  {
    title: '未结算金额',
    dataIndex: 'profitTotal',
    align: 'center',
  },
  {
    title: '申请中金额',
    dataIndex: 'profitTotal',
    align: 'center',
  },
])
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>