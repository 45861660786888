<template>
  <div>
    <a-space>
      <a-select style="width: 140px" v-model:value="regionalType" placeholder="选择大车类型"
        @change="BIGpagination.current = 1, loadData()" >
        <a-select-option :value="1">国内</a-select-option>
        <a-select-option :value="2">国外</a-select-option>
      </a-select>
      <a-input v-model:value="searchVinno" placeholder="车牌号" />
      <a-button type="primary" @click="search">搜索</a-button>
      <a-button @click="onAddVehicle">新增</a-button>
    </a-space>
    <a-table :data-source="dataSource" v-model:columns="mirrorImgColumns" :loading="loading" :pagination="BIGpagination"
      @change="handleChange" :rowKey="(record, index) => { return index }" size="small">
      <template #operation="{ record }">
        <div>
          <span v-show=" record.truckStatus && record.truckStatus.value !== 1"
            size="small">当前状态不能操作</span>
          <a-button v-show="record.truckStatus && record.truckStatus.value === 1" size="small"
            type="text" style="background:#F2F2F2;color:#0066DD" @click="choose(record, 1)">{{ record.choose ? '已选择/切换' :
              '选该车' }}</a-button>
          <a-divider v-show=" record.truckStatus && record.truckStatus.value === 1" type="vertical" />
          <a-button v-show="record.truckStatus && record.truckStatus.value === 1" size="small"
            type="text" style="background:#F2F2F2;color:#0066DD" @click="choose(record, 2)">选司机</a-button>
        </div>
      </template>
    </a-table>
  </div>
  <!-- 选择司机 -->
  <a-modal width="50%" v-model:visible="deiverShow" title="选择司机" @ok="deiverShow = false" :destroyOnClose="true">
    <Driver ref="driverRef" :carrierId="carrierId" @onondriverName="chooseDriver" />
  </a-modal>
  <!-- 大车详情 -->
  <a-modal v-model:visible="bigCarShow" title="大车信息" @ok="bigCarShow = false">
    <a-form layout="horizontal" ref="formRef">
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="发车时间">
            {{ transportform ? transportform.departureTime : '' }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="派车人">
            {{ transportform ? transportform.dispatcher : '' }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="线路名称">
            {{ transportform ? transportform.lineName : '' }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="已占车位">
            {{ transportform ? transportform.usedParkPlace : '' }}
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
  <!-- 承运商车辆添加 -->
  <Addvehicle @loadData="loadData" :carrierId="carrierId" :regionalType="2" ref="_addVehicle" />
</template>

<script>
import { onMounted, reactive, toRefs, ref, watch } from 'vue'
import { carrierTruckFreePage, truckByCarrierTruckId } from '@/api/transport/truck'
import { Freepage } from '@/api/crm/carrer/driver'
import Driver from './driver'
import { message } from 'ant-design-vue'
import Addvehicle from '@/views/carrier/toolDetail/vehicleList/add.vue'
export default {
  props: { carrierId: String },
  components: {
    Driver,
    Addvehicle
  },
  setup (props, context) {
    const _addVehicle = ref()
    const gpsSearchRef = ref(null)
    const driverRef = ref(null)
    const state = reactive({
      regionalType:2,
      searchVinno: '',
      carrierId: '',
      bigCarShow: false,
      loading: false,
      deiverShow: false,
      detail: {},
      transportform: {},
      dataSource: [],
      mirrorImgColumns: [],
      columns: [
        {
          title: '地理分类',
          dataIndex: 'regionalType.label'
        },
        {
          title: '车牌号',
          dataIndex: 'carNo',
        }, 
        {
          title: '剩余空位',
          dataIndex: 'vacancy'
        },
        {
          title: '当前状态',
          dataIndex: 'truckStatus.label'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      BIGcolumnss: [
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          ellipsis: true
        },
        {
          title: '电话',
          dataIndex: 'mobile',
          key: 'mobile',
          ellipsis: true
        },
        {
          title: '承运商',
          dataIndex: 'carrierName',
          key: 'carrierName',
          ellipsis: true
        },
        {
          title: '选择',
          dataIndex: 'operation',
          width: 200,
          fixed: 'right',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      BIGpagination: {
        pageSize: 3,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['3', '6', '10'],
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const loadData = () => {
         state.loading = true
        state.mirrorImgColumns = state.columns
        carrierTruckFreePage({
          carrierId: state.carrierId,
          current: state.BIGpagination.current,
          size: state.BIGpagination.pageSize,
          name: state.searchVinno,
          regionalType: state.regionalType
        }).then(res => {
          if (res.code === 10000) {
            state.dataSource = res.data.records
            state.BIGpagination.total = res.data.total
            state.loading = false
          }
        })
    }
    const handleChange = page => {
      state.BIGpagination.current = page.current
      loadData()
    }
    const search = () => {
      state.BIGpagination.current = 1
      loadData()
    }
    // 确认该车和司机
    const choose = (item, num) => {
   
      switch (num) {
        case 1: // 选择该车
          if (state.detail.choose) state.detail.choose = false
          if (state.detail.chooseDriver) state.detail.chooseDriver = false
          state.detail = item
          state.detail.choose = true
          state.detail.chooseDriver = true
          state.detail.carrierTruckId = item.id
          break
        case 2: // 选择司机
        if(!state.detail.carrierTruckId){
          message.warning('请先选择大车')
         return
        }
          state.deiverShow = true
          setTimeout(() => {
            driverRef.value.loadData()
          }, 100)
          break
      }
    }
    const chooseDriver = recode => {
      if (recode) {
        // state.detail.carrierDriverId = recode.id
        // state.detail.driver = recode.name
        // state.detail.carType = recode.driverType.label
        const objData = { ...state.detail, ...recode }
        state.detail = objData
        context.emit('carDriverData', state.detail)
        state.deiverShow = false
      }
    }
  
    // 查看大车信息
    const lookBigCarData = record => {
      state.bigCarShow = true
      state.transportform = {}
      truckByCarrierTruckId(record.id).then(res => {
        if (res.code === 10000) {
          state.transportform = res.data
        }
      })
    }

    watch(() => props.carrierId, (newVal, oldVal) => {
      if (newVal !== undefined) state.carrierId = newVal
      loadData()
    }, { deep: true })
    onMounted(() => {
      if (props.carrierId) {
        state.carrierId = props.carrierId
      }
      loadData()
    })
    // 新增承运商车辆
    const onAddVehicle = () => {
      _addVehicle.value.addShow = true
    }
    return {
      ...toRefs(state),
      lookBigCarData,
      chooseDriver,
      handleChange,
      loadData,
      choose,
      search,
      gpsSearchRef,
      driverRef,
      onAddVehicle,
      _addVehicle
    }
  }
}
</script>

<style lang="less" scoped></style>
